// components/forms/EditProfileForm.tsx
import { Profile } from "@/types/entities";
import { ProfileFormBase, FormSchema } from "./ProfileFormBase";
import { useState } from "react";
import { useProfileMutation } from "@/queries/profiles";

type Props = {
  profile: Profile | null;
};

export function EditProfileForm({ profile }: Props) {
  const profileMutation = useProfileMutation();
  const [, setIsSaving] = useState(false);

  const handleSubmit = async (data: FormSchema) => {
    // console.log("HandleSubmit called in EditProfileForm", data);
    setIsSaving(true);

    const updatedProfile: Profile = {
      ...profile, // Keep existing values
      ...data, // Overwrite only fields provided by the form
      avatar_svg: data.avatar_svg ?? profile?.avatar_svg ?? "", // Ensure avatar_svg is always a string
    };

    try {
      profileMutation.mutate(updatedProfile);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <ProfileFormBase
      profile={profile}
      onSubmit={(data) => {
        return handleSubmit(data);
      }}
      title="Edit Profile"
      buttonText="Save"
      isEditMode={true}
    />
  );
}
