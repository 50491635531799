// api/index.ts
import {
  ICategoryAPI,
  IProfileAPI,
  ITransactionAPI,
  IDocumentAPI,
  ITelegramReceiptsAPI,
  ITelegramReceiptsDataAPI,
} from "./types";
import { JunoCategoryAPI } from "./juno/category";
import { JunoProfileAPI } from "./juno/profile";
import { JunoTransactionAPI } from "./juno/transaction";
import { JunoTelegramReceiptsAPI } from "./juno/telegram_receipts";
import { JunoTelegramReceiptsDataAPI } from "./juno/telegram_receipts_data";
import { SatelliteOptions } from "@junobuild/core";
import { Identity } from "@dfinity/agent";
import { JunoReceiptAPI } from "./juno/receipt";
import { JunoDocumentAPI } from "./juno/document";
import { NFTAPI } from "./nft/mint_nft";

export interface IAPI {
  profile: IProfileAPI;
  category: ICategoryAPI;
  transaction: ITransactionAPI;
  receipt: JunoReceiptAPI;
  document: IDocumentAPI;
  mintNFT: NFTAPI;
  telegramReceipts: ITelegramReceiptsAPI;
  telegramReceiptsData: ITelegramReceiptsDataAPI;
}

let customIdentity: Identity | null = null;

export function setAPICustomIdentity(identity: Identity) {
  customIdentity = identity;
}

export function getSatteliteOptions(): SatelliteOptions {
  let options: SatelliteOptions = {};

  if (customIdentity) {
    options = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      identity: customIdentity,
      satelliteId: import.meta.env.VITE_JUNO_ID as string,
    };
  }

  return options;
}

export function getAPI(): IAPI {
  // Return the API object with the category API
  return {
    category: new JunoCategoryAPI(),
    profile: new JunoProfileAPI(),
    transaction: new JunoTransactionAPI(),
    receipt: new JunoReceiptAPI(),
    document: new JunoDocumentAPI(),
    mintNFT: new NFTAPI(),
    telegramReceipts: new JunoTelegramReceiptsAPI(),
    telegramReceiptsData: new JunoTelegramReceiptsDataAPI(),
  };
}
