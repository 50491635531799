import { receiptsState } from "@/atoms/receipt";
import { Receipt } from "@/types/entities";
import { useRecoilValue } from "recoil";
import { useMemo } from "react";
import { search } from "fast-fuzzy";

interface StoreMap {
  [key: string]: {
    storeInfo: Receipt;
    receipts: Receipt[];
    count: number;
  };
}

interface GroupedReceipts {
  [key: string]: Receipt[];
}

const normalizeAddress = (address: string): string => {
  if (typeof address !== "string") {
    console.warn("normalizeAddress was called with a non-string argument", address);
    return "";
  }

  return address
    .toLowerCase()
    .replace(/[\\.,]/g, "")
    .replace(/\s+/g, " ")
    .trim()
    .split(" ")
    .sort()
    .join(" ");
};

const useGroupedReceipts = (): GroupedReceipts => {
  const receipts = useRecoilValue(receiptsState);
  
  const groupedReceipts = useMemo(() => {
    const storesMap: StoreMap = {};

    receipts.forEach((receipt) => {
      const address = receipt?.address || "defaultAddress";
      const normalizedAddress = normalizeAddress(address);
      let closestMatchKey: string | null = null;
      let highestScore = 0;

      // Store normalized addresses to avoid redundant normalization
      const normalizedStoreAddresses = Object.keys(storesMap).map(key => normalizeAddress(storesMap[key].storeInfo.address));

      normalizedStoreAddresses.forEach((normalizedStoreAddress, index) => {
        const key = Object.keys(storesMap)[index];
        const score = search(normalizedAddress, [normalizedStoreAddress], {
          returnMatchData: true,
          threshold: 0.8,
        })[0]?.score || 0;

        if (score > highestScore) {
          highestScore = score;
          closestMatchKey = key;
        }
      });

      if (highestScore > 0.8 && closestMatchKey) {
        storesMap[closestMatchKey].receipts.push(receipt);
        storesMap[closestMatchKey].count += 1;
      } else {
        storesMap[receipt.address] = {
          storeInfo: receipt,
          receipts: [receipt],
          count: 1,
        };
      }
    });

    return Object.keys(storesMap).reduce((acc, key) => {
      acc[key] = storesMap[key].receipts;
      return acc;
    }, {} as GroupedReceipts);
  }, [receipts]);

  return groupedReceipts;
};

export default useGroupedReceipts;
