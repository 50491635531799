import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchTransaction } from "@/atoms/transaction";
import TransactionForm from "@/components/forms/TransactionForm";
import { Transaction } from "@/types/entities";
import Loading from "@/components/LoadingSplashScreen";
import { useNavigate } from "react-router-dom";
import PageWrapper from "@/components/layout/PageWrapper";

const EditTransactionPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [transaction, setTransaction] = useState<Transaction | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAndSetTransaction = async () => {
      if (id) {
        const fetchedTransaction = await fetchTransaction(id);
        if (fetchedTransaction) {
          setTransaction(fetchedTransaction);
          console.log(fetchedTransaction);
        }
      }
    };

    fetchAndSetTransaction();
  }, [id]);

  if (!transaction) {
    return <Loading />;
  }

  return (
    <PageWrapper>
      <div className="max-w-md mx-auto w-[90%]">
        <TransactionForm
          mode="edit"
          transaction={transaction}
          type={transaction.transaction_type}
          onSave={() => {}}
          onPrev={() => navigate("/")}
        />
      </div>
    </PageWrapper>
  );
};

export default EditTransactionPage;
