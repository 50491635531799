import { useNavigate } from "react-router-dom";
import { formatCurrency } from "@/lib/utils";
import { Category, Transaction } from "@/types/entities";
import { useProfileQuery } from "@/queries/profiles";

type TransactionsListItemProps = {
  transaction: Transaction;
  currency: string;
  transactionKey: string; // Change 'key' to 'transactionKey'
  category: Category;
  onVerify?: (receiptId: string) => void; // New prop for verification
  loadingReceiptId?: string | null; // New prop for loading state
};

export default function TransactionsListItem({
  transaction,
  currency,
  transactionKey,
  category,
  onVerify,
  loadingReceiptId,
}: TransactionsListItemProps) {
  const navigate = useNavigate();

  const { data: profile } = useProfileQuery();
  const isDisabled = !profile?.onboarding_completed;

  const handleClick = () => {
    if (transaction.id) navigate(`/transactions/${transaction.id}/edit`);
  };

  const handleReceiptClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigate(`/receipt/${transaction.id}`);
  };

  const handleVerifyClick = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevents triggering the parent click event
    if (onVerify && transaction.id) {
      onVerify(transaction.id);
    }
  };

  const normalizedCurrency = currency === "RP" ? "IDR" : currency;
  const isVerifying = loadingReceiptId === transaction.id;

  return (
    <div
      key={transactionKey}
      className={`pt-4 rounded-lg flex items-center justify-between z-0 ${isDisabled ? "cursor-allowed" : "cursor-pointer"}`}
      onClick={!isDisabled ? handleClick : undefined}
    >
      <div className="flex items-center space-x-4 flex-1 min-w-0">
        <div
          className={`${
            category
              ? "bg-gradient-to-tr from-blue-500 to-blue-300"
              : transaction.transaction_type === "income"
                ? "bg-gradient-to-tr from-green-800 to-green-300"
                : "bg-gradient-to-tr from-red-500 to-red-300"
          } h-8 w-8 rounded-full flex items-center justify-center`}
        >
          {category ? (
            <div className="text-[16pt] flex items-center justify-center">
              <div className="">{category.icon}</div>
            </div>
          ) : null}
        </div>
        <div className="flex-1 min-w-0">
          <p className="font-medium truncate">{transaction.name}</p>
          <div className="flex flex-wrap">
            {transaction.category && (
              <span className="text-xs bg-gray-800 text-foreground/80 p-1 rounded mr-2 mb-2 whitespace-nowrap">
                {transaction.category}
              </span>
            )}
            {transaction.receipt_id && (
              <span
                onClick={!isDisabled ? handleReceiptClick : undefined}
                className={`text-xs bg-gray-200 text-gray-800 text-foreground/80 p-1 mb-2 rounded z-50 whitespace-nowrap ${isDisabled ? "cursor-allowed" : "cursor-pointer"}`}
              >
                Show receipt
              </span>
            )}
            {onVerify && (
              <button
                onClick={handleVerifyClick}
                className="text-xs bg-blue-500 text-white p-1 mb-2 rounded z-50 whitespace-nowrap ml-2"
                disabled={isVerifying}
              >
                {isVerifying ? "Verifying receipt..." : "Verify"}
              </button>
            )}
          </div>
        </div>
      </div>
      <span
        className={`${transaction.transaction_type === "income" ? "text-green-500" : "text-red-500"} mr-2 `}
      >
        {formatCurrency(transaction.amount_cents / 100, normalizedCurrency)}
      </span>
    </div>
  );
}
