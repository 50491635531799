import { createAgent } from "@dfinity/utils";
import { LedgerCanister } from "@dfinity/ledger-icp";
import { Principal } from "@dfinity/principal";
import { AccountIdentifier } from "@dfinity/ledger-icp";
import { Ed25519KeyIdentity } from "@dfinity/identity";

export const IC_MAINNET_URL = "https://icp0.io/";
export const MAINNET_CANISTER_ID = "ryjl3-tyaaa-aaaaa-aaaba-cai";

export const setupLedger = async (identity: Ed25519KeyIdentity) => {
  const agent = await createAgent({
    identity,
    host: IC_MAINNET_URL,
  });

  const ledger = LedgerCanister.create({
    agent,
    canisterId: Principal.fromText(MAINNET_CANISTER_ID),
  });

  return ledger;
};

export const getAccountId = (principalId: string) => {
  return AccountIdentifier.fromPrincipal({
    principal: Principal.fromText(principalId),
  });
};