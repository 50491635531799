import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import EditReceiptForm from './EditReceiptForm';
import { DotsThree, PencilSimple, Trash, ArrowClockwise } from "@phosphor-icons/react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import PageWrapper from '@/components/layout/PageWrapper';
import currencySymbolMap from 'currency-symbol-map';
import { deleteReceipt, receiptsState, updateReceipt } from '@/atoms/receipt';
import { Skeleton } from "@/components/ui/skeleton";
import { deleteTransaction, fetchTransaction, transactionsState, updateTransaction } from '@/atoms/transaction';
import { updateTelegramTransaction, telegramReceiptsState } from '@/atoms/telegram_receipts';
import { updateTelegramReceipt, telegramReceiptsDataState } from '@/atoms/telegram_receipts_data';
import { assetsState } from '@/atoms/receipt_asset';
import DeleteDialog from '@/components/ui/DeleteDialog';
import { Receipt as BaseReceipt, Transaction } from '@/types/entities';
import { Doc } from '@junobuild/core';
import { useCustomErrorBoundary } from '@/hooks/useErrorBoundary';
import { useTelegramReceiptsData } from '@/hooks/useTelegramReceiptsData';
import { useTelegramReceipts } from '@/hooks/useTelegramReceipts';

interface Receipt extends BaseReceipt {
    imageUrl: string;
}

const ReceiptDetailPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [receipts, setReceipts] = useRecoilState(receiptsState);
    const telegramReceipts = useTelegramReceipts();
    const telegramReceiptsData = useTelegramReceiptsData();
    const assets = useRecoilValue(assetsState);
    const setTransactions = useSetRecoilState(transactionsState);
    const setTelegramTransactions = useSetRecoilState(telegramReceiptsState);
    const setTelegramReceiptsData = useSetRecoilState(telegramReceiptsDataState);
    const [loading, setLoading] = useState(true);
    const [receipt, setReceipt] = useState<BaseReceipt | null>(null);
    const [_imageUrl, setImageUrl] = useState<string>('');
    const [newReceipt] = useState(new URLSearchParams(location.search).get('new') === 'true');
    const [isEditing, setIsEditing] = useState<boolean>(newReceipt);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [imageLoading, setImageLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const { handleError } = useCustomErrorBoundary();
    const [notFound, setNotFound] = useState(false);

    const navigate = useNavigate();

    const fetchReceipt = useCallback(async () => {    
        if ((!receipts || receipts.length === 0) && (!telegramReceiptsData || telegramReceiptsData.length === 0)) {
            console.log("No receipts available");
            setLoading(false);
            setNotFound(true);
            return;
        }
    
        // Search in both normal receipts and telegram receipts
        const foundReceipt = receipts.find((receipt): receipt is Receipt => receipt.id === id) ||
                            telegramReceiptsData.find((receipt): receipt is Receipt => receipt.id === id);
    
            if (foundReceipt) {
                const { imageUrl, ...rest } = foundReceipt;
                if (imageUrl) {
                    setImageUrl(imageUrl);
                } else {
                    const matchingAsset = assets.find(asset => {
                        if (!asset) return false;
                        const assetName = asset.split('/').pop()?.split('.')[0];
                        return assetName === id;
                    });
                    setImageUrl(matchingAsset || assets[assets.length - 1]);
                }
                setReceipt(rest);
            } else {
                console.log("No receipt found");
                setReceipt(null);
                setNotFound(true);
            }
            setLoading(false);
        }, [id, receipts, telegramReceiptsData, assets]);

    useEffect(() => {
        fetchReceipt();
    }, [fetchReceipt]);

    const handleUpdate = async (updatedReceipt: Doc<BaseReceipt>) => {
        if (!receipt) return;
        setIsSaving(true);
        try {
            // Determine if the receipt is a telegram transaction
            const isTelegramTransaction = telegramReceipts.some(r => r.id === updatedReceipt.key);
    
            let transaction;
            if (isTelegramTransaction) {
                // Fetch the telegram transaction
                transaction = telegramReceipts.find(r => r.id === updatedReceipt.key);
            } else {
                // Fetch the normal transaction
                transaction = await fetchTransaction(updatedReceipt.key);
            }
    
            if (!transaction) {
                throw new Error('Transaction not found');
            }
    
            const now = new Date();
            const localOffset = now.getTimezoneOffset();
            const updateTransactionData: Transaction = {
                id: transaction.id,
                name: updatedReceipt.data.store_name ?? transaction.name ?? "",
                date: updatedReceipt.data.date ?? transaction.date ?? "",
                amount_cents: updatedReceipt.data.total_price * 100,
                category: updatedReceipt.data.main_category ?? transaction.category ?? "",
                amount_currency: updatedReceipt.data.currency ?? transaction.amount_currency ?? "USD",
                amount_usd_cents: transaction.amount_usd_cents ?? 0,
                transaction_type: transaction.transaction_type ?? "expense",
                is_recurring: transaction.is_recurring ?? false,
                location: transaction.location ?? "",
                recurring_frequency: transaction.recurring_frequency ?? "",
                receipt_id: updatedReceipt.key,
                profile_id: transaction.profile_id ?? "",
                offset: localOffset
            };
    
            // Update the transaction based on its type
            if (isTelegramTransaction) {
                // Update logic for telegram transactions
                await updateTelegramTransaction(updateTransactionData, updatedReceipt.key, setTelegramTransactions);
                // Update the telegram receipt
                await updateTelegramReceipt(updatedReceipt.data, updatedReceipt.key, setTelegramReceiptsData);
            } else {
                // Update logic for normal transactions
                await updateTransaction(updateTransactionData, updatedReceipt.key, setTransactions);
                // Update the normal receipt
                await updateReceipt(updatedReceipt.data, updatedReceipt.key, setReceipts);
            }
    
            setReceipt({ ...receipt, ...updatedReceipt });
            setImageUrl(_imageUrl);
            if (newReceipt) {
                navigate(`/?id=${id}`);
            }
            setIsEditing(false);
        } catch (error) {
            handleError(error instanceof Error ? error : new Error('An error occurred while saving the receipt'));
        } finally {
            setIsSaving(false);
        }
    };

    const handleDelete = async () => {
        if (!id || !receipt) return;
        setIsDeleting(true);
        try {
            await deleteReceipt(receipt, id, _imageUrl, setReceipts);
            navigate('/timeline');
            await deleteTransaction(id, setTransactions);
        } catch (error) {
            console.error('Error deleting receipt:', error);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleFormDataChange = (updatedData: BaseReceipt) => {
        if (!receipt) return;
        setReceipt({ ...receipt, ...updatedData });
    };

    const handleImageLoad = () => {
        setImageLoading(false);
    };

    const handleImageError = () => {
        setImageLoading(false);
    };

    if (loading) {
        return (
            <div className='flex flex-col items-center justify-center min-h-screen'>
                Loading Receipt...
            </div>
        );
    }

    if (notFound || !receipt) {
        return (
            <div className='flex flex-col items-center justify-center min-h-screen'>
                Receipt not found.
            </div>
        );
    }

    return (
        <PageWrapper>
            <div className="max-w-md mx-auto w-[90%]">
                <h1 className="text-2xl font-bold mb-4">Receipt Details</h1>
                {imageLoading && (
                    <Skeleton className="w-full h-[350px] rounded-lg" />
                )}
                {_imageUrl && (
                    <div className={`mt-4 ${imageLoading ? 'hidden' : ''}`}>
                        <img
                            src={_imageUrl}
                            alt="Receipt"
                            className="w-full h-auto"
                            onLoad={handleImageLoad}
                            onError={handleImageError}
                        />
                    </div>
                )}
                <div className='flex justify-between w-full'>
                    {isEditing ? (
                        <>
                            <Button
                                onClick={() => handleUpdate({ key: receipt.id || '', data: receipt })}
                                className="p-2 rounded mt-4 mb-4 mr-2 w-1/2"
                                variant={'default'}
                                disabled={isSaving}
                            >
                                {isSaving ? (
                                    <>
                                        <ArrowClockwise className="mr-2 h-4 w-4 animate-spin" />
                                        Saving...
                                    </>
                                ) : (
                                    'Save'
                                )}
                            </Button>
                            <Button
                                onClick={() => setIsEditing(!isEditing)}
                                className="p-2 rounded mt-4 mb-4 ml-2 w-1/2"
                                variant={'secondary'}
                                disabled={isSaving}
                            >
                                Cancel Edit
                            </Button>
                        </>
                    ) : (
                        <div className='pt-4'></div>
                    )}
                </div>
                <DeleteDialog
                    open={deleteDialogOpen}
                    onOpenChange={setDeleteDialogOpen}
                    onDelete={handleDelete}
                    isDeleting={isDeleting}
                />
                {isEditing ? (
                    <>
                        <EditReceiptForm receipt={receipt} onFormDataChange={handleFormDataChange} />
                        <div className='flex justify-between w-full'>
                            <Button
                                onClick={() => handleUpdate({ key: receipt.id || '', data: receipt })}
                                className="p-2 rounded mt-4 mb-4 mr-2 w-1/2"
                                variant={'default'}
                                disabled={isSaving}
                            >
                                {isSaving ? (
                                    <>
                                        <ArrowClockwise className="mr-2 h-4 w-4 animate-spin" />
                                        Saving...
                                    </>
                                ) : (
                                    'Save'
                                )}
                            </Button>
                            <Button
                                onClick={() => setIsEditing(!isEditing)}
                                className="p-2 rounded mt-4 mb-4 ml-2 w-1/2"
                                variant={'secondary'}
                                disabled={isSaving}
                            >
                                Cancel Edit
                            </Button>
                        </div>
                    </>
                ) : (
                    <div className="border p-4 rounded-lg">
                        <div className="flex font-semibold text-lg justify-between">{receipt.store_name || 'Unknown Store'}
                            {!receipt.nft_minted && (
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                        <Button variant="outline"><DotsThree className='h-6 w-6' /></Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent align="end" className='bg-black rounded-sm p-1 border'>
                                        <DropdownMenuLabel>Options</DropdownMenuLabel>
                                        <DropdownMenuSeparator />
                                        <DropdownMenuItem
                                            onClick={() => setIsEditing(!isEditing)}
                                            className='flex justify-between'
                                        >
                                            Edit
                                            <div className="p-2 rounded">
                                                {isEditing ? 'Cancel Edit' : <PencilSimple className="h-4 w-4" />}
                                            </div>
                                        </DropdownMenuItem>
                                        <DropdownMenuItem
                                            onClick={() => setDeleteDialogOpen(true)}
                                            className='flex justify-between bg-red-500/90'
                                        >
                                            Delete
                                            <div className="p-2 rounded">
                                                <Trash className="h-4 w-4" />
                                            </div>
                                        </DropdownMenuItem>
                                        <DropdownMenuSeparator />
                                        <DropdownMenuItem>
                                            Cancel
                                        </DropdownMenuItem>
                                    </DropdownMenuContent>
                                </DropdownMenu>
                            )}
                        </div>
                        <p>Date: {receipt.date ? new Date(receipt.date).toLocaleDateString() : 'Unknown Date'}</p>
                        <p>Address: {receipt.address || 'Unknown Address'}</p>
                        <p>Main Category: {receipt.main_category || 'N/A'}</p>
                        <p className='font-semibold text-lg'>
                            Total Price: {receipt.currency ? currencySymbolMap(receipt.currency) : '?'}{' '}
                            {receipt.total_price ? receipt.total_price : '0.00'}
                        </p>
                        <h3 className="font-semibold text-md mt-4">Items:</h3>
                        {receipt.line_items.length > 0 ? (
                            <ul className="list-disc list-inside">
                                {receipt.line_items.map((item, index) => (
                                    <li key={index}>
                                        {item.product_name} - {currencySymbolMap(receipt.currency || 'Unknown')} {item.total_price ? item.total_price : '0.00'}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No items</p>
                        )}
                    </div>
                )}
            </div>
        </PageWrapper>
    );
};

export default ReceiptDetailPage;