import React, { useEffect, useState } from "react";
import { Receipt } from "@/types/entities";
import { nanoid } from "nanoid";
import { useRecoilState, useRecoilValue } from "recoil";
import { receiptsState } from "@/atoms/receipt";
import { ArrowClockwise } from "@phosphor-icons/react";
import { getAPI } from "@/api";
import { updateReceiptNFTStatus } from "./utils/functions";
import { evnAddressState, mintingState } from "@/atoms/minting";
import { useProfileQuery } from "@/queries/profiles";

interface ReceiptImage extends Receipt {
  imageUrl?: string;
}

interface MintNFTButtonProps {
  receipt: ReceiptImage;
}

const MintNFTButton: React.FC<MintNFTButtonProps> = ({ receipt }) => {
  const [, setReceipts] = useRecoilState(receiptsState);
  const [minting, setMinting] = useRecoilState(mintingState);
  const [evnState, setEnvState] = useRecoilState(evnAddressState);
  const [isEvnAddressLoading, setIsEvnAddressLoading] = useState(true);
  const { data: profile } = useProfileQuery();

  const API = getAPI();
  const docKey = nanoid();

  useEffect(() => {
    const fetchEvnAddress = async () => {
      try {
        if (
          (!evnState.evnAddress || evnState.profileId !== profile?.id) &&
          profile?.id
        ) {
          const address = await API.mintNFT.getEvnAddress(profile?.id);
          setEnvState({ evnAddress: address, profileId: profile.id });
        }
      } catch (error) {
        console.error("Failed to fetch EVM address:", error);
      } finally {
        setIsEvnAddressLoading(false);
      }
    };

    if (
      profile?.id &&
      (!evnState.evnAddress || evnState.profileId !== profile.id)
    ) {
      fetchEvnAddress();
    } else {
      setIsEvnAddressLoading(false);
    }
  }, [API.mintNFT, evnState, profile?.id, setEnvState]);

  const handleMintNFT = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation(); // Stop event propagation

    const receiptId = receipt.id?.toString() ?? "";

    setMinting((prevState) => ({
      ...prevState,
      [receiptId]: { isLoading: true, error: null },
    }));

    if (!evnState.evnAddress) return null;

    try {
      const uploadedDataURL = await API.mintNFT.uploadData(docKey, receipt);
      const result = await API.mintNFT.mintNFT(
        evnState.evnAddress,
        uploadedDataURL
      );
      console.log("result", result);
      if (result === "Success") {
        await updateReceiptNFTStatus(receipt, setReceipts);
        setReceipts((prevReceipts) =>
          prevReceipts.map((r) =>
            r.id === receipt.id ? { ...r, nft_minted: true } : r
          )
        );
      }
    } catch (err) {
      setMinting((prevState) => ({
        ...prevState,
        [receiptId]: { isLoading: false, error: (err as Error).message },
      }));
    } finally {
      setMinting((prevState) => ({
        ...prevState,
        [receiptId]: { isLoading: false, error: null },
      }));
    }
  };

  const handleNavigate = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation(); // Stop event propagation
    window.open(
      `https://basescan.org/address/${evnState.evnAddress}#nfttransfers`,
      "_blank"
    );
  };

  const receiptId = receipt.id?.toString() ?? "";
  const currentMintingState = minting[receiptId] || {
    isLoading: false,
    error: null,
  };

  return (
    <div className="p-2 m-auto ">
      {receipt.nft_minted ? (
        <div className="bg-gray-500 text-white text-xs font-semibold rounded-full mr-2 p-2">
          <button onClick={handleNavigate} disabled={isEvnAddressLoading}>
            {isEvnAddressLoading ? "Loading..." : "Minted"}
          </button>
        </div>
      ) : (
        <div className="bg-blue-500 text-white text-xs font-semibold rounded-lg p-2 mr-2">
          <button
            onClick={handleMintNFT}
            disabled={currentMintingState.isLoading || isEvnAddressLoading}
          >
            {isEvnAddressLoading ? (
              "Loading..."
            ) : currentMintingState.isLoading ? (
              <ArrowClockwise className="m-auto h-4 w-4 animate-spin" />
            ) : (
              "MINT"
            )}
          </button>
          {currentMintingState.error && (
            <div>Error: {currentMintingState.error}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default MintNFTButton;
