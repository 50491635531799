import { useState } from 'react';
import { useEffect } from 'react';
import currenciesData from './data/currencies.json';

export const useNewCurrencies = () => {
  const [currencies, setCurrencies] = useState<Array<{ label: string; value: string }>>([]);

  useEffect(() => {
    const loadCurrencies = () => {
      const currencyOptions = Object.entries(currenciesData).map(([code, name]) => ({
        label: `${code.toUpperCase()} - ${name}`,
        value: code,
      }));
      setCurrencies(currencyOptions);
    };

    loadCurrencies();
  }, []);

  return currencies;
};