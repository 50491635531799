import React, { useEffect } from "react";
import { init, cloudStorage, postEvent, useLaunchParams } from "@telegram-apps/sdk-react";
import Loading from "../LoadingSplashScreen";
import { Ed25519KeyIdentity } from "@dfinity/identity";
import { useRecoilState } from "recoil";
import { authState } from "@/atoms/auth";
import { getSatteliteOptions, setAPICustomIdentity } from "@/api";
import { User, UserData, getDoc, setDoc } from "@junobuild/core";
import { Profile } from "@/types/entities";
import { useLocation, useNavigate } from "react-router-dom";
import { TonConnectUIProvider, useTonAddress } from "@tonconnect/ui-react";
import authenticateTelegram from "../../api/supabase/authenticate-telegram.ts";
import posthog from "posthog-js";
import { getAPI } from "@/api";
import { createOrUpdateProfile } from "@/atoms/profile";
import { fetchProfileStats } from "@/atoms/profile_stats.ts";
import { fetchRewards } from "@/atoms/rewards.ts";
import { useQuery } from "@tanstack/react-query";
import { useProfileQuery } from "@/queries/profiles.tsx";

declare global {
  interface Window {
    Telegram?: {
      WebApp?: {
        expand: () => void;
        requestFullscreen: () => void;
      };
    };
  }
}

export default function TelegramAuthProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const IDENITITY_KEY = "telegram_internet_identity";
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [user, setUser] = useRecoilState<User | null>(authState);
  const location = useLocation();
  const navigate = useNavigate();
  const API = getAPI();
  const rawAddress = useTonAddress(true);
  
  // Use the new SDK hook
  const launchParams = useLaunchParams();

  const { data: profile, isPending: profileLoading } = useProfileQuery();

  const { data: rewards, isPending: rewardsLoading } = useQuery({
    queryKey: ["rewards"],
    queryFn: fetchRewards,
    enabled: isInitialized,
  });

  const { data: profileStats, isPending: profileStatsLoading } = useQuery({
    queryKey: ["profileStats"],
    queryFn: () => fetchProfileStats(profile?.id || "anonymous"),
    enabled: isInitialized,
  });

  useEffect(() => {
    async function authorize() {
      console.time("overall authorize");

      // Initialize SDK
      init();

      const restoredIdentity = await cloudStorage.getItem(IDENITITY_KEY);
      const { initData, initDataRaw } = launchParams;

      let identity: Ed25519KeyIdentity;
      if (!restoredIdentity) {
        console.log("generating identity");
        identity = await Ed25519KeyIdentity.generate();
        await cloudStorage.setItem(
          IDENITITY_KEY,
          JSON.stringify(identity.toJSON())
        );
        console.log("idenitity saved", identity.toJSON());
        console.log("principal:", identity.getPrincipal().toString());
      } else {
        identity = Ed25519KeyIdentity.fromJSON(restoredIdentity);
        console.log("restored identity", identity);
        console.log("principal:", identity.getPrincipal().toString());
      }

      setAPICustomIdentity(identity);

      if (initData?.user) {
        posthog.identify(
          "telegram:" + initData?.user?.id,
          {
            username: initData?.user.username,
            firstName: initData?.user.firstName,
            lastName: initData?.user?.lastName,
            language: initData?.user.languageCode,
            isPremium: initData?.user.isPremium,
          }
        );
      }

      // Handle initial routing for Edit Receipt button
      if (initData?.startParam && initData.startParam.startsWith("receipt_")) {
        const receiptId = initData.startParam.replace("receipt_", "");
        navigate(`/receipt/${receiptId}`);
      }

      console.time("authenticateTelegram");
      await authenticateTelegram(
        initDataRaw!,
        identity.getPrincipal().toText()
      );
      console.timeEnd("authenticateTelegram");

      console.time("fetchUser");
      const user = await getDoc<UserData>({
        collection: `#user`,
        key: identity.getPrincipal().toText(),
        satellite: getSatteliteOptions(),
      });
      console.timeEnd("fetchUser");

      if (!user) {
        console.time("setDoc with user data");
        const result = await setDoc<UserData>({
          collection: `#user`,
          doc: {
            key: identity.getPrincipal().toText(),
            data: {
              provider: "internet_identity",
            },
          },
          satellite: getSatteliteOptions(),
        });
        console.timeEnd("setDoc with user data");

        console.log("telegram auth: user result", result);

        setUser(result);
      } else {
        console.log("telegram auth: user fetched", user);
        setUser(user);
      }

      console.time("fetchProfile");
      console.log("telegram auth: profile fetched", profile);

      console.timeEnd("fetchProfile");

      if (profile) {
        const tonAddress = rawAddress;

        let updatedProfile: Profile | null = null;

        if (!profile.ton_address && rawAddress) {
          profile.ton_address = tonAddress;
          updatedProfile = {
            ...profile,
          };
          console.log("Updating profile with TON address...");
        }

        if (updatedProfile) {
          console.time("createOrUpdateProfile");
          await createOrUpdateProfile(profile.id, updatedProfile);
          console.timeEnd("createOrUpdateProfile");
        }
      }

      if (!profile?.introduction_completed) {
        console.log("onboarding not completed");
        // navigate("/onboarding");
        navigate("/intro");
      } else {
        // if (location.pathname === "/onboarding") {
        if (location.pathname === "/intro") {
          navigate("/");
        }
      }

      if (window?.Telegram?.WebApp) {
        window.Telegram.WebApp.expand();
        window.Telegram.WebApp.requestFullscreen();
      }

      console.log("initDataRaw", initDataRaw);

      setIsInitialized(true);
      console.timeEnd("overall authorize");
    }

    if (!profileLoading) {
      authorize();
    }
  }, [profileLoading, launchParams]);

  if (!isInitialized || rewardsLoading || profileStatsLoading) {
    return <Loading />;
  }

  return (
    <TonConnectUIProvider manifestUrl="https://beta.bridge23.app/tonconnect-manifest.json">
      {children}
    </TonConnectUIProvider>
  );
}
