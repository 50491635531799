import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import PageWrapper from "@/components/layout/PageWrapper";
import ReceiptCard from "./ReceiptCard";
import ItemCard from "./ItemCard";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { LineItem, Receipt } from "@/types/entities";
import useGroupedReceipts from "@/hooks/useGroupedReceipts";

const ReceiptList = React.memo(() => {
  const { address } = useParams<{ address: string }>();
  const decodedAddress = decodeURIComponent(address || '');
  const groupedReceipts = useGroupedReceipts();

  const receipts = useMemo(() => {
    return groupedReceipts[decodedAddress || ''] || [];
  }, [groupedReceipts, decodedAddress]);

  const [filteredReceipts, setFilteredReceipts] = useState<Receipt[]>([]);
  const [filteredItems, setFilteredItems] = useState<{ item: LineItem; receipt: Receipt }[]>([]);
  const [showItems, setShowItems] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const getBackgroundColorForCategory = (category: string): string => {
    let hash = 0;
    for (let i = 0; i < category.length; i++) {
      hash = category.charCodeAt(i) + ((hash << 5) - hash);
    }
    return `hsl(${hash % 360}, 70%, 80%)`;
  };

  const getTextColorForBackground = (backgroundColor: string): string => {
    const hslToRgb = (h: number, s: number, l: number) => {
      s /= 100;
      l /= 100;
      const k = (n: number) => (n + h / 30) % 12;
      const a = s * Math.min(l, 1 - l);
      const f = (n: number) => l - a * Math.max(Math.min(k(n) - 3, 9 - k(n), 1), -1);
      return [f(0), f(8), f(4)];
    };

    const hslMatch = backgroundColor.match(/\d+/g);
    if (!hslMatch) return "#000000";
    const hsl = hslMatch.map(Number);
    const [r, g, b] = hslToRgb(hsl[0], hsl[1], hsl[2]);

    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    return luminance > 0.5 ? "#000000" : "#FFFFFF";
  };

  const backgroundColor = getBackgroundColorForCategory(receipts[0]?.main_category || "");
  const textColor = getTextColorForBackground(backgroundColor);

  useEffect(() => {
    if (showItems) {
      const query = searchQuery.toLowerCase();
      const allItems = receipts.flatMap(receipt =>
        Array.isArray(receipt.line_items) ? receipt.line_items.map((item) => ({ item, receipt })) : []
      );

      const sortedItems = allItems.sort((a, b) => {
        if (a.item.product_name < b.item.product_name) {
          return -1;
        }
        if (a.item.product_name > b.item.product_name) {
          return 1;
        }
        return 0;
      });

      setFilteredItems(
        sortedItems.filter(({ item }) =>
          item.product_name.toLowerCase().includes(query)
        )
      );
      setFilteredReceipts([]); // Clear filtered receipts when showing items
    } else {
      setFilteredReceipts(receipts);
      setFilteredItems([]); // Clear filtered items when showing receipts
    }
  }, [receipts, searchQuery, showItems]);

  const memoizedReceipts = useMemo(() => {
    if (!filteredReceipts || !Array.isArray(filteredReceipts)) {
      return <div>No receipts found</div>;
    }

    return filteredReceipts.map((receipt, index) => (
      <ReceiptCard key={`${receipt.id}-${index}`} receipt={receipt} backgroundColor={backgroundColor} textColor={textColor} />
    ));
  }, [backgroundColor, filteredReceipts, textColor]);

  const memoizedItems = useMemo(() => {
    if (!filteredItems || !Array.isArray(filteredItems)) {
      return <div>No items found</div>;
    }

    return filteredItems.map(({ item, receipt }, index) => (
      <ItemCard
        key={`${receipt.id}-${item.id}-${index}`}
        item={item}
        receipt={receipt}
        backgroundColor={backgroundColor}
        textColor={textColor}
      />
    ));
  }, [backgroundColor, filteredItems, textColor]);

  return (
    <PageWrapper>
      <div className="flex justify-between max-w-md mx-auto w-[90%] pb-4">
        <Input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className=" border rounded-lg mr-2"
        />
        <Button
          variant={"secondary"}
          onClick={() => {
            setShowItems(!showItems);
            setSearchQuery("");
          }}
        >
          {showItems ? "Show Summary" : "Show Details"}
        </Button>
      </div>
      <div
        className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4
        w-[90%] mx-auto mb-10"
      >
        {showItems ? memoizedItems : memoizedReceipts}
      </div>
    </PageWrapper>
  );
});

export default ReceiptList;
