"use client";

import { useEffect, useState } from "react";
import { initSatellite } from "@junobuild/core";
import { ThemeProvider } from "./ThemeProvider"
import { TooltipProvider } from "../ui/tooltip";
import LoadingSplashScreen from "../LoadingSplashScreen";
import AuthProvider from "./AuthProvider";
import CurrenciesProvider from "./CurrenciesProvider";
import { Toaster } from "@/components/ui/toaster";

// import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

if (typeof global === "undefined") {
  window.global = window;
}

export default function Providers({ children }: { children: React.ReactNode }) {
  const [initialized, setInitialized] = useState(false);
  const [showRQTools] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      (async () => {
        if (!initialized) {
          console.log("Initializing satellite...", new Date());
          await initSatellite({
            satelliteId: import.meta.env.VITE_JUNO_ID as string,
          });
          setInitialized(true);
          console.log("Initialized satellite", new Date());
        }
      })();
    }
  }, [initialized]);

  if (!initialized) {
    return (
      <ThemeProvider
        defaultTheme="dark"
        storageKey="vite-ui-theme"
      >
        <LoadingSplashScreen />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider
      defaultTheme="dark"
      storageKey="vite-ui-theme"
    >
      <AuthProvider>
        <CurrenciesProvider>
          <TooltipProvider>
            {children}
            <Toaster />
          </TooltipProvider>
        </CurrenciesProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}
