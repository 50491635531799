import React from 'react';
import { FallbackProps } from 'react-error-boundary';

export function ErrorFallback({ resetErrorBoundary }: FallbackProps) {
  return (
    <div role="alert" style={{
      padding: '20px',
      backgroundColor: '#f8d7da',
      color: '#721c24',
      borderRadius: '5px',
      margin: '10px',
      textAlign: 'center'
    }}>
      <h2>Oops! Something went wrong.</h2>
      <p>Don't worry, it's not your fault. Here's what you can do:</p>
      <button
        onClick={resetErrorBoundary}
        style={{
          padding: '10px 20px',
          backgroundColor: '#0056b3',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          marginTop: '10px'
        }}
      >
        Try again 😄
      </button>
      <p style={{ fontSize: '0.8em', marginTop: '20px' }}>
        If the problem persists, please contact support here https://t.me/bridge23app/541
      </p>
    </div>
  );
}