import React, { useState } from 'react';
import { LineItem, Receipt } from '@/types/entities';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import CategorySelector from '@/components/CategorySelector';
import CurrencySelect from '@/components/CurrencySelect';

interface EditReceiptFormProps {
    receipt: Receipt;
    onFormDataChange: (updatedData: Receipt) => void;
}

const EditReceiptForm: React.FC<EditReceiptFormProps> = ({ receipt, onFormDataChange }) => {
    const [formData, setFormData] = useState(receipt);
    const [category, setCategory] = useState(receipt?.main_category || "");
    const [currency, setCurrency] = useState(receipt.currency || "");

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        const updatedData = {
            ...formData,
            [name]: value,
        };
        setFormData(updatedData);
        onFormDataChange(updatedData);
    };

    // const handleLocaleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const { name, value } = e.target;
    //     const updatedData = {
    //         ...formData,
    //         locale: {
    //             ...formData.locale,
    //             [name]: value,
    //         },
    //     };
    //     setFormData(updatedData);
    //     onFormDataChange(updatedData);
    // };

    const handleLineItemChange = (index: number, field: keyof LineItem, value: string | number) => {
        const updatedLineItems = formData.line_items.map((item, i) =>
            i === index ? { ...item, [field]: value } : item
        );
        const updatedData = {
            ...formData,
            line_items: updatedLineItems,
        };
        setFormData(updatedData);
        onFormDataChange(updatedData);
    };

    const handleCategoryChange = (category: string) => {
        setCategory(category);
        const updatedData = {
            ...formData,
            main_category: category,
        };
        setFormData(updatedData);
        onFormDataChange(updatedData);
    };

    const handleCategoryAdded = (newCategory: { name: string }) => {
        setCategory(newCategory.name);
    };

    const handleCurrencyChange = (currency: string) => {
        setCurrency(currency);
        const updatedData = {
            ...formData,
            currency,
        };
        setFormData(updatedData);
        onFormDataChange(updatedData);
    };

    const formatDateString = (dateString: string) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };

    return (
        <div className="border p-4 rounded">
            <div className="mb-4">
                <label>Store Name:</label>
                <Input
                    type="text"
                    name="store_name"
                    value={formData.store_name || ''}
                    onChange={handleChange}
                    className="w-full mt-2"
                />
            </div>
            <div className="mb-4">
                <label>Date:</label>
                <Input
                    type="date"
                    name="date"
                    value={formData.date ? formatDateString(formData.date) : ''}
                    onChange={handleChange}
                    className="w-full mt-2"
                />
            </div>
            <div className="mb-4">
                <label>Address:</label>
                <Input
                    type="text"
                    name="address"
                    value={formData.address || ''}
                    onChange={handleChange}
                    className="w-full mt-2"
                />
            </div>
            <div className="mb-4 flex space-x-2 w-full">
                <div className="flex-grow">
                    <label>Total Price:</label>
                    <Input
                        type="number"
                        name="total_price"
                        value={formData.total_price || ''}
                        onChange={handleChange}
                        className="mt-2 w-full"
                    />
                </div>
                <div className="w-auto ml-auto">
                    <label>Currency:</label>
                    <CurrencySelect
                        currency={currency}
                        onCurrencyChange={handleCurrencyChange}
                    />
                </div>
            </div>
            <div className="mb-4">
                <label>Main Category:</label>
                <CategorySelector
                    type="expense"
                    value={category}
                    onCategoryChange={handleCategoryChange}
                    handleCategoryAdded={handleCategoryAdded}
                />
            </div>
            <div className="mb-4">
                <label>Description:</label>
                <Textarea
                    name="description"
                    value={formData.description || ''}
                    onChange={handleChange}
                    className="w-full mt-2"
                />
            </div>

            <div>
                <label>Items:</label>
                <div className="mt-2">
                    {formData.line_items.map((item, index) => (
                        <div key={index} className="mb-4">
                            <div>
                                <div>Item {index + 1}</div>
                                <Input
                                    type="text"
                                    value={item.product_name}
                                    onChange={(e) => handleLineItemChange(index, 'product_name', e.target.value)}
                                    className="w-full mb-2"
                                    placeholder="Product Name"
                                />
                            </div>
                            <div className="flex space-x-2">
                                <div className="flex-1">
                                    <label>Quantity:</label>
                                    <Input
                                        type="number"
                                        value={item.quantity}
                                        onChange={(e) => handleLineItemChange(index, 'quantity', parseFloat(e.target.value))}
                                        className="w-[50%]"
                                        placeholder="Quantity"
                                    />
                                </div>
                                <div className="flex-1">
                                    <label>Total Price:</label>
                                    <Input
                                        type="number"
                                        value={item.total_price || ''}
                                        onChange={(e) => handleLineItemChange(index, 'total_price', parseFloat(e.target.value))}
                                        className="w-full"
                                        placeholder="Total Price"
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default EditReceiptForm;
