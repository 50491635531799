import React, { useState, useMemo } from "react";
import TransactionsList from "@/components/transactions_list/TransactionsList";
import { Button } from "@/components/ui/button";
import { useTransactions } from "@/hooks/useTransactions";
import { filterTransactionsByPeriod } from "@/utils/filterTransactionsByPeriod";
import { useLoadCategories } from "@/hooks/useLoadCategories";

const OnboardingTransactionsForm: React.FC<{ onNext: () => void; onPrev: () => void; transactionType: "income" | "expense" }> = ({ onNext, onPrev, transactionType }) => {
  useLoadCategories();

  const transactions = useTransactions();
  const [selectedPeriod] = useState("all");

  const filteredTransactions = useMemo(() => filterTransactionsByPeriod(transactions, selectedPeriod), [transactions, selectedPeriod]);

  return (
    <div className="text-white flex flex-col items-start justify-center">
      <div className="flex flex-col items-center w-full max-w-3xl px-4 md:px-10 mt-10 mx-auto">
        <TransactionsList
          transactions={filteredTransactions.filter(transaction => transaction.transaction_type === transactionType)}
        />
        <Button className="w-full mt-4" size={"lg"} onClick={onNext}>
          Proceed To The Next Step
        </Button>
        <Button
          className="w-full mt-4 animate-shimmer bg-[linear-gradient(110deg,#000103,45%,#1e2631,55%,#000103)] bg-[length:200%_100%]"
          size={"lg"}
          variant={"outline"}
          onClick={onPrev}
        >
          Add More Streams of {transactionType}
        </Button>
      </div>
    </div>
  );
};

export default OnboardingTransactionsForm;
