import { Button } from "@/components/ui/button";
import React from "react";
import animationData from "@/assets/Onboarding.mp4";

interface SingleOnboardingPageProps {
  onNext?: () => void;
}

const SingleOnboardingPage: React.FC<SingleOnboardingPageProps> = ({ onNext }) => {
  return (
    <div className="w-full sm:w-1/2 lg:w-1/3 p-4 flex flex-col h-full">
      <div className="flex flex-col items-center justify-center flex-grow">
        <div className="w-[100%] mb-10">
          <video
            autoPlay
            loop
            muted
            playsInline
            className="w-full h-full object-cover rounded-lg"
          >
            <source src={animationData} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="text-xl font-semibold mb-4 text-center">
          <p>Create your AI employee department</p>
        </div>
      </div>
      <div className="mt-4">
        <Button
          onClick={onNext}
          size={"lg"}
          className="px-4 py-2 rounded-md w-full mx-auto block"
        >
          Get Started
        </Button>
      </div>
    </div>
  );
};

export default SingleOnboardingPage;