import { updateReceipt } from "@/atoms/receipt";
import { Receipt } from "@/types/entities";

export const updateReceiptNFTStatus = async (
  receiptData: Receipt,
  setReceipts: (callback: (receipt: Receipt[]) => Receipt[]) => void
) => {
  const newData: Receipt = {
    ...receiptData,
    nft_minted: true,
  };
  try {
    if (receiptData.id) {
      const status = await updateReceipt(newData, receiptData?.id, setReceipts);
      return status;
    }
  } catch (error) {
    console.error("Error updating receipt NFT status:", error);
    throw error;
  }
};
