import React from "react";
import currencySymbolMap from "currency-symbol-map";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { LineItem, Receipt } from "@/types/entities";

interface ItemCardProps {
    item: LineItem;
    receipt: Receipt;
    backgroundColor?: string;
    textColor?: string;
}

const ItemCard: React.FC<ItemCardProps> = ({ item, receipt, backgroundColor, textColor }) => {
    const itemPrice = item.item_price != null ? item.item_price.toFixed(2) : "0.00";
    const currencySymbol = receipt?.locale?.currency ? currencySymbolMap(receipt.locale.currency) : "?";

    return (
        <Card className="flex">
            <CardHeader className="flex items-center justify-center text-sm font-bold rounded-lg h-12 w-12"
                style={{
                    backgroundColor,
                    color: textColor,
                    height: "70px",
                    width: "70px",
                }}>
                {item.product_name.substring(0, 4)}
            </CardHeader>
            <CardContent className="my-auto ps-3" style={{ height: "70px" }}>
                <div key={item.product_name} className="mt-2">
                    <p className="text-xs font-semibold">{item.product_name}</p>
                    <p className="text-xs text-gray-400">
                        {currencySymbol} {itemPrice}
                    </p>
                </div>
            </CardContent>
        </Card>
    );
};

export default ItemCard;
