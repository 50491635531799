import { useState } from 'react'
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Slider } from "@/components/ui/slider"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Textarea } from "@/components/ui/textarea"

const languages = [
  "Afrikaans", "Arabic", "Armenian", "Azerbaijani", "Belarusian", "Bosnian", "Bulgarian", "Catalan", "Chinese", "Croatian", "Czech", "Danish", "Dutch", "English", "Estonian", "Finnish", "French", "Galician", "German", "Greek", "Hebrew", "Hindi", "Hungarian", "Icelandic", "Indonesian", "Italian", "Japanese", "Kannada", "Kazakh", "Korean", "Latvian", "Lithuanian", "Macedonian", "Malay", "Marathi", "Maori", "Nepali", "Norwegian", "Persian", "Polish", "Portuguese", "Romanian", "Russian", "Serbian", "Slovak", "Slovenian", "Spanish", "Swahili", "Swedish", "Tagalog", "Tamil", "Thai", "Turkish", "Ukrainian", "Urdu", "Vietnamese", "Welsh"
]

export function AgentSettingsCard() {
  const [mainGoal, setMainGoal] = useState('general')
  const [temperature, setTemperature] = useState(0.5)

  return (
    <Card>
      <CardHeader>
        <CardTitle>Agent Settings</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div>
          <label className="block mb-2">Language:</label>
          <Select>
            <SelectTrigger>
              <SelectValue placeholder="Select language" />
            </SelectTrigger>
            <SelectContent>
              {languages.map((lang) => (
                <SelectItem key={lang} value={lang.toLowerCase()}>
                  {lang}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div>
          <label className="block mb-2">Main goal:</label>
          <Select value={mainGoal} onValueChange={setMainGoal}>
            <SelectTrigger>
              <SelectValue placeholder="Select main goal" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="general">General Chat</SelectItem>
              <SelectItem value="finance">Finance</SelectItem>
              <SelectItem value="nutrition">Nutrition and Fitness</SelectItem>
              <SelectItem value="custom">Custom</SelectItem>
            </SelectContent>
          </Select>
        </div>
        {mainGoal === 'custom' && (
          <Textarea placeholder="Enter custom prompt" />
        )}
        <div>
          <label className="block mb-2">Temperature: {temperature.toFixed(1)}</label>
          <Slider
            min={0}
            max={1}
            step={0.1}
            value={[temperature]}
            onValueChange={([value]) => setTemperature(value)}
          />
          <p className="text-sm text-gray-500 mt-2">
            Controls how creative vs focused the AI responses will be. Higher values (closer to 1.0) result in more varied and creative responses, while lower values (closer to 0.0) make responses more focused and deterministic.
          </p>
        </div>
        <div>
          <label className="block mb-2">Knowledge base:</label>
          <p className="text-sm text-gray-500">Coming Soon</p>
        </div>
        <div className="flex justify-end mt-4">
          <Button>Save Changes</Button>
        </div>
      </CardContent>
    </Card>
  )
}

