import AuthButton from "@/components/AuthButton";
import Providers from "@/components/providers/Providers";
import { useEffect } from "react";

export default function Login() {
  useEffect(() => {
  }, [])

  return (
    <Providers>
      <AuthButton />
    </Providers>
  );
}
