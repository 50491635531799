"use client";

import { MainMenuToggle } from "./MainMenuToggle";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";
import numeral from "numeral";
import iconUrl from "../../assets/ICONWhite.png";
import { fetchRewards } from "@/atoms/rewards";
import { useQuery } from "@tanstack/react-query";
import { useProfileQuery } from "@/queries/profiles";
import { getStats } from "@/api/supabase/profiles";
import { ThemeToggle } from "@/components/ThemeToggle";

export function SiteHeader() {
  const { data: profile } = useProfileQuery();

  const { data: rewards, isPending: rewardsLoading } = useQuery({
    queryKey: ["rewards"],
    queryFn: fetchRewards,
    retry: true,
    enabled: !!profile,
  });

  const { data: stats, isPending: profileStatsLoading } = useQuery({
    queryKey: ["profileStats"],
    queryFn: () => getStats(),
    retry: true,
    enabled: !!profile,
  });

  return (
    <header className="fixed top-0 z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="container flex h-14 max-w-screen-xl items-center px-2 lg:px-8">
        <div className="mr-4 flex">
          <a className="mr-6 flex items-center space-x-2" href="/">
            <span className="inline-flex items-center font-bold">
              <img
                src={iconUrl}
                alt="Bridge23"
                className="mr-2 h-[45px] w-[45px] dark:brightness-100 brightness-0"
              />
            </span>
          </a>
          <nav className="flex items-center gap-6 text-sm"></nav>
        </div>
        <div className="flex flex-1 items-center justify-end space-x-2">
          <nav className="flex items-center gap-2">
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex flex-row items-center gap-1">
                  <span className="text-lg text-foreground">
                    {stats?.streak || 0}
                  </span>
                  🔥
                </div>
              </TooltipTrigger>
              <TooltipContent>Streak</TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex flex-row items-center gap-1">
                  <span className="text-lg text-foreground">
                    {numeral(rewards?.total_points || 0)
                      .format("0a")
                      .toUpperCase()}
                  </span>
                  🙏
                </div>
              </TooltipTrigger>
              <TooltipContent>Devotion Points</TooltipContent>
            </Tooltip>
            <div className="flex flex-row items-center gap-1"></div>
            <ThemeToggle />
            <MainMenuToggle />
          </nav>
        </div>
      </div>
    </header>
  );
}
