import React from "react";

const steps = [
  { label: "Basic Info", reward: "" },
  { label: "Add Income", reward: "" },
  { label: "Add Expense", reward: "" },
  { label: "Finalize", reward: "" },
];

interface ProgressBarProps {
  currentStage: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ currentStage }) => {
  return (
    <div className="my-4 mr-5 ml-5">
      <div className="flex justify-between md:justify-center lg:justify-center md:mx-4 items-center">
        {steps.map((step, index) => (
          <div key={index} className="flex flex-col items-center md:mx-4">
            <div
              className={`w-20 h-1 ${currentStage >= index ? "bg-blue-500" : "bg-gray-300 dark:bg-gray-700"}`}
            />
            <div
              className={`mt-2 text-center ${currentStage >= index ? "text-gray-800 dark:text-gray-200" : "text-gray-500 dark:text-gray-400"} text-xs md:text-sm`}
            >
              <div>{step.label}</div>
              <div>{step.reward}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
