import React from "react";

interface ProgressBarProps {
  currentStage: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ currentStage }) => {
  const stepsCount = 5;

  return (
    <div className="flex justify-center items-center my-4">
      <div className="flex justify-between w-full max-w-3xl items-center">
        {[...Array(stepsCount)].map((_, index) => (
          <div key={index} className="flex-1 flex flex-col items-center">
            <div
              className={`w-full h-1 ${
                currentStage >= index
                  ? "bg-blue-500"
                  : "bg-gray-300 dark:bg-gray-700"
              }`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
