import { Profile } from "@/types/entities";

type AvatarProps = {
  profile: Profile | null;
  onClick?: () => void;
  className?: string;
  size?: 'small' | 'medium' | 'large';
};

export function Avatar({ profile, onClick, className = '', size = 'small' }: AvatarProps) {
  if (!profile) {
    return null; // or return a loading state or a default avatar
  }

  // Define the size classes for the avatar
  const sizeClasses = {
    small: 'w-8 h-8',
    medium: 'w-12 h-12',
    large: 'w-32 h-32'
  };

  return (
    <div
      className={`rounded-md overflow-hidden ${sizeClasses[size]} ${className}`}
      dangerouslySetInnerHTML={{ __html: profile.avatar_svg }}
      style={{ backgroundColor: profile.color }}
      onClick={onClick}
    ></div>
  );
}
