import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import currencySymbolMap from 'currency-symbol-map';
import { Receipt as BaseReceipt } from '@/types/entities';
import MintNFTButton from '@/components/nft/MintNFTButton';

interface ReceiptCardProps {
  receipt: Receipt;
  backgroundColor?: string;
  textColor?: string;
}

interface Receipt extends BaseReceipt {
  imageUrl?: string;
}

const ReceiptCard: React.FC<ReceiptCardProps> = ({ receipt, backgroundColor, textColor }) => {
  const navigate = useNavigate();

  if (!receipt) return null;

  const handleCardClick = () => {
    navigate(`/receipt/${receipt.id}`);
  };

  return (
    <Card
      key={receipt.id}
      className="flex cursor-pointer items-center justify-between w-full"
      onClick={handleCardClick}
    >
      <div className="flex items-center">
        <CardHeader
          className="flex items-center justify-center text-sm font-bold rounded-lg"
          style={{
            backgroundColor,
            color: textColor,
            height: '80px',
            width: '80px',
          }}
        >
          {receipt.main_category ? receipt.main_category.substring(0, 3) : 'N/A'}
        </CardHeader>
        <CardContent className="items-center justify-center pt-2"
          style={{ height: '80px' }}>
          <h3 className="font-semibold text-xs line-clamp-2 max-w-[110px]">{receipt.store_name || 'Unknown Store'}</h3>
          <p className="text-xs text-gray-400">Date: {receipt.date ? new Date(receipt.date).toLocaleDateString() : 'Unknown Date'}</p>
          <p className="text-sm">
            {receipt.locale && receipt.locale.currency ? currencySymbolMap(receipt.locale.currency) : '?'} {receipt.total_price ? receipt.total_price : '0.00'}
          </p>
        </CardContent>
      </div>
      <div className="ml-auto">
        <MintNFTButton receipt={receipt} />
      </div>
    </Card>
  );
};

export default ReceiptCard;
