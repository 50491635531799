import React, { useEffect } from "react";
import {
    Select,
    SelectTrigger,
    SelectContent,
    SelectItem,
    SelectValue,
} from "@/components/ui/select";
import AddCategory from "@/components/AddCategory";
import { defaultExpenseCategories, defaultIncomeCategories } from "./data/categories";
import { useRecoilState } from "recoil";
import { categoriesState, fetchAllCategories } from "@/atoms/category";

interface CategorySelectorProps {
    type?: "income" | "expense";
    value: string;
    onCategoryChange: (value: string) => void;
    handleCategoryAdded?: (newCategory: { name: string }) => void;
}

const CategorySelector: React.FC<CategorySelectorProps> = ({
    type,
    value,
    onCategoryChange,
    handleCategoryAdded,
}) => {
    const [categories, setCategories] = useRecoilState(categoriesState);

    const defaultCategories = type === "income" ? defaultIncomeCategories : defaultExpenseCategories;
    const combinedCategories = [
        ...defaultCategories.map((cat, index) => ({ ...cat, key: `default-${cat.name}-${index}` })),
        ...categories
            .filter((cat) => cat.type === type?.toLowerCase())
            .map((cat, index) => ({ ...cat, key: `custom-${cat.name}-${index}` }))
    ];

    useEffect(() => {
        const fetchCategories = async () => {
            const fetchedCategories = await fetchAllCategories();
            setCategories(fetchedCategories);
        };
        fetchCategories();
    }, [setCategories]);

    return (
        <div className="flex items-center justify-between w-full mb-4">
            <Select value={value} onValueChange={onCategoryChange}>
                <SelectTrigger className="w-full bg-background text-foreground dark:bg-background dark:text-foreground">
                    <SelectValue placeholder={`Select ${type} category`} />
                </SelectTrigger>
                <SelectContent className="max-h-80 bg-background text-foreground dark:bg-background dark:text-foreground">
                    {combinedCategories
                        .filter((cat) => cat.name)
                        .map((cat) => (
                            <SelectItem key={cat.key} value={cat.name}>
                                {cat.icon} {cat.name}
                            </SelectItem>
                        ))}
                </SelectContent>
            </Select>
            <AddCategory
                onCategoryAdded={handleCategoryAdded}
                type={type?.toLowerCase() as "income" | "expense"}
            />
        </div>
    );
};

export default CategorySelector;
