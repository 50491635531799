import { Button } from "@/components/ui/button";
import React from "react";
import { CaretLeft } from "@phosphor-icons/react";

interface AddFirstIncomeMessageProps {
  onNext?: () => void;
  onPrev?: () => void;
}

const AddFirstIncomeMessage: React.FC<AddFirstIncomeMessageProps> = ({
  onNext,
  onPrev,
}) => {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-gray-900 bg-opacity-75 backdrop-blur-sm">
      <div className="bg-gradient-to-br from-blue-500/20 to-purple-500/20 p-6 rounded-lg shadow-lg text-white w-96 mx-4">
        <h2 className="text-xl font-semibold mb-4">Great Job!</h2>
        <p className="mb-6">
          Let's start by adding your first recurring income. It can be your
          salary, monthly allowance, rental income.
        </p>
        <div className="flex justify-between">
          <Button
            onClick={onPrev}
            className="flex items-center bg-gray-700 px-4 py-2 rounded-md hover:bg-gray-600"
          >
            <CaretLeft />
            <span className="ml-2">Basic Info</span>
          </Button>
          <Button
            onClick={onNext}
            className="bg-blue-600 px-4 py-2 rounded-md hover:bg-blue-500"
          >
            Add First Income
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddFirstIncomeMessage;
