import React, { useRef } from "react";
import { CloudArrowUp } from "@phosphor-icons/react";
import Loading from "../upload/UploadLoading";
import { useAnalyzeReceipt } from "./upload-drawer-receipts";

interface FileUploadProps {
  onFileUpload?: (file: File) => void;
  customMessage?: string;
}

const FileUpload: React.FC<FileUploadProps> = ({
  onFileUpload,
  customMessage,
}) => {
  const { startAnalysis, isLoading, isAnalyzing, error } = useAnalyzeReceipt();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFile(file);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      handleFile(file);
    }
  };

  const handleFile = (file: File) => {
    startAnalysis({
      target: { files: [file] },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
    if (onFileUpload) {
      onFileUpload(file);
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <div>
      {(isLoading || isAnalyzing) && (
        <Loading
          statusText={
            isLoading ? "Uploading..." : isAnalyzing ? "Analyzing..." : ""
          }
        />
      )}
      <div
        className="
        py-12
        bg-[#1A2132] 
        relative 
        flex flex-col items-center justify-center 
        rounded-lg
        my-4"
      >
        <div
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onClick={() => fileInputRef.current?.click()}
          style={{ cursor: "pointer" }}
        >
          <div className="flex flex-col items-center text-center text-gray-200 text-sm">
            <CloudArrowUp size={50} />
            <p className="text-lg">Upload an image</p>
            <p className="text-sm text-gray-400">
              {customMessage ||
                "Add a receipt or invoice from your camera roll or take a picture"}
            </p>
          </div>
          {error && (
            <div className="flex mt-8 mx-8 p-4 text-red-100 justify-center text-center border rounded-md border-red-500 bg-red-900">
              {error}
            </div>
          )}
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="hidden"
            ref={fileInputRef}
          />
        </div>
      </div>
    </div>
  );
};

export default FileUpload;