// api/juno/telegram_receipts_data.ts
import { getDoc, setDoc, listDocs, deleteDoc } from "@junobuild/core";
import { getSatteliteOptions } from "..";
import { Receipt } from "../../types/entities";

// Constants
const TELEGRAM_RECEIPTS_COLLECTION = "telegram_receipts";

export class JunoTelegramReceiptsDataAPI {
  async fetchAllReceiptsData(): Promise<Receipt[]> {
    const response = await listDocs<Receipt>({
      collection: TELEGRAM_RECEIPTS_COLLECTION,
      satellite: getSatteliteOptions(),
    });

    if (!response.items) {
      return [];
    }

    return response.items.map((doc) => ({ id: doc.key, ...doc.data }));
  }

  async update(receiptKey: string, data: Receipt): Promise<Receipt> {
    const existingReceipt = await getDoc<Receipt>({
      collection: TELEGRAM_RECEIPTS_COLLECTION,
      key: receiptKey,
      satellite: getSatteliteOptions(),
    });

    if (!existingReceipt) {
      throw new Error("Receipt not found");
    }

    await setDoc<Receipt>({
      collection: TELEGRAM_RECEIPTS_COLLECTION,
      doc: {
        key: receiptKey,
        data,
        version: existingReceipt.version,
      },
      satellite: getSatteliteOptions(),
    });

    return { id: receiptKey, ...data };
  }

  async delete(receiptKey: string): Promise<{ success: boolean; message: string }> {
    const existingReceipt = await getDoc<Receipt>({
      collection: TELEGRAM_RECEIPTS_COLLECTION,
      key: receiptKey,
      satellite: getSatteliteOptions(),
    });

    if (!existingReceipt) {
      return {
        success: false,
        message: `Receipt with key ${receiptKey} not found.`,
      };
    }

    try {
      await deleteDoc({
        collection: TELEGRAM_RECEIPTS_COLLECTION,
        doc: {
          key: receiptKey,
          version: existingReceipt.version,
          data: {}, // Provide an empty object to satisfy the type requirement
        },
        satellite: getSatteliteOptions(),
      });

      return {
        success: true,
        message: `Receipt with key ${receiptKey} successfully deleted.`,
      };
    } catch (error) {
      console.error(`Error deleting receipt with key ${receiptKey}:`, error);
      return {
        success: false,
        message: `Failed to delete receipt with key ${receiptKey}.`,
      };
    }
  }
}