import React from "react";
import { useRecoilValue } from "recoil";
import { currenciesState } from "@/atoms/exchangeRate";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface CurrencySelectProps {
  currency: string;
  onCurrencyChange: (currency: string) => void;
}

const CurrencySelect: React.FC<CurrencySelectProps> = ({
  currency,
  onCurrencyChange,
}) => {
  const currencies = useRecoilValue(currenciesState);

  return (
    <Select onValueChange={onCurrencyChange}>
      <SelectTrigger className="w-[100px] mt-2">
        <SelectValue placeholder={currency} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {currencies && currencies[0] ? (
            currencies[0].map((curr) => (
              <SelectItem key={curr.name} value={curr.name}>
                {curr.name}
              </SelectItem>
            ))
          ) : (
            <SelectItem value="none" disabled>
              No currencies available
            </SelectItem>
          )}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export default CurrencySelect;
