// components/forms/ProfileFormBase.tsx
"use client";

import { createAvatar } from "@dicebear/core";
import { openPeeps } from "@dicebear/collection";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Profile } from "@/types/entities";
import { Checkbox } from "@/components/ui/checkbox";
import { ArrowClockwise } from "@phosphor-icons/react";
import { getRandomColor } from "@/hooks/useBackground";
import { isTMA, retrieveLaunchParams } from "@telegram-apps/sdk";
import { useToast } from "@/components/ui/use-toast";
import { useLocalTimezone } from "@/components/data/timezones";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

export type ProfileFormIntroProps = {
  profile: Profile | null;
  onSubmit: (data: FormSchema) => void;
  title: string;
  buttonText?: string;
  isEditMode: boolean;
};

const formSchema = z.object({
  avatar_svg: z.string().optional(),
  color: z.string().optional(),
  username: z
    .string()
    .min(3, { message: "Username should be at least 3 characters long" })
    .max(100, { message: "Username should be <= 100 characters long" }),
  timezone: z.string({
    required_error: "Timezone is required",
  }),
  privacy_policy: z
    .boolean()
    .refine((val) => val === true, {
      message: "You must agree to the privacy policy",
    })
    .nullable(),
});

export type FormSchema = z.infer<typeof formSchema>;

export const ProfileFormIntro: React.FC<ProfileFormIntroProps> = ({
  profile,
  onSubmit,
  title,
  buttonText = "Proceed",
  isEditMode = false,
}) => {
  const { toast } = useToast();
  const localTimezone = useLocalTimezone();
  const [isUsernameEditDisabled, setIsUsernameEditDisabled] =
    useState<boolean>(false);
  const [, setTelegramUsername] = useState<string>("");

  const [backgroundColor, setBackgroundColor] = useState<string>(
    profile?.color || getRandomColor()
  );
  const [seed, setSeed] = useState<string>(new Date().toJSON());
  const [avatar_svg, setavatar_svg] = useState<string>(
    profile?.avatar_svg || genAvatar(seed)
  );
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    async function init() {
      if (await isTMA()) {
        const launchParams = await retrieveLaunchParams();
        const username = launchParams?.initData?.user?.username || "";
        // setTelegramUsername(username);
        if (username) {
          setIsUsernameEditDisabled(true);
        }
        form.setValue("username", username);
      }
    }
    init();
  }, []);

  function genAvatar(seed: string): string {
    return createAvatar(openPeeps, {
      seed: seed,
      backgroundColor: [backgroundColor],
    }).toString();
  }

  function handleRegenerate(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    e.preventDefault();
    const _color = getRandomColor();
    setBackgroundColor(_color);
    const _newSeed = new Date().toJSON();
    setSeed(_newSeed);
    const _newavatar_svg = genAvatar(_newSeed); // Fix seed issue here
    setavatar_svg(_newavatar_svg);
    form.setValue("avatar_svg", _newavatar_svg);
    form.setValue("color", _color);
  }

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: profile?.username ?? "",
      timezone: localTimezone ?? "",
      privacy_policy: isEditMode ? null : false, // Conditional default value based on mode
    },
  });

  useEffect(() => {
    form.setValue("timezone", localTimezone);
  }, [localTimezone, form]);

  const handleSubmit = async (data: FormSchema) => {
    setSaving(true);

    try {
      await onSubmit({ ...data, avatar_svg });
      toast({ title: "Profile updated successfully" });
    } catch (error) {
      if (error instanceof z.ZodError) {
        error.errors.forEach((err) => {
          form.setError(err.path[0] as keyof FormSchema, {
            type: "manual",
            message: err.message,
          });
        });
      } else {
        toast({ title: "Failed to update profile" });
      }
    } finally {
      setSaving(false);
    }
  };

  return (
    <Card className="w-full sm:w-1/2 lg:w-1/3 max-w-2xl mx-2 border-none flex flex-col h-full">
      <CardHeader>
        <CardTitle className="text-center">{title}</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col flex-grow">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="space-y-4 flex flex-col h-full"
          >
            <div className="flex flex-col gap-4 items-center flex-grow">
              <div
                className="w-64 h-64 rounded-lg mx-auto"
                style={{ backgroundColor }}
                dangerouslySetInnerHTML={{ __html: avatar_svg }}
              ></div>
              <Button
                variant="secondary"
                onClick={handleRegenerate}
                className="flex justify-center items-center w-48 mx-auto"
              >
                Regenerate
              </Button>

              <FormField
                control={form.control}
                name="username"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>
                      Username <span className="text-red-500">*</span>
                    </FormLabel>
                    <FormControl>
                      <Input
                        placeholder="jacksparrow"
                        {...field}
                        maxLength={100}
                        disabled={saving}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="mt-auto">
              {!isEditMode && (
                <FormField
                  control={form.control}
                  name="privacy_policy"
                  render={({ field }) => (
                    <FormItem className="flex items-center space-x-2 mb-4">
                      <FormControl>
                        <Checkbox
                          id="privacy_policy"
                          checked={field.value || false}
                          onCheckedChange={(checked) => field.onChange(checked)}
                          onBlur={field.onBlur}
                          name={field.name}
                          ref={field.ref}
                          className="mt-2"
                        />
                      </FormControl>
                      <FormLabel htmlFor="privacy_policy" className="text-sm">
                        I agree to the Privacy Policy{" "}
                        <span className="text-red-500">*</span>
                      </FormLabel>
                    </FormItem>
                  )}
                />
              )}

              <Button
                type="submit"
                className="w-full"
                disabled={
                  saving || (!isEditMode && !form.watch("privacy_policy"))
                }
              >
                {saving ? (
                  <>
                    <ArrowClockwise className="mr-2 h-4 w-4 animate-spin" />
                    Saving...
                  </>
                ) : (
                  <>{buttonText}</>
                )}
              </Button>
            </div>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
};
