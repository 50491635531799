// atoms/exchangeRateAtom.ts
import { atom, selector } from 'recoil';
import { HttpAgent, Actor } from '@dfinity/agent';
import { idlFactory } from './exchange_rate.did';
import { Currency } from '@/types/entities';

const canisterId = import.meta.env.VITE_CANISTER_ID_EXCHANGE_RATE as string;
const agent = new HttpAgent({ host: 'https://ic0.app' });

export const currenciesState = atom<Currency[][]>({
  key: 'currenciesState',
  default: [],
});

export const fetchCurrencies = selector<Currency[][]>({
  key: 'fetchCurrencies',
  get: async () => {
    try {
      const actor = Actor.createActor(idlFactory, { agent, canisterId });
      const data: Currency[][] = await actor.getCurrencies() as Currency[][];
      return data;
    } catch (error) {
      console.error('Error fetching currencies:', error);
      return [];
    }
  },
});
