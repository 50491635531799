import React, { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Receipt as BaseReceipt, Transaction } from "@/types/entities";
import {
  createTransaction,
  deleteTransaction,
  transactionsState,
  updateTransaction,
} from "@/atoms/transaction";
import TransactionForm from "./transaction_UI/TransactionForm";
import AmountInput from "./transaction_UI/AmountInput";
import { useNavigate } from "react-router-dom";
import {
  deleteReceipt,
  fetchAllReceipts,
  receiptsState,
} from "@/atoms/receipt";
import { useProfileQuery } from "@/queries/profiles";

interface Receipt extends BaseReceipt {
  imageUrl: string;
}

interface AddTransactionFormProps {
  mode: "edit" | "new";
  transaction?: Transaction;
  onNext?: () => void;
  onPrev?: () => void;
  onSave?: (data: {
    amount: string;
    title: string;
    category: string;
    recurrence: string;
  }) => void;
  type: "income" | "expense";
  isInDrawer?: boolean;
  onCancel?: () => void;
}

const AddTransactionForm: React.FC<AddTransactionFormProps> = ({
  mode,
  transaction,
  onNext,
  onPrev,
  onSave,
  type,
  isInDrawer = false,
  onCancel,
}) => {
  const initialAmount = transaction
    ? (Math.abs(transaction.amount_cents) / 100).toFixed(2)
    : "0.00";
  const [amount, setAmount] = useState<string>(initialAmount);
  const { data: profile } = useProfileQuery();
  const [currency, setCurrency] = useState<string>(
    transaction?.amount_currency || profile?.currency?.toUpperCase() || "USD"
  );
  const [title, setTitle] = useState(transaction?.name || "");
  const [category, setCategory] = useState(transaction?.category || "");
  const [recurrence, setRecurrence] = useState(
    transaction?.recurring_frequency || "once"
  );
  const [date, setDate] = useState(
    transaction ? new Date(transaction.date) : new Date()
  );
  const [isSaving, setIsSaving] = useState(false);
  const setTransactions = useSetRecoilState(transactionsState);
  const [receipts, setReceipts] = useRecoilState(receiptsState);
  const navigate = useNavigate();

  useEffect(() => {
    if (transaction?.receipt_id) {
      const fetchReceiptsData = async () => {
        const receiptsData = await fetchAllReceipts();
        setReceipts(receiptsData);
      };
      fetchReceiptsData();
    }
  }, [setReceipts, transaction?.receipt_id]);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");
    const numberValue = Number(value);
    if (!isNaN(numberValue)) {
      if (value.length > 12) {
        setAmount(value.slice(0, 12));
      } else {
        setAmount(value);
      }
    }
  };

  const handleKeypadClick = (value: string) => {
    if (value === "back") {
      setAmount((prev) => (prev.length > 1 ? prev.slice(0, -1) : "0.00"));
    } else {
      setAmount((prev) => (prev === "0.00" ? value : prev + value));
    }
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleCategoryChange = (value: string) => {
    setCategory(value);
  };

  const handleRecurrenceChange = (value: string) => {
    setRecurrence(value);
  };

  const handleSaveAndNext = async () => {
    if (isSaving) return;

    if (!amount || !title || !category) {
      if (onNext) onNext();
      return;
    }

    setIsSaving(true);

    const parsedAmount = parseFloat(amount);
    const amountInCents = Math.round(
      type === "expense" ? parsedAmount * 100 : parsedAmount * 100
    );
    const now = new Date();
    const localOffset = now.getTimezoneOffset();
    const transactionData: Transaction = {
      date: date.toISOString(),
      transaction_type: type,
      is_recurring: recurrence !== "",
      recurring_frequency: recurrence,
      amount_cents: amountInCents,
      amount_currency: currency,
      name: title,
      category: category,
      receipt_id: transaction?.receipt_id,
      profile_id: profile?.id,
      location: transaction?.location,
      offset: localOffset,
    };

    try {
      if (mode === "new") {
        await createTransaction(transactionData, setTransactions);
      } else {
        await updateTransaction(
          transactionData,
          transaction?.id || "",
          setTransactions
        );
        navigate("/");
      }
      if (onSave) {
        onSave({
          amount,
          title,
          category,
          recurrence,
        });
      }
      if (!isInDrawer && onNext) {
        onNext();
      }
    } catch (error) {
      console.error("Error saving transaction:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCategoryAdded = (newCategory: { name: string }) => {
    setCategory(newCategory.name);
  };

  const handleDelete = async () => {
    if (transaction && transaction.id) {
      setIsSaving(true);
      try {
        if (transaction.receipt_id) {
          const receiptToDelete = receipts.find(
            (receipt) => receipt.id === transaction.id
          ) as Receipt | undefined;
          if (receiptToDelete) {
            await deleteReceipt(
              receiptToDelete,
              transaction.id,
              receiptToDelete.imageUrl,
              setReceipts
            );
          }
        }
        await deleteTransaction(transaction.id, setTransactions);
        navigate("/");
      } catch (error) {
        console.error("Error deleting transaction:", error);
      } finally {
        setIsSaving(false);
      }
    }
  };

  return (
    <div className="max-w-md w-full mx-auto">
      <AmountInput
        amount={amount}
        setCurrency={setCurrency}
        handleAmountChange={handleAmountChange}
        handleKeypadClick={handleKeypadClick}
        currency={currency}
      />
      <TransactionForm
        mode={mode}
        transaction={transaction}
        type={type}
        selectedDate={date}
        onTitleChange={handleTitleChange}
        onCategoryChange={handleCategoryChange}
        onRecurrenceChange={handleRecurrenceChange}
        onDateChange={setDate}
        handleCategoryAdded={handleCategoryAdded}
        handleSaveAndNext={handleSaveAndNext}
        handleCancel={onCancel}
        handlePrev={onPrev}
        handleDelete={handleDelete}
        amount={amount}
        title={title}
        category={category}
        recurrence={recurrence}
        isSaving={isSaving}
        isInDrawer={isInDrawer}
      />
    </div>
  );
};

export default AddTransactionForm;
