// pages/onboarding/OnboardingPage.tsx
"use client";

import PageWrapper from "@/components/layout/PageWrapper";
import { NewProfileForm } from "@/components/forms/NewProfileForm";
import { authState } from "@/atoms/auth";
import {
  useRecoilState,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import AddFirstIncomeMessage from "./components/AddFirstIncomeMessage";
import IncomeListPage from "./components/IncomeListPage";
import AddFirstExpenseMessage from "./components/AddFirstExpenseMessage";
import ExpenseListPage from "./components/ExpenseListPage";
import FinishMessage from "./components/FinishMessage";
import { OnboardingProvider, useOnboarding } from "./context/OnboardingContext";
import ProgressBar from "./components/ProgressBar";
import TransactionForm from "@/components/forms/TransactionForm";
import { Profile } from "@/types/entities";
import Loading from "@/components/LoadingSplashScreen";
import { currenciesState, fetchCurrencies } from "@/atoms/exchangeRate";
import { useEffect } from "react";
import { useProfileQuery } from "@/queries/profiles";

type StepComponentProps = {
  onNextStep: (step: number) => void;
  onPrevStep: (step: number) => void;
  profile: Profile | null;
  authKey?: string;
};

const saveDataToDatabase = (data: unknown) => {
  console.log(data);
};

const steps = [
  ({ onNextStep, profile, authKey }: StepComponentProps) => (
    <NewProfileForm
      profile={profile}
      authKey={authKey!}
      onNext={() => onNextStep(1)}
    />
  ),

  ({ onNextStep, onPrevStep }: StepComponentProps) => (
    <AddFirstIncomeMessage
      onNext={() => onNextStep(2)}
      onPrev={() => onPrevStep(0)}
    />
  ),

  ({ onNextStep, onPrevStep }: StepComponentProps) => (
    <div className="max-w-md mx-auto w-[90%]">
      <TransactionForm
        mode="new"
        type="income"
        onNext={() => onNextStep(3)}
        onPrev={() => onPrevStep(1)}
        onSave={saveDataToDatabase}
      />
    </div>
  ),

  ({ onNextStep, onPrevStep }: StepComponentProps) => (
    <IncomeListPage onNext={() => onNextStep(4)} onPrev={() => onPrevStep(2)} />
  ),

  ({ onNextStep, onPrevStep }: StepComponentProps) => (
    <AddFirstExpenseMessage
      onNext={() => onNextStep(5)}
      onPrev={() => onPrevStep(3)}
    />
  ),

  ({ onNextStep, onPrevStep }: StepComponentProps) => (
    <div className="max-w-md mx-auto w-[90%]">
      <TransactionForm
        mode="new"
        type="expense"
        onNext={() => onNextStep(6)}
        onPrev={() => onPrevStep(4)}
        onSave={saveDataToDatabase}
      />
    </div>
  ),

  ({ onNextStep, onPrevStep }: StepComponentProps) => (
    <ExpenseListPage
      onNext={() => onNextStep(7)}
      onPrev={() => onPrevStep(5)}
    />
  ),

  ({ onPrevStep }: StepComponentProps) => (
    <FinishMessage onPrev={() => onPrevStep(6)} />
  ),
];

const getProgressStage = (step: number): number => {
  if (step <= 1) return 0;
  if (step <= 3) return 1;
  if (step <= 5) return 2;
  return 3;
};

function OnboardingSteps() {
  const { currentStep, setCurrentStep } = useOnboarding();
  const { data: profile } = useProfileQuery();

  const StepComponent = steps[currentStep];
  const currenciesLoadable = useRecoilValueLoadable(fetchCurrencies);
  const setCurrencies = useSetRecoilState(currenciesState);

  const handleNextStep = (step: number) => {
    setCurrentStep(step);
  };

  const handlePrevStep = (step: number) => {
    setCurrentStep(step);
  };

  const currentStage = getProgressStage(currentStep);

  useEffect(() => {
    const loadCurrencies = async () => {
      if (currenciesLoadable.state === "hasValue") {
        setCurrencies(currenciesLoadable.contents);
      }
    };

    loadCurrencies();
  }, [setCurrencies, currenciesLoadable]);

  return (
    <>
      <ProgressBar currentStage={currentStage} />
      <StepComponent
        profile={profile}
        onNextStep={handleNextStep}
        onPrevStep={handlePrevStep}
      />
    </>
  );
}

function Onboarding() {
  const [auth] = useRecoilState(authState);

  if (!auth) {
    return <Loading />;
  }

  return (
    <OnboardingProvider userKey={auth.key}>
      <PageWrapper>
        <div className="text-center text-gray-800 dark:text-gray-200 text-xl">
          Let's setup your profile
        </div>
        <OnboardingSteps />
      </PageWrapper>
    </OnboardingProvider>
  );
}

export default Onboarding;
