// api/juno/telegram_receipts.ts
import { getDoc, setDoc, listDocs, deleteDoc } from "@junobuild/core";
import { getSatteliteOptions } from "..";
import { Transaction } from "../../types/entities";

// Constants
const TELEGRAM_RECEIPTS_COLLECTION = "telegram_transactions";

export class JunoTelegramReceiptsAPI {
  async fetchAllReceipts(): Promise<Transaction[]> {
    const response = await listDocs<Transaction>({
      collection: TELEGRAM_RECEIPTS_COLLECTION,
      satellite: getSatteliteOptions(),
    });

    if (!response.items) {
      return [];
    }

    return response.items.map((doc) => ({ id: doc.key, ...doc.data }));
  }

  async update(transactionKey: string, data: Transaction): Promise<Transaction> {
    const existingTransaction = await getDoc<Transaction>({
      collection: TELEGRAM_RECEIPTS_COLLECTION,
      key: transactionKey,
      satellite: getSatteliteOptions(),
    });

    if (!existingTransaction) {
      throw new Error("Transaction not found");
    }

    await setDoc<Transaction>({
      collection: TELEGRAM_RECEIPTS_COLLECTION,
      doc: {
        key: transactionKey,
        data,
        version: existingTransaction.version,
      },
      satellite: getSatteliteOptions(),
    });

    return { id: transactionKey, ...data };
  }

  async delete(transactionKey: string): Promise<{ success: boolean; message: string }> {
    const existingTransaction = await getDoc<Transaction>({
      collection: TELEGRAM_RECEIPTS_COLLECTION,
      key: transactionKey,
      satellite: getSatteliteOptions(),
    });

    if (!existingTransaction) {
      return {
        success: false,
        message: `Transaction with key ${transactionKey} not found.`,
      };
    }

    try {
      await deleteDoc({
        collection: TELEGRAM_RECEIPTS_COLLECTION,
        doc: {
          key: transactionKey,
          version: existingTransaction.version,
          data: {}, // Provide an empty object to satisfy the type requirement
        },
        satellite: getSatteliteOptions(),
      });

      return {
        success: true,
        message: `Transaction with key ${transactionKey} successfully deleted.`,
      };
    } catch (error) {
      console.error(`Error deleting transaction with key ${transactionKey}:`, error);
      return {
        success: false,
        message: `Failed to delete transaction with key ${transactionKey}.`,
      };
    }
  }
}