import Providers from "@/components/providers/Providers";
import { Outlet, useLocation } from "react-router-dom";
import { SiteHeader } from "./SiteHeader";
import { SiteFooter } from "./SiteFooter";

export default function RootLayout() {
  const location = useLocation();

  return (
    <Providers>
      <main>
        {!location.pathname.startsWith("/points") && !location.pathname.startsWith("/intro") && <SiteHeader />}
        <Outlet />
        {!location.pathname.startsWith("/onboarding") && !location.pathname.startsWith("/intro") && <SiteFooter />}
      </main>
    </Providers>
  );
}