"use client";

import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from "react";

interface OnboardingContextType {
  currentStep: number;
  setCurrentStep: (step: number) => void;
}

const OnboardingContext = createContext<OnboardingContextType | undefined>(
  undefined,
);

export const useOnboarding = (): OnboardingContextType => {
  const context = useContext(OnboardingContext);
  if (!context) {
    throw new Error("useOnboarding must be used within an OnboardingProvider");
  }
  return context;
};

interface OnboardingProviderProps {
  children: ReactNode;
  userKey: string;
}

export const OnboardingProvider: React.FC<OnboardingProviderProps> = ({
  children,
  userKey,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(() => {
    const saved = localStorage.getItem(`currentStep_${userKey}`);
    return saved ? JSON.parse(saved) : 0;
  });

  useEffect(() => {
    localStorage.setItem(`currentStep_${userKey}`, JSON.stringify(currentStep));
  }, [currentStep, userKey]);

  return (
    <OnboardingContext.Provider value={{ currentStep, setCurrentStep }}>
      {children}
    </OnboardingContext.Provider>
  );
};
