import { Profile, Transaction } from "@/types/entities";
import { _getHeaders, URL_BASE } from "./shared";

export async function getStats() {
  const response = await fetch(`${URL_BASE}/stats`, {
    method: "GET",
    headers: _getHeaders(),
  });

  if (!response.ok) {
    throw new Error("Failed to fetch transactions");
  }

  return response.json();
}

// export async function getProfiles() {
//   const response = await fetch(`${URL_BASE}/profiles`, {
//     method: "GET",
//     headers: _getHeaders(),
//   });

//   if (!response.ok) {
//     throw new Error("Failed to fetch profiles");
//   }

//   return response.json();
// }

export async function getProfile() {
  console.log("Fetching profile of user...");
  const response = await fetch(`${URL_BASE}/profiles/me`, {
    method: "GET",
    headers: _getHeaders(),
  });

  if (response.status === 404) {
    return null;
  }

  if (!response.ok) {
    throw new Error("Failed to fetch profile");
  }

  return response.json();
}

export async function createProfile(profile: Profile) {
  const response = await fetch(`${URL_BASE}/profiles`, {
    method: "POST",
    headers: _getHeaders(),
    body: JSON.stringify(profile),
  });

  if (!response.ok) {
    throw new Error("Failed to create profile");
  }

  return response.json();
}

export async function updateProfile(profile: Profile) {
  const response = await fetch(`${URL_BASE}/profiles/me`, {
    method: "PUT",
    headers: _getHeaders(),
    body: JSON.stringify(profile),
  });

  if (!response.ok) {
    throw new Error("Failed to update profile");
  }

  return response.json();
}

export async function deleteProfile(
  profileId: string
): Promise<{ success: boolean; message: string }> {
  const response = await fetch(`${URL_BASE}/profiles/me`, {
    method: "DELETE",
    headers: _getHeaders(),
  });

  if (!response.ok) {
    return { success: false, message: "Failed to delete profile" };
  }

  return { success: true, message: "Profile deleted successfully" };
}
