import React, { useState } from 'react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { setupLedger } from '@/utils/ledger';
import { useToast } from '@/components/ui/use-toast';
// import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { ArrowUpRight } from "lucide-react";
import { Ed25519KeyIdentity } from '@dfinity/identity';
import { Principal } from '@dfinity/principal';
import { AccountIdentifier  } from '@dfinity/ledger-icp';

export const SendICPForm: React.FC<{ identity: Ed25519KeyIdentity, onTransferComplete?: () => void }> = ({ 
  identity, 
  onTransferComplete 
}) => {
  const [address, setAddress] = useState('');
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false)

  const { toast } = useToast()

  const handleSend = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      const ledger = await setupLedger(identity);
      const amountE8s = BigInt(Math.floor(Number(amount) * 100000000));
      const toAccountId = AccountIdentifier.fromPrincipal({
        principal: Principal.fromText(address)
      });
  
      const result = await ledger.transfer({
        to: toAccountId,
        amount: amountE8s,
        memo: BigInt(0),
        fee: BigInt(10000),
      });

      console.log("Transfer result:", result);
  
      toast({
        title: "Transfer Successful",
        description: `Successfully sent ${amount} ICP to ${address}`,
      });

      setIsOpen(false);
      setAddress('');
      setAmount('');
      
      // Trigger balance refresh
      if (onTransferComplete) {
        onTransferComplete();
      }
  
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Transfer Failed",
        description: error instanceof Error ? error.message : "Failed to send ICP",
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="ghost" size="icon">
          <ArrowUpRight className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Send ICP</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSend} className="space-y-4">
          <div className="space-y-2">
            <label className="text-sm font-medium">Recipient Address</label>
            <Input
              placeholder="Enter principal ID"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium">Amount</label>
            <Input
              type="number"
              step="0.00000001"
              placeholder="Amount in ICP"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
              min="0"
            />
          </div>
          <div className="flex justify-end space-x-2">
            <Button type="button" variant="outline" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button type="submit" disabled={loading}>
              {loading ? 'Sending...' : 'Send ICP'}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};