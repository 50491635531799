import { fetchAllTransactions, transactionsState } from "@/atoms/transaction";
import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

export const useTransactions = () => {
    const transactions = useRecoilValue(transactionsState);
    const setTransactions = useSetRecoilState(transactionsState);
  
    useEffect(() => {
      const loadTransactions = async () => {
        const allTransactions = await fetchAllTransactions();
        setTransactions(allTransactions);
      };
  
      loadTransactions();
    }, [setTransactions]);
  
    return transactions;
  };