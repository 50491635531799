import { useErrorBoundary } from 'react-error-boundary';

export const useCustomErrorBoundary = () => {
  const { showBoundary } = useErrorBoundary();

  const handleError = (error: Error) => {
    console.error('An error occurred:', error);
    showBoundary(error);
  };

  return { handleError };
};