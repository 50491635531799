// components/data/timezones.ts
import { useEffect, useState } from "react";

// @ts-ignore
const allTimezones = Intl.supportedValuesOf("timeZone");

export function useLocalTimezone() {
  const [localTimezone, setLocalTimezone] = useState<string>(""); // Initialize with an empty string

  useEffect(() => {
    const detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setLocalTimezone(detectedTimezone);
  }, []);

  return localTimezone;
}

export const timezones = allTimezones.map((tz: string) => ({
  label: tz,
  value: tz,
}));
