import { useState, useEffect } from 'react'
import { useTheme } from "next-themes"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Slider } from "@/components/ui/slider"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Textarea } from "@/components/ui/textarea"
import { MessageCircle, Mic, Video, Link, File, Image, Send, Users, ArrowLeft } from 'lucide-react'
import GuideGroupVideoLight from '@/assets/GuideGroupVideoLight.mp4'
import GuideGroupVideoDark from '@/assets/GuideGroupVideoDark.mp4'

interface Group {
  id: number;
  name: string;
  membersCount: number;
  credits: number;
  hasAdminAccess: boolean;
}

interface GroupAgentSettingsProps {
  group: Group;
  onBack: () => void;
}

interface StatsIconProps {
  Icon: React.ComponentType<any>;
  count: number;
  isSent?: boolean;
}

export function GroupsTab() {
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const { theme } = useTheme()
  const [key, setKey] = useState(0)

  useEffect(() => {
    // Force video reload when theme changes
    setKey(prev => prev + 1)
  }, [theme])

  const groups: Group[] = [
    { id: 1, name: 'Group 1', membersCount: 10, credits: 100, hasAdminAccess: true },
    { id: 2, name: 'Group 2', membersCount: 15, credits: 200, hasAdminAccess: true },
    { id: 3, name: 'Group 3', membersCount: 8, credits: 50, hasAdminAccess: false },
  ];

  if (selectedGroup) {
    return <GroupAgentSettings group={selectedGroup} onBack={() => setSelectedGroup(null)} />;
  }

  return (
    <div className="space-y-6 mb-4">
      <Card>
        <CardHeader>
          <CardTitle>Groups</CardTitle>
        </CardHeader>
        <CardContent>
          <ul className="space-y-4">
            {groups.map((group) => (
              <li key={group.id} className="flex justify-between items-center p-4 border rounded-lg">
                <div>
                  <h3 className="font-semibold">{group.name}</h3>
                  <p className="text-sm text-gray-500">
                    <Users className="inline mr-1" size={16} /> {group.membersCount} members
                  </p>
                </div>
                <div className="text-right">
                  <p>Credits: {group.credits}</p>
                  {!group.hasAdminAccess && (
                    <p className="text-yellow-500">⚠️ No Admin Access</p>
                  )}
                </div>
                {group.hasAdminAccess && (
                  <Button onClick={() => setSelectedGroup(group)}>Settings</Button>
                )}
              </li>
            ))}
          </ul>
        </CardContent>
      </Card>
      <div className="mt-6">
        <h3 className="font-semibold mb-2">How to add the bot to a group:</h3>
        <video 
          key={key}
          autoPlay 
          muted
          loop
          controls
          className="w-full max-w-md mx-auto"
        >
          <source 
            src={theme === 'dark' ? GuideGroupVideoDark : GuideGroupVideoLight} 
            type="video/mp4" 
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  )
}

function GroupAgentSettings({ group, onBack }: GroupAgentSettingsProps) {
  return (
    <div className="space-y-6">
      <Button onClick={onBack} className="mb-4">
        <ArrowLeft className="mr-2" size={16} /> Back to Groups
      </Button>
      <h2 className="text-2xl font-bold mb-4">{group.name} Settings</h2>
      <GroupAgentStatsCard group={group} />
      <GroupChatSettingsCard />
      <GroupAgentSettingsCard />
    </div>
  )
}

function GroupAgentStatsCard({ group }: { group: Group }) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Group Agent Stats</CardTitle>
      </CardHeader>
      <CardContent>
        <p className="mb-2">Number of members: {group.membersCount}</p>
        <div className="flex justify-between mb-4">
          <div className="flex space-x-4">
            <StatsIcon Icon={MessageCircle} count={100} />
            <StatsIcon Icon={Mic} count={50} />
            <StatsIcon Icon={Video} count={30} />
            <StatsIcon Icon={Link} count={20} />
            <StatsIcon Icon={File} count={10} />
            <StatsIcon Icon={Image} count={40} />
          </div>
        </div>
        <div className="flex justify-between items-center mb-4">
          <div>Chat credits balance: {group.credits}</div>
          <Button>Buy more chat credits</Button>
        </div>
        <p className="text-sm text-gray-500">
          Note: Group chat credits are a separate balance from the main balance.
        </p>
      </CardContent>
    </Card>
  )
}

function StatsIcon({ Icon, count, isSent = false }: StatsIconProps) {
  return (
    <div className="flex items-center">
      <Icon className="mr-1" size={16} />
      <span className="text-sm">{count}</span>
      {isSent && <Send className="ml-1" size={12} />}
    </div>
  )
}

function GroupChatSettingsCard() {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Chat Settings</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div>
          <h3 className="font-semibold mb-2">Text Messages</h3>
          <div className="space-y-2">
            <label className="block">Chat Trigger:</label>
            <Select>
              <SelectTrigger>
                <SelectValue placeholder="Select option" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="mentions-replies">Mentions and Replies</SelectItem>
                <SelectItem value="mentions-replies-admin">Mentions and Replies + All Admin User Messages</SelectItem>
                <SelectItem value="all-messages">All Messages</SelectItem>
                <SelectItem value="none">None</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-2 mt-2">
            <label className="block">Agent Replies With:</label>
            <Select>
              <SelectTrigger>
                <SelectValue placeholder="Select option" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="text">Text</SelectItem>
                <SelectItem value="voice-text">Voice and Text</SelectItem>
                <SelectItem value="circle-text">Circle and Text</SelectItem>
                <SelectItem value="none">None</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <div>
          <h3 className="font-semibold mb-2">Voice Memos</h3>
          <div className="space-y-2">
            <label className="block">Chat Trigger:</label>
            <Select>
              <SelectTrigger>
                <SelectValue placeholder="Select option" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="admin-messages">All Admin User Messages</SelectItem>
                <SelectItem value="all-messages">All Messages</SelectItem>
                <SelectItem value="none">None</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-2 mt-2">
            <label className="block">Agent Replies With:</label>
            <Select>
              <SelectTrigger>
                <SelectValue placeholder="Select option" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="voice-text">Voice and Text</SelectItem>
                <SelectItem value="video-circle-text">Video Circle and Text</SelectItem>
                <SelectItem value="only-video-circle">Only Video Circle</SelectItem>
                <SelectItem value="only-voice">Only Voice</SelectItem>
                <SelectItem value="only-text">Only Text</SelectItem>
                <SelectItem value="none">None</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <div>
          <h3 className="font-semibold mb-2">Circle Videos</h3>
          <div className="space-y-2">
            <label className="block">Chat Trigger:</label>
            <Select>
              <SelectTrigger>
                <SelectValue placeholder="Select option" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="admin-messages">All Admin User Messages</SelectItem>
                <SelectItem value="all-messages">All Messages</SelectItem>
                <SelectItem value="none">None</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-2 mt-2">
            <label className="block">Agent Replies With:</label>
            <Select>
              <SelectTrigger>
                <SelectValue placeholder="Select option" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="voice-text">Voice and Text</SelectItem>
                <SelectItem value="video-circle-text">Video Circle and Text</SelectItem>
                <SelectItem value="only-video-circle">Only Video Circle</SelectItem>
                <SelectItem value="only-voice">Only Voice</SelectItem>
                <SelectItem value="only-text">Only Text</SelectItem>
                <SelectItem value="none">None</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <Button>Save Changes</Button>
        </div>
      </CardContent>
    </Card>
  )
}

function GroupAgentSettingsCard() {
  const [mainGoal, setMainGoal] = useState('general')
  const [temperature, setTemperature] = useState(0.5)

  return (
    <Card>
      <CardHeader>
        <CardTitle>Agent Settings</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div>
          <label className="block mb-2">Language:</label>
          <Select>
            <SelectTrigger>
              <SelectValue placeholder="Select language" />
            </SelectTrigger>
            <SelectContent>
              {languages.map((lang) => (
                <SelectItem key={lang} value={lang.toLowerCase()}>
                  {lang}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div>
          <label className="block mb-2">Main goal:</label>
          <Select value={mainGoal} onValueChange={setMainGoal}>
            <SelectTrigger>
              <SelectValue placeholder="Select main goal" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="general">General Chat</SelectItem>
              <SelectItem value="finance">Finance</SelectItem>
              <SelectItem value="nutrition">Nutrition and Fitness</SelectItem>
              <SelectItem value="custom">Custom</SelectItem>
            </SelectContent>
          </Select>
        </div>
        {mainGoal === 'custom' && (
          <Textarea placeholder="Enter custom prompt" />
        )}
        <div>
          <label className="block mb-2">Temperature: {temperature.toFixed(1)}</label>
          <Slider
            min={0}
            max={1}
            step={0.1}
            value={[temperature]}
            onValueChange={([value]) => setTemperature(value)}
          />
          <p className="text-sm text-gray-500 mt-2">
            Controls how creative vs focused the AI responses will be. Higher values (closer to 1.0) result in more varied and creative responses, while lower values (closer to 0.0) make responses more focused and deterministic.
          </p>
        </div>
        <div>
          <label className="block mb-2">Knowledge base:</label>
          <p className="text-sm text-gray-500">Coming Soon</p>
        </div>
      </CardContent>
    </Card>
  )
}

const languages = [
  "Afrikaans", "Arabic", "Armenian", "Azerbaijani", "Belarusian", "Bosnian", "Bulgarian", "Catalan", "Chinese", "Croatian", "Czech", "Danish", "Dutch", "English", "Estonian", "Finnish", "French", "Galician", "German", "Greek", "Hebrew", "Hindi", "Hungarian", "Icelandic", "Indonesian", "Italian", "Japanese", "Kannada", "Kazakh", "Korean", "Latvian", "Lithuanian", "Macedonian", "Malay", "Marathi", "Maori", "Nepali", "Norwegian", "Persian", "Polish", "Portuguese", "Romanian", "Russian", "Serbian", "Slovak", "Slovenian", "Spanish", "Swahili", "Swedish", "Tagalog", "Tamil", "Thai", "Turkish", "Ukrainian", "Urdu", "Vietnamese", "Welsh"
]

