import { isTMA } from "@telegram-apps/sdk";
import { useEffect, useState } from "react";
import JunoAuthProvider from "./JunoAuthProvider";
import TelegramAuthProvider from "./TelegramAuthProvider";

export default function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isTelegramMiniApp, setIsTelegramMiniApp] = useState<boolean | null>(
    null
  );

  useEffect(() => {
    async function detectAndSetIsTelegramMiniApp() {
      const _isTMA = await isTMA();
      setIsTelegramMiniApp(_isTMA);
    }
    detectAndSetIsTelegramMiniApp();
  }, []);

  if (isTelegramMiniApp === null) {
    return;
  }

  // Fix auth problems
  if (isTelegramMiniApp === null) {
    return null;
  }

  if (isTelegramMiniApp) {
    return <TelegramAuthProvider>{children}</TelegramAuthProvider>;
  } else {
    return <JunoAuthProvider>{children}</JunoAuthProvider>;
  }
}
