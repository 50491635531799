import { getProfile, updateProfile } from "@/api/supabase/profiles";
import { useToast } from "@/components/ui/use-toast";
import { Profile } from "@/types/entities";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export function useProfileQuery() {
  return useQuery({
    queryKey: ["profile"],
    queryFn: getProfile,
  });
}

export function useProfileMutation() {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation({
    mutationFn: (data: Profile) => updateProfile(data),
    onSuccess: (data) => {
      toast({
        title: "Profile successfully updated 🎉",
      });
      queryClient.setQueryData(["profile"], data);
    },
    onError: () => {
      toast({
        title: "Error updating profile 😢. Try again",
      });
    },
  });
}
