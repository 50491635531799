import React, { useState } from "react";
import { Input } from "@/components/ui/input";
import { DatePickerDemo } from "@/components/ui/date-picker";
import { CaretLeft, ArrowClockwise } from "@phosphor-icons/react";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Transaction } from "@/types/entities";
import DeleteDialog from "@/components/ui/DeleteDialog";
import CategorySelector from "@/components/CategorySelector";
import { Label } from "@radix-ui/react-dropdown-menu";
import { FormLabel } from "@/components/ui/form";

interface TransactionFormProps {
  mode: "new" | "edit";
  type: "income" | "expense";
  transaction?: Transaction;

  selectedDate?: Date;
  onTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCategoryChange: (value: string) => void;
  onRecurrenceChange: (value: string) => void;
  onDateChange: (date: Date) => void;
  handleCategoryAdded: (newCategory: { name: string }) => void;
  handleSaveAndNext: () => void;
  handleCancel?: () => void;
  handlePrev?: () => void;
  handleDelete: () => void;
  amount: string;
  title: string;
  category: string;
  recurrence: string;
  isSaving: boolean;
  isInDrawer: boolean;
}

const TransactionForm: React.FC<TransactionFormProps> = ({
  mode,
  type,
  selectedDate,
  onTitleChange,
  onCategoryChange,
  onRecurrenceChange,
  onDateChange,
  handleCategoryAdded,
  handleSaveAndNext,
  handleCancel,
  handlePrev,
  handleDelete,
  title,
  category,
  recurrence,
  isSaving,
  isInDrawer,
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  return (
    <div className="flex flex-col items-start mx-auto">
      <div className="w-full">
        <Label className="text-sm mb-1">
          Title <span className="text-red-500">*</span>
        </Label>
        <Input
          placeholder={`Enter ${type === "income" ? "Income" : "Expense"} title (required)`}
          className="mb-4 w-full bg-background text-foreground dark:bg-background dark:text-foreground"
          value={title}
          onChange={onTitleChange}
          id="title"
        />
      </div>
      <div className="w-full">
        <Label className="text-sm mb-1">
          Category <span className="text-red-500">*</span>
        </Label>
        <CategorySelector
          type={type}
          value={category}
          onCategoryChange={onCategoryChange}
          handleCategoryAdded={handleCategoryAdded}
        />
      </div>
      <div className="flex w-full space-x-2 mb-8">
        <div className="w-1/2">
          <Label className="text-sm mb-1">
            Date <span className="text-red-500">*</span>
          </Label>
          <DatePickerDemo
            selectedDate={selectedDate}
            onDateChange={onDateChange}
          />
        </div>
        <div className="w-1/2">
          <Label className="text-sm mb-1">
            Recurrence <span className="text-red-500">*</span>
          </Label>
          <Select
            value={recurrence}
            defaultValue="once"
            onValueChange={onRecurrenceChange}
            required
          >
            <SelectTrigger className="bg-background text-foreground dark:bg-background dark:text-foreground">
              <SelectValue placeholder="Recurrence" />
            </SelectTrigger>
            <SelectContent
              ref={(ref) =>
                ref?.addEventListener("touchend", (e) => e.preventDefault())
              }
              className="bg-background text-foreground dark:bg-background dark:text-foreground"
            >
              <SelectItem value="once">Once</SelectItem>
              <SelectItem value="daily">Daily</SelectItem>
              <SelectItem value="weekly">Weekly</SelectItem>
              <SelectItem value="monthly">Monthly</SelectItem>
              <SelectItem value="yearly">Yearly</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className="w-full">
        {isInDrawer ? (
          <div className="flex-col w-full">
            <Button
              type="submit"
              className="w-full"
              onClick={handleSaveAndNext}
              disabled={isSaving}
            >
              {isSaving ? (
                <>
                  <ArrowClockwise className="mr-2 h-4 w-4 animate-spin" />
                  Saving...
                </>
              ) : (
                <>{mode === "new" ? `Add ${type}` : `Update ${type}`}</>
              )}
            </Button>
            {type === "expense" ? (
              <>
                <div className="flex items-center my-2">
                  <div className="border-t border-gray-400 flex-grow"></div>
                  <span className="mx-4 text-gray-400">or</span>
                  <div className="border-t border-gray-400 flex-grow"></div>
                </div>
                <Button
                  className="w-full"
                  variant="secondary"
                  onClick={handleCancel}
                >
                  Upload an image
                </Button>
              </>
            ) : (
              <div className="h-12" />
            )}
          </div>
        ) : (
          <div className="flex flex-col">
            {mode === "edit" && (
              <>
                <DeleteDialog
                  open={deleteDialogOpen}
                  onOpenChange={setDeleteDialogOpen}
                  onDelete={handleDelete}
                  isDeleting={isSaving}
                />
              </>
            )}
            <Button
              type="submit"
              className="w-full"
              onClick={handleSaveAndNext}
              disabled={isSaving}
            >
              {isSaving ? (
                <>
                  <ArrowClockwise className="mr-2 h-4 w-4 animate-spin" />
                  Saving...
                </>
              ) : (
                <>{mode === "new" ? `Proceed` : `Update ${type}`}</>
              )}
            </Button>
            {mode === "edit" && (
              <Button
                className="w-full mt-2"
                onClick={() => setDeleteDialogOpen(true)}
                variant="destructive"
              >
                Delete
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TransactionForm;
