/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { fetchProfileStats, profileStatsState } from "@/atoms/profile_stats";
import { useNavigate } from "react-router-dom";
import { useStoreReceipts } from "@/hooks/useStoreReceipts";
import PageWrapper from "@/components/layout/PageWrapper";
import currencySymbolMap from "currency-symbol-map";
import { Receipt } from "@/types/entities";
import {
  Select,
  SelectItem,
  SelectTrigger,
  SelectContent,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import CountUp from "react-countup";
import { useQuery } from "@tanstack/react-query";
import { useProfileQuery } from "@/queries/profiles";
import { getStats } from "@/api/supabase/profiles";

const StoreList = () => {
  const { stores } = useStoreReceipts();
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState("all");
  const [selectedStore, setSelectedStore] = useState("all");
  // console.log("total items here", profileStats?.total_items);
  const [isLoading, setIsLoading] = useState(true);
  const MAX_STORE_NAME_LENGTH = 20;

  const { data: profile } = useProfileQuery();

  const { data: profileStats, isPending: profileStatsLoading } = useQuery({
    queryKey: ["profileStats"],
    queryFn: () => getStats(),
    enabled: !!profile,
  });

  const [view, setView] = useState("dashboard");
  const [portfolioData, setPortfolioData] = useState({
    totalPurchases: profileStats?.total_items || 0,
    browserActivity: "Coming Soon",
    socialMediaActivity: "Coming Soon",
    appActivity: "Coming Soon",
    potentialPrice: "Coming Soon",
    currentPrice: "Coming Soon",
  });

  const handleStoreClick = (address: string) => {
    const encodedAddress = encodeURIComponent(address);
    navigate(`/store/${encodedAddress}`);
  };

  const getTotalAmount = (receipts: Receipt[]): string => {
    const total = receipts.reduce(
      (total: number, receipt: Receipt) => total + Number(receipt.total_price),
      0
    );
    return parseFloat(total.toFixed(2)).toString();
  };

  const handleDateChange = (value: string) => {
    setSelectedDate(value);
  };

  const handleStoreChange = (value: string) => {
    setSelectedStore(value);
  };

  const validStores = Object.entries(stores).filter(([address, receipts]) => {
    return (
      address &&
      address.trim() &&
      address !== "undefined" &&
      receipts &&
      receipts.length > 0
    );
  });

  // const filteredStores = validStores.filter(([address, receipts]) => {
  //   const matchedDate = selectedDate !== "all" ? receipts.some(receipt => receipt.date === selectedDate) : true;
  //   const matchedStore = selectedStore !== "all" ? receipts.some(receipt => receipt.store_name === selectedStore) : true;
  //   return matchedDate && matchedStore;
  // });

  const renderDashboard = () => (
    <div className="space-y-4">
      <Card className="bg-gray-800 text-white">
        <CardHeader>
          <CardTitle className="text-xl">Data Portfolio Overview</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm text-gray-400">Total Purchases</p>
              <p className="text-2xl font-bold">
                <CountUp end={portfolioData.totalPurchases} duration={2} />
              </p>
            </div>
            <div>
              <p className="text-sm text-gray-400">Browser Activity</p>
              <p className="text-2xl font-bold">
                {portfolioData.browserActivity}
              </p>
            </div>
            <div>
              <p className="text-sm text-gray-400">Social Media Activity</p>
              <p className="text-2xl font-bold">
                {portfolioData.socialMediaActivity}
              </p>
            </div>
            <div>
              <p className="text-sm text-gray-400">App Activity</p>
              <p className="text-2xl font-bold">{portfolioData.appActivity}</p>
            </div>
          </div>
        </CardContent>
      </Card>
      <Card className="bg-gray-800 text-white">
        <CardHeader>
          <CardTitle className="text-xl">Potential Portfolio Price</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-4xl font-bold">
            $ {(portfolioData.totalPurchases * 0.02).toFixed(2)}
          </p>
          <p className="text-sm text-gray-400 mt-2">
            (Total Purchases * $0.02 per item)
          </p>
        </CardContent>
      </Card>
    </div>
  );

  return (
    <PageWrapper>
      <div className="w-[90%] mx-auto mb-4">
        {view === "dashboard" && (
          <>
            {renderDashboard()}
            {/* <div className="mt-8"> //* hide the "Your Stores" section from being rendered
              <h2 className="text-2xl font-bold mb-4">Your Stores</h2>
              <div className="flex justify-between items-center mb-4 space-x-4">
                <Select onValueChange={handleDateChange}>
                  <SelectTrigger className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
                    <SelectValue placeholder="All Dates" />
                  </SelectTrigger>
                  <SelectContent ref={(ref) =>
                    ref?.addEventListener("touchend", (e) => e.preventDefault())
                  }>
                    <SelectItem value="all">All Dates</SelectItem>
                    {Array.from(new Set(validStores.flatMap(([_, receipts]) => receipts.map(receipt => receipt.date))))
                      .sort((a, b) => new Date(a).getTime() - new Date(b).getTime()) // Sort dates from oldest to newest
                      .filter((d) => !!d)
                      .map((date) => (
                        <SelectItem key={date} value={date}>
                          {date}
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
                <Select onValueChange={handleStoreChange}>
                  <SelectTrigger className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
                    <SelectValue placeholder="All Stores" />
                  </SelectTrigger>
                  <SelectContent className="flex flex-col" ref={(ref) =>
                    ref?.addEventListener("touchend", (e) => e.preventDefault())
                  }>
                    <SelectItem value="all">All Stores</SelectItem>
                    {Array.from(new Set(validStores.flatMap(([_, receipts]) => receipts.map(receipt => receipt.store_name))))
                      .sort() // Sort store names in ascending order
                      .filter((store) => !!store)
                      .map((store) => (
                        <SelectItem key={store} value={store}>
                          <div className="tooltip">
                            {store.length > MAX_STORE_NAME_LENGTH
                              ? `${store.slice(0, MAX_STORE_NAME_LENGTH)}...`
                              : store}
                            <span className="tooltiptext">{store}</span>
                          </div>
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-10">
                {filteredStores.length > 0 ? (
                  filteredStores.map(([address, receipts]) => (
                    <div
                      key={address}
                      className="shadow rounded-lg cursor-pointer px-4 py-2 border hover:shadow-lg transition duration-300 ease-in-out bg-slate-900"
                      onClick={() => handleStoreClick(address)}
                    >
                      <div className="flex justify-between items-center">
                        <div className="font-semibold text-sm">
                          {receipts[0].store_name}
                        </div>
                        <div className="flex-shrink-0 whitespace-nowrap">
                          {currencySymbolMap(receipts[0].currency)}{" "}
                          {getTotalAmount(receipts as Receipt[])}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="flex justify-center text-center">No stores found</div>
                )}
              </div>
            </div> */}
          </>
        )}
      </div>
    </PageWrapper>
  );
};

export default StoreList;
