import { atom } from "recoil";
import { getAPI } from "@/api";

export const assetsState = atom<string[]>({
  key: "assetsState",
  default: [],
});

const API = getAPI();

/**
 * Create a new asset and update the Recoil state.
 * @param {File} asset - The file to upload.
 * @param {Function} setAsset - Function to update the receipt state.
 * @param {string} [docKey] - Optional document key.
 * @return {Promise<{ imageUrl: string, success: boolean, error: boolean }>} The upload result.
 */
export async function createAsset(
  asset: File,
  setAsset: (callback: (assets: string[]) => string[]) => void,
  docKey?: string
): Promise<{
  imageUrl?: string;
  success: boolean;
  error: boolean;
  message: string;
}> {
  try {
    const _asset = await API.receipt.createAsset(asset, docKey);
    setAsset((prevAssets) => [...prevAssets, _asset.message]);

    console.log("asset", _asset.message);
    return {
      success: true,
      error: false,
      imageUrl: _asset.message,
      message: "Asset uploaded successfully",
    };
  } catch (error) {
    console.error(`Error uploading asset with key ${docKey}:`, error);
    return {
      success: false,
      error: true,
      message: "Failed to upload asset",
    };
  }
}
