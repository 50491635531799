import React, { useState } from "react";
import { Transaction } from "@/types/entities";
import { X } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";

type EditReceiptModalProps = {
  transaction: Transaction;
  onClose: () => void;
  onSaveAndVerify: (transaction: Transaction) => void;
  onDelete: (receiptId: string) => Promise<void>;
};

const EditReceiptModal: React.FC<EditReceiptModalProps> = ({
  transaction,
  onClose,
  onSaveAndVerify,
  onDelete,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleDelete = async () => {
    setIsDeleting(true);
    setError(null);
    setSuccess(null);
    try {
      await onDelete(transaction.receipt_id ?? '');
      setSuccess("Receipt deleted successfully.");
      onClose();
    } catch (err) {
      setError("Failed to delete receipt. Please try again.");
    } finally {
      setIsDeleting(false);
    }
  };

  const handleEditReceipt = () => {
    if (transaction.receipt_id) {
      navigate(`/receipt/${transaction.receipt_id}`);
    }
  };

  return (
    <Dialog open={true} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="text-lg font-bold">
            {transaction.name}
          </DialogTitle>
          <Button
            variant="ghost"
            size="icon"
            className="absolute right-4 top-4"
            onClick={onClose}
          >
            <X className="h-4 w-4" />
          </Button>
        </DialogHeader>
        
        <div className="space-y-4">
          <p className="text-muted-foreground">
            ${(transaction.amount_cents / 100).toFixed(2)}
          </p>

          {error && (
            <p className="text-destructive text-sm">{error}</p>
          )}
          {success && (
            <p className="text-green-600 dark:text-green-400 text-sm">{success}</p>
          )}

          <div className="flex flex-col space-y-2">
            <Button
              variant="default"
              onClick={() => onSaveAndVerify(transaction)}
            >
              Save and Verify
            </Button>
            
            <Button
              variant="secondary"
              onClick={handleEditReceipt}
            >
              Edit Receipt
            </Button>
            
            <Button
              variant="destructive"
              onClick={handleDelete}
              disabled={isDeleting}
            >
              {isDeleting ? "Deleting..." : "Delete Receipt"}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditReceiptModal;