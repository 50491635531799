import { getAPI } from "@/api";
import { authState } from "@/atoms/auth";
import { Check, Clipboard } from "@phosphor-icons/react";
import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";

interface CopyToClipboardProps {}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({}) => {
  const [user] = useRecoilState(authState);

  const [copied, setCopied] = useState(false);
  const [copiedEnv, setCopiedEnv] = useState(false);
  const [evnAddress, setEvnAddress] = useState<string | undefined>(undefined);

  const API = getAPI();
  useEffect(() => {
    const fetchEvnAddress = async () => {
      if (!user || !user?.key) {
        return;
      }
      try {
        const address = await API.mintNFT.getEvnAddress(user?.key);
        setEvnAddress(address);
      } catch (error) {
        console.error("Error fetching EVM address:", error);
      }
    };

    fetchEvnAddress();
  }, [API.mintNFT, user]);

  const handleCopyPrincipalID = (text: string) => {
    if (text) {
      navigator.clipboard.writeText(text).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      });
    }
  };

  const handleCopyEvnAddress = (text: string) => {
    if (text) {
      navigator.clipboard.writeText(text).then(() => {
        setCopiedEnv(true);
        setTimeout(() => setCopiedEnv(false), 2000);
      });
    }
  };

  const formatId = (id: string) => {
    if (id.length > 10) {
      return `${id.slice(0, 10)}...${id.slice(-5)}`;
    }
    return id;
  };

  if (!user || !user.key) {
    return null;
  }

  return (
    <div className="flex flex-col items-center">
      <div className="text-center">
        <p className="text-sm">
          Your Bridge23 ID: {formatId(user.key)}
          {copied ? (
            <Check className="text-green-500 h-4 w-4 inline cursor-pointer ml-1" />
          ) : (
            <Clipboard
              className="h-4 w-4 inline cursor-pointer ml-1"
              onClick={() => handleCopyPrincipalID(user.key)}
            />
          )}
        </p>
      </div>
    </div>
  );
};

export default CopyToClipboard;
