import { retrieveLaunchParams } from "@telegram-apps/sdk";

export const URL_BASE = import.meta.env.VITE_API_URL_BASE;

export function _getHeaders() {
  const { initDataRaw } = retrieveLaunchParams();

  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `TMA ${btoa(initDataRaw!)}`,
    "X-Bridge23-Env": import.meta.env.PROD ? "production" : "local",
  };
}
