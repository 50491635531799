import React, { useState } from "react";
import { Button } from "@/components/ui/button";
//import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import FileUpload from "./FileUpload";
import PageWrapper from "../layout/PageWrapper";
//import AddTransactionForm from "../forms/TransactionForm";
//import { useNavigate } from "react-router-dom";

const CreateTransaction: React.FC = () => {
  //const navigate = useNavigate();

  return (
    <PageWrapper>
      <div className="flex flex-col max-w-md mx-auto w-[90%] h-full">
        <div className="flex flex-col flex-grow">
          <h2 className="text-2xl font-semibold mb-4 text-center">Upload Receipt</h2>
          
          <div className="flex items-center my-4">
            <div className="border-t border-gray-400 flex-grow"></div>
            <div className="border-t border-gray-400 flex-grow"></div>
          </div>
          <FileUpload />
          {/* <div className="flex items-center my-4">
            <div className="border-t border-gray-400 flex-grow"></div>
            <span className="mx-4 text-gray-400">or</span>
            <div className="border-t border-gray-400 flex-grow"></div>
          </div> */}
          {/* <Button
            variant="secondary"
            onClick={() => navigate('/add-expense-manually')}
            className="w-full mt-2"
          >
            Add Expense manually
          </Button> */}
        </div>
        <div className="mt-auto w-full">
          <Button
            variant="outline"
            className="w-full mx-auto justify-center flex mt-2"
            onClick={() => window.history.back()}
          >
            Cancel
          </Button>
        </div>
      </div>
    </PageWrapper>
  );
};

export default CreateTransaction;
