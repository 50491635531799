import React, { useEffect, useMemo, useState } from "react";
import PageWrapper from "@/components/layout/PageWrapper";
import TransactionsList from "@/components/transactions_list/TransactionsList";
import { useTransactions } from "@/hooks/useTransactions";
import { useTelegramReceipts } from "@/hooks/useTelegramReceipts";
import { useRecoilValue } from "recoil";
import { useLoadCategories } from "@/hooks/useLoadCategories";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { currenciesState } from "@/atoms/exchangeRate";
import { useExchangeRateToUSD } from "@/hooks/useExchangeRateToUSD";
import TransactionsTotalHeader from "@/components/transactions_list/TransactionsTotalHeader";
import TelegramReceiptsWarning from "@/components/transactions_list/TelegramReceiptsWarning";
import { useProfileQuery } from "@/queries/profiles";

const Dashboard: React.FC = () => {
  dayjs.extend(isBetween);
  useLoadCategories();

  const transactions = useTransactions();
  // const telegramReceipts = useTelegramReceipts();
  const currencies = useRecoilValue(currenciesState);
  const { data: profile } = useProfileQuery();
  const [selectedPeriod, setSelectedPeriod] = useState("all");
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [selectedType, setSelectedType] = useState<string>("income");
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedCurrency, setSelectedCurrency] = useState<string>(
    profile?.currency?.toUpperCase() || "USD"
  );
  const getExchangeRateToUSD = useExchangeRateToUSD(currencies || []);

  const categories = useMemo(() => {
    const uniqueCategories = Array.from(
      new Set(transactions.map((transaction) => transaction.category))
    ).map((category) => ({ name: category }));
    return uniqueCategories;
  }, [transactions]);

  useEffect(() => {
    if (categories.length > 0 && !selectedCategory) {
      setSelectedCategory(categories[0].name);
    }
  }, [categories, selectedCategory]);

  const filteredTransactions = useMemo(() => {
    const transactionsFilteredByPeriod = transactions.filter((transaction) => {
      if (selectedPeriod === "all") return true;
      const transactionDate = dayjs(transaction.date);
      if (selectedPeriod === "monthly")
        return transactionDate.isSame(dayjs(), "month");
      if (selectedPeriod === "weekly")
        return transactionDate.isSame(dayjs(), "week");
      if (selectedPeriod === "today")
        return transactionDate.isSame(dayjs(), "day");
      return true;
    });

    let filtered = transactionsFilteredByPeriod;

    if (selectedFilter === "by-type") {
      filtered = filtered.filter((transaction) => {
        if (selectedType === "all") return true;
        return transaction.transaction_type === selectedType;
      });
    }
    if (selectedFilter === "by-day") {
      filtered = filtered.filter((transaction) =>
        dayjs(transaction.date).isSame(selectedDate, "day")
      );
    }
    if (selectedFilter === "by-week") {
      const startOfWeek = dayjs(selectedDate).startOf("week");
      const endOfWeek = dayjs(selectedDate).endOf("week");
      filtered = filtered.filter((transaction) =>
        dayjs(transaction.date).isBetween(startOfWeek, endOfWeek, null, "[]")
      );
    }
    if (selectedFilter === "by-month") {
      filtered = filtered.filter((transaction) =>
        dayjs(transaction.date).isSame(selectedDate, "month")
      );
    }
    if (selectedFilter === "by-category" && selectedCategory) {
      filtered = filtered.filter(
        (transaction) => transaction.category === selectedCategory
      );
    }
    if (selectedFilter === "by-currency" && selectedCurrency) {
      filtered = filtered.filter(
        (transaction) => transaction.amount_currency === selectedCurrency
      );
    }
    return filtered;
  }, [
    transactions,
    selectedPeriod,
    selectedFilter,
    selectedType,
    selectedDate,
    selectedCategory,
    selectedCurrency,
  ]);

  const filteredTotalIncome = useMemo(() => {
    return filteredTransactions
      .filter((transaction) => transaction.transaction_type === "income")
      .reduce((sum, transaction) => {
        const exchangeRate = getExchangeRateToUSD(
          transaction.amount_currency?.toUpperCase() || "USD"
        );
        return sum + Math.abs((transaction.amount_cents / 100) * exchangeRate);
      }, 0);
  }, [filteredTransactions, getExchangeRateToUSD]);

  const filteredTotalExpense = useMemo(() => {
    return filteredTransactions
      .filter((transaction) => transaction.transaction_type === "expense")
      .reduce((sum, transaction) => {
        const exchangeRate = getExchangeRateToUSD(
          transaction.amount_currency?.toUpperCase() || "USD"
        );
        return sum + Math.abs((transaction.amount_cents / 100) * exchangeRate);
      }, 0);
  }, [filteredTransactions, getExchangeRateToUSD]);

  // Calculate receipts to verify based on the principal ID
  const principalId = profile?.id;

  // Create a set of verified receipt IDs from the transactions
  const verifiedReceiptIds = useMemo(() => {
    return new Set(transactions.map((transaction) => transaction.receipt_id));
  }, [transactions]);

  // // Filter telegramReceipts to find unverified receipts
  // const receiptsToVerify = useMemo(() => {
  //   return telegramReceipts.filter(
  //     (receipt) => receipt.profile_id === principalId && !verifiedReceiptIds.has(receipt.receipt_id)
  //   ).length;
  // }, [telegramReceipts, principalId, verifiedReceiptIds]);

  return (
    <PageWrapper>
      {/* {receiptsToVerify > 0 && (
        <TelegramReceiptsWarning message={`You have ${receiptsToVerify} receipts to verify`} />
      )} */}
      <div className="flex flex-col items-start justify-center">
        <div className="flex flex-col items-center w-full max-w-3xl px-4 md:px-10 mx-auto">
          <TransactionsTotalHeader
            title={selectedFilter === "all" ? "Total" : ""}
            currency={profile?.currency || "USD"}
            // categories={categories}
            totalIncome={filteredTotalIncome}
            totalExpense={filteredTotalExpense}
            currencies={currencies}
            selectedPeriod={selectedPeriod}
            onPeriodChange={setSelectedPeriod}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            selectedCurrency={selectedCurrency}
            setSelectedCurrency={setSelectedCurrency}
            showTotals={selectedFilter !== "by-type"}
            type=""
            value={selectedCategory}
            onCategoryChange={setSelectedCategory}
          />
          <TransactionsList
            transactions={filteredTransactions}
            selectedCurrency={selectedCurrency}
            getExchangeRateToUSD={getExchangeRateToUSD}
          />
        </div>
      </div>
    </PageWrapper>
  );
};

export default Dashboard;
