// src/components/transactions_list/TransactionsTotalHeader.tsx
import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from "@/components/ui/select";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Badge } from "@/components/ui/badge";
import { Funnel as Filter } from "@phosphor-icons/react";
import { Calendar, Coin, Cube, ArrowsVertical, ArrowDown, Folder } from "@phosphor-icons/react";
import { ArrowCircleLeft, ArrowCircleRight } from "@phosphor-icons/react";
import { formatCurrency } from "@/lib/utils";
import {
  defaultExpenseCategories,
  defaultIncomeCategories,
} from "@/components/data/categories";
import { categoriesState, fetchAllCategories } from "@/atoms/category";
import { Currency } from "@/types/entities";
import dayjs from "dayjs";

type TransactionsTotalProps = {
  title: string;
  currency: string;
  totalIncome: number;
  totalExpense: number;
  currencies?: Currency[][] | undefined;
  selectedPeriod?: string;
  onPeriodChange?: (period: string) => void;
  // transactions: Transaction[];
  selectedFilter?: string;
  setSelectedFilter?: (filter: string) => void;
  selectedType?: string;
  setSelectedType?: (type: string) => void;
  selectedCategory?: string;
  setSelectedCategory?: (type: string) => void;
  selectedCurrency: string;
  setSelectedCurrency: (type: string) => void;
  showTotals?: boolean;
  selectedDate?: string;
  setSelectedDate?: (date: string) => void;
  type: string;
  value: string;
  onCategoryChange: (value: string) => void;
};

type Category = {
  type: string;
  key: string;
  name: string;
  icon: JSX.Element;
};

const dropdownOptions = [
  {
    value: "all",
    label: "All Entries",
    icon: <ArrowsVertical className="mr-2 h-4 w-4" />,
  },
  {
    value: "by-type",
    label: "By Type",
    icon: <ArrowDown className="mr-2 h-4 w-4" />,
  },
  {
    value: "by-day",
    label: "By Day",
    icon: <Calendar className="mr-2 h-4 w-4" />,
  },
  {
    value: "by-week",
    label: "By Week",
    icon: <Calendar className="mr-2 h-4 w-4" />,
  },
  {
    value: "by-month",
    label: "By Month",
    icon: <Calendar className="mr-2 h-4 w-4" />,
  },
  {
    value: "by-currency",
    label: "By Currency",
    icon: <Coin className="mr-2 h-4 w-4" />,
  },
  {
    value: "by-category",
    label: "By Category",
    icon: <Folder className="mr-2 h-4 w-4" />,
  },
  {
    value: "recurring",
    label: "Recurring",
    icon: <Cube className="mr-2 h-4 w-4" />,
  },
];

export default function TransactionsTotal({
  type,
  title,
  totalIncome,
  totalExpense,
  // transactions,
  currencies,
  selectedPeriod,
  onPeriodChange,
  selectedFilter,
  setSelectedFilter,
  selectedType,
  setSelectedType,
  showTotals,
  selectedDate,
  setSelectedDate,
  selectedCategory,
  setSelectedCategory,
  selectedCurrency,
  setSelectedCurrency,
}: TransactionsTotalProps) {
  const [exchangeRate, setExchangeRate] = useState(1);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [categories, setCategories] = useRecoilState(categoriesState);

  useEffect(() => {
    const getExchangeRate = (currency: string): number => {
      console.log({ currencies });
      if (currency === "USD") return 1;
      const flattenedCurrencies = currencies?.flat();
      const currencyData = flattenedCurrencies?.find(
        (curr) => curr.name === currency
      );
      return currencyData ? currencyData.value : 1;
    };

    const rate = getExchangeRate(selectedCurrency);
    console.log(`Exchange rate for ${selectedCurrency}:`, rate);
    setExchangeRate(rate);
  }, [selectedCurrency, currencies]);

  const handleSelectTriggerClick = (
    event: React.MouseEvent | React.TouchEvent
  ) => {
    event.stopPropagation();
    setIsSelectOpen(true);
  };

  const handleSelectClose = () => {
    setIsSelectOpen(false);
  };

  // function to support date, week and month filters
  const handleDateChange = (direction: "previous" | "next") => {
    const currentDate = selectedDate || dayjs().format("YYYY-MM-DD");
    let newDate = currentDate;
    if (selectedFilter === "by-day") {
      newDate =
        direction === "previous"
          ? dayjs(currentDate).subtract(1, "day").format("YYYY-MM-DD")
          : dayjs(currentDate).add(1, "day").format("YYYY-MM-DD");
    } else if (selectedFilter === "by-week") {
      newDate =
        direction === "previous"
          ? dayjs(currentDate)
              .subtract(1, "week")
              .startOf("week")
              .format("YYYY-MM-DD")
          : dayjs(currentDate)
              .add(1, "week")
              .startOf("week")
              .format("YYYY-MM-DD");
    } else if (selectedFilter === "by-month") {
      newDate =
        direction === "previous"
          ? dayjs(currentDate)
              .subtract(1, "month")
              .startOf("month")
              .format("YYYY-MM-DD")
          : dayjs(currentDate)
              .add(1, "month")
              .startOf("month")
              .format("YYYY-MM-DD");
    }
    setSelectedDate?.(newDate);
  };

  const getFontSizeClass = (value: string) => {
    const length = value.length;
    if (length > 25) return "text-sm";
    if (length > 10) return "text-2xl";
    if (length > 7) return "text-3xl";
    return "text-4xl";
  };

  const isFilterSelected =
    selectedFilter !== "all" &&
    (selectedFilter === "by-type" ||
      selectedFilter === "by-day" ||
      selectedFilter === "by-week" ||
      selectedFilter === "by-month" ||
      selectedFilter === "by-currency" ||
      selectedFilter === "by-category" ||
      selectedFilter === "recurring");

  // handle for combine category with custom categories
  const combinedCategories: Category[] = [
    ...defaultExpenseCategories.map((cat, index) => ({
      ...cat,
      key: `default-expense-${cat.name}-${index}`,
      icon: cat.icon as unknown as JSX.Element,
    })),
    ...categories
      .filter((cat) => cat.type === "expense")
      .map((cat, index) => ({
        ...cat,
        key: `custom-expense-${cat.name}-${index}`,
        icon: cat.icon as unknown as JSX.Element,
      })),
    ...defaultIncomeCategories.map((cat, index) => ({
      ...cat,
      key: `default-income-${cat.name}-${index}`,
      icon: cat.icon as unknown as JSX.Element,
    })),
    ...categories
      .filter((cat) => cat.type === "income")
      .map((cat, index) => ({
        ...cat,
        key: `custom-income-${cat.name}-${index}`,
        icon: cat.icon as unknown as JSX.Element,
      })),
  ].reduce<Category[]>((acc, current) => {
    const x = acc.find((item) => item.name === current.name);
    if (!x) {
      return acc.concat(current);
    } else {
      return acc;
    }
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      const fetchedCategories = await fetchAllCategories();
      setCategories(fetchedCategories);
    };
    fetchCategories();
  }, [setCategories]);

  return (
    <div className="w-full rounded-lg bg-card p-4 border">
      <div className="text-card-foreground">
        <div className="text-center w-full relative">
          {isSelectOpen && (
            <div
              className="fixed inset-0 bg-transparent z-10"
              onClick={handleSelectClose}
            ></div>
          )}

          <div className="mt-4 flex items-center justify-center space-x-2 mx-auto w-fit relative z-20">
            <h2 className="text-xl font-semibold whitespace-nowrap">{title}</h2>

            {!isFilterSelected && (
              <>
                <Select
                  value={selectedPeriod}
                  onValueChange={onPeriodChange}
                  onOpenChange={handleSelectClose}
                >
                  <SelectTrigger
                    className="text-sm text-gray-400 h-auto p-1"
                    onClick={handleSelectTriggerClick}
                    onTouchStart={handleSelectTriggerClick}
                  >
                    <SelectValue placeholder="Monthly" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">All</SelectItem>
                    <SelectItem value="monthly">This Month</SelectItem>
                    <SelectItem value="weekly">This Week</SelectItem>
                    <SelectItem value="today">Today</SelectItem>
                  </SelectContent>
                </Select>

                {currencies && (
                  <Select
                    value={selectedCurrency}
                    onValueChange={(value) => {
                      setSelectedCurrency(value);
                    }}
                    onOpenChange={handleSelectClose}
                  >
                    <SelectTrigger
                      className="text-sm text-gray-400 h-auto p-1 px-2"
                      onClick={handleSelectTriggerClick}
                      onTouchStart={handleSelectTriggerClick}
                    >
                      <SelectValue placeholder="Select Currency" />
                    </SelectTrigger>
                    <SelectContent style={{ position: "absolute", zIndex: 30 }}>
                      {currencies.flat().map((curr) => (
                        <SelectItem key={curr.name} value={curr.name}>
                          {curr.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
              </>
            )}
          </div>

          {isFilterSelected && (
            <div className="relative">
              <div className="absolute top-[-2rem] left-1/2 transform -translate-x-1/2">
                <Badge
                  className="flex items-center space-x-2 cursor-pointer mt-2"
                  onClick={() => {
                    setSelectedFilter?.("all");
                  }}
                  variant="secondary"
                >
                  <span>
                    {
                      dropdownOptions.find(
                        (option) => option.value === selectedFilter
                      )?.label
                    }
                  </span>
                  <span>x</span>
                </Badge>
              </div>
              {selectedFilter === "by-type" && (
                <Tabs value={selectedType} onValueChange={setSelectedType}>
                  <TabsList className="justify-center mt-5">
                    <TabsTrigger value="income">Income</TabsTrigger>
                    <TabsTrigger value="expense">Expense</TabsTrigger>
                  </TabsList>
                </Tabs>
              )}
              {selectedFilter === "by-day" && (
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => handleDateChange("previous")}
                    aria-label="Previous Day"
                    className="p-2"
                    style={{ marginLeft: "-1rem" }}
                  >
                    <ArrowCircleLeft className="h-6 w-6 text-white mt-4 mb-4" />
                  </button>
                  <span className="text-xl font-semibold mt-4 mb-4">
                    {dayjs(selectedDate || dayjs().format("YYYY-MM-DD")).format(
                      "D MMM YYYY"
                    )}
                  </span>
                  <button
                    onClick={() => handleDateChange("next")}
                    aria-label="Next Day"
                    className="p-2"
                    style={{ marginRight: "-1rem" }}
                  >
                    <ArrowCircleRight className="h-6 w-6 text-white mt-4 mb-4" />
                  </button>
                </div>
              )}
              {selectedFilter === "by-week" && (
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => handleDateChange("previous")}
                    aria-label="Previous Week"
                    className="p-2"
                    style={{ marginLeft: "-1rem" }}
                  >
                    <ArrowCircleLeft className="h-6 w-6 text-white mt-4 mb-4" />
                  </button>
                  <span className="text-xl font-semibold mt-4 mb-4">
                    {dayjs(selectedDate).startOf("week").format("D MMM")} -{" "}
                    {dayjs(selectedDate).endOf("week").format("D MMM YYYY")}
                  </span>
                  <button
                    onClick={() => handleDateChange("next")}
                    aria-label="Next Week"
                    className="p-2"
                    style={{ marginRight: "-1rem" }}
                  >
                    <ArrowCircleRight className="h-6 w-6 text-white mt-4 mb-4" />
                  </button>
                </div>
              )}
              {selectedFilter === "by-month" && (
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => handleDateChange("previous")}
                    aria-label="Previous Month"
                    className="p-2"
                    style={{ marginLeft: "-1rem" }}
                  >
                    <ArrowCircleLeft className="h-6 w-6 text-white mt-4 mb-4" />
                  </button>
                  <span className="text-xl font-semibold mt-4 mb-4">
                    {dayjs(selectedDate).format("MMM YYYY")}
                  </span>
                  <button
                    onClick={() => handleDateChange("next")}
                    aria-label="Next Month"
                    className="p-2"
                    style={{ marginRight: "-1rem" }}
                  >
                    <ArrowCircleRight className="h-6 w-6 text-white mt-4 mb-4" />
                  </button>
                </div>
              )}
              {selectedFilter === "by-currency" && (
                <div className="flex justify-center w-[200px] mx-auto">
                  <Select
                    value={selectedCurrency}
                    onValueChange={(value) => {
                      setSelectedCurrency(value);
                    }}
                    onOpenChange={handleSelectClose}
                  >
                    <SelectTrigger
                      className="text-sm text-gray-400 h-auto p-1 mt-2 pl-3"
                      onClick={handleSelectTriggerClick}
                      onTouchStart={handleSelectTriggerClick}
                    >
                      <SelectValue placeholder="All" />
                    </SelectTrigger>
                    <SelectContent>
                      {currencies?.flat().map((curr) => (
                        <SelectItem key={curr.name} value={curr.name}>
                          {curr.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              )}
              {selectedFilter === "by-category" && (
                <div className="flex justify-center w-[200px] mx-auto">
                  <Select
                    value={selectedCategory}
                    onValueChange={setSelectedCategory}
                    onOpenChange={handleSelectClose}
                  >
                    <SelectTrigger className="w-full bg-background text-foreground dark:bg-background dark:text-foreground mt-2">
                      <SelectValue placeholder={`Select ${type} category`} />
                    </SelectTrigger>
                    <SelectContent className="max-h-80 bg-background text-foreground dark:bg-background dark:text-foreground">
                      {combinedCategories
                        .filter((cat) => cat.name)
                        .map((cat) => (
                          <SelectItem key={cat.key} value={cat.name}>
                            {cat.icon} {cat.name}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                </div>
              )}
            </div>
          )}

          {!isFilterSelected && (
            <div className="absolute top-[-1rem] right-[-1rem]">
              <DropdownMenu>
                <DropdownMenuTrigger className="p-2">
                  <Filter
                    size={32}
                    weight={isFilterSelected ? "bold" : undefined}
                  />
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  {dropdownOptions.map((option) => (
                    <DropdownMenuItem
                      key={option.value}
                      onSelect={() => setSelectedFilter?.(option.value)}
                    >
                      <div className="flex items-center space-x-2">
                        {option.icon}
                        <span>{option.label}</span>
                      </div>
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          )}

          {!isFilterSelected && showTotals && (
            <>
              <div className="flex items-center justify-center space-x-2 mt-2">
                <span
                  className={`font-bold ${getFontSizeClass(formatCurrency(totalIncome / exchangeRate - totalExpense / exchangeRate, selectedCurrency))}`}
                >
                  {formatCurrency(
                    totalIncome / exchangeRate - totalExpense / exchangeRate,
                    selectedCurrency
                  )}
                </span>
              </div>
              <div className="flex items-center justify-center space-x-2 mt-2">
                {totalIncome !== 0 && (
                  <span
                    className={`text-green-500 ${formatCurrency(totalIncome / exchangeRate, selectedCurrency)}`}
                  >
                    {formatCurrency(totalIncome / exchangeRate, selectedCurrency)}
                  </span>
                )}
                {totalExpense !== 0 && (
                  <span
                    className={`text-red-500 ${formatCurrency(totalExpense / exchangeRate, selectedCurrency)}`}
                  >
                    {formatCurrency(totalExpense / exchangeRate, selectedCurrency)}
                  </span>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="text-muted-foreground">
        {/* ... existing content ... */}
      </div>
    </div>
  );
}
