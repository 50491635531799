'use client'

import { useState } from 'react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { PersonalTab } from '@/components/agents/PersonalTab'
import { GroupsTab } from '@/components/agents/GroupsTab'

export default function Page() {
  const [activeTab, setActiveTab] = useState('personal')

  return (
    <div className="container mx-auto p-4 mb-8 mt-12">
      <h1 className="text-3xl font-bold mb-6">Agent Settings</h1>
      <Tabs value={activeTab} onValueChange={setActiveTab}>
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="personal">Personal</TabsTrigger>
          <TabsTrigger value="groups">Groups</TabsTrigger>
        </TabsList>
        <TabsContent value="personal">
          <PersonalTab />
        </TabsContent>
        <TabsContent value="groups">
          <GroupsTab />
        </TabsContent>
      </Tabs>
    </div>
  )
}
