// pages/under_construction/UnderConstruction.tsx
import React from "react";
import { Card } from "../../components/ui/card";
import Maintenance from "../../assets/maintenance.png";
import { useBackButton } from '@/hooks/useBackButton';

export const UnderConstruction: React.FC = () => {

    useBackButton();

    return (
        <div className="flex items-center justify-center min-h-screen">
            <Card className="p-8 rounded-lg shadow-lg max-w-md text-center bg-card">
                <img
                    src={Maintenance}
                    alt="Image of a maintenance worker"
                    className="mx-auto mb-4"
                />
                <h1 className="text-2xl font-bold mb-4 text-card-foreground">
                    UNDER CONSTRUCTION
                </h1>
                <p className="text-muted-foreground">
                    We're working hard to bring you this page. Please check back later! 😊
                </p>
            </Card>
        </div>
    );
};