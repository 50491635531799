// hooks/useTelegramReceiptsData.ts
import { useEffect, useState } from "react";
import { getAPI } from "@/api";
import { Receipt } from "@/types/entities";

export const useTelegramReceiptsData = () => {
  const [receipts, setReceipts] = useState<Receipt[]>([]);

  useEffect(() => {
    const fetchReceiptsData = async () => {
      try {
        const api = getAPI();
        const data = await api.telegramReceiptsData.fetchAllReceiptsData();
        setReceipts(data);
      } catch (error) {
        console.error("Failed to fetch telegram receipts", error);
      }
    };

    fetchReceiptsData();
  }, []);

  return receipts;
};