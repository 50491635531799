import { SignIdentity } from "@dfinity/agent";
import { User } from "@junobuild/core";
import { atom } from "recoil";

export const authState = atom({
  key: "auth",
  default: null as User | null,
});

export const customIdentity = atom({
  key: "customIdentity",
  default: null as SignIdentity | null
})
