import {
  getTasks,
  startTask,
  checkTask,
  completeTask,
} from "@/api/supabase/tasks";
import { Task } from "@/types/entities";
import { NavigateFunction } from "react-router-dom";
import { openLink } from "@telegram-apps/sdk-react";
import { checkIsTMA } from "@/hooks/useIsTMA";

export async function handleStartTask(
  task: Task,
  navigate: NavigateFunction,
  onError: (error: string | null) => void
): Promise<boolean> {
  const isTma = await checkIsTMA();

  if (!isTma) {
    return false;
  }

  try {
    if (task.navigate) {
      if (task.navigate.startsWith("https://t.me/")) {
        openLink(task.navigate);
      } else if (task.navigate.startsWith("https")) {
        openLink(task.navigate);
      } else {
        navigate(task.navigate);
      }
    }

    await startTask(task.id);

    return true;
  } catch (error) {
    console.error("Error starting task:", error);
    onError((error as Error).message);
    return false;
  }
}

export async function handleCheckTask(task: Task): Promise<boolean> {
  try {
    const response = await completeTask(task.id);
    console.log("Task completed:", response);

    return true;
  } catch (error) {
    console.error("Error during task operation:", error);
  } finally {
  }
  return false;
}
