import { Receipt } from "@/types/entities";
import { _getHeaders, URL_BASE } from "./shared";

export async function getReceipts() {
  const response = await fetch(`${URL_BASE}/receipts`, {
    method: "GET",
    headers: _getHeaders(),
  });

  if (!response.ok) {
    throw new Error("Failed to fetch receipts");
  }

  return response.json();
}

export async function getReceipt(receiptId: string) {
  const response = await fetch(`${URL_BASE}/receipts/${receiptId}`, {
    method: "GET",
    headers: _getHeaders(),
  });

  if (!response.ok) {
    throw new Error("Failed to fetch receipt");
  }

  return response.json();
}

export async function createReceipt(receipt: Receipt, receiptKey?: string) {
  const data = receiptKey ? { id: receiptKey, ...receipt } : receipt;
  const response = await fetch(`${URL_BASE}/receipts`, {
    method: "POST",
    headers: _getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error("Failed to create receipt");
  }

  return response.json();
}

export async function ingestReceipt(imageUrl: string) {
  const response = await fetch(`${URL_BASE}/receipts/ingest`, {
    method: "POST",
    headers: _getHeaders(),
    body: JSON.stringify({ imageUrl }),
  });

  if (!response.ok) {
    throw new Error("Failed to ingest receipt");
  }

  return response.json();
}

export async function updateReceipt(receiptId: string, receipt: Receipt) {
  const response = await fetch(`${URL_BASE}/receipts/${receiptId}`, {
    method: "PUT",
    headers: _getHeaders(),
    body: JSON.stringify(receipt),
  });

  if (!response.ok) {
    throw new Error("Failed to update receipt");
  }

  return response.json();
}

export async function deleteReceipt(
  receiptId: string
): Promise<{ success: boolean; message: string }> {
  const response = await fetch(`${URL_BASE}/receipts/${receiptId}`, {
    method: "DELETE",
    headers: _getHeaders(),
  });

  if (!response.ok) {
    return { success: false, message: "Failed to delete receipt" };
  }

  return { success: true, message: "Receipt deleted successfully" };
}
