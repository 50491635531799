import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { authState } from "@/atoms/auth";
import { useRecoilState } from "recoil";
import { Profile } from "@/types/entities";
import { toast } from "@/components/ui/use-toast";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "@/assets/tg_sticker/_DUCK14_MONEY_OUT.json";
import { useIntro } from "@/components/intro/context/IntroContext";
import { ArrowClockwise } from "@phosphor-icons/react";
import { useProfileMutation, useProfileQuery } from "@/queries/profiles";

interface CongratulationsPageFiveProps {
  onPrev?: () => void;
}

const CongratulationsPageFive: React.FC<CongratulationsPageFiveProps> = () => {
  const [auth] = useRecoilState(authState);
  const { data: profile } = useProfileQuery();
  const profileMutation = useProfileMutation();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { resetSteps } = useIntro();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const updateOnboardingStatus = async (
    authKey: string,
    profile: Profile | null
  ) => {
    if (!profile) return;

    const updatedProfile: Profile = {
      ...profile,
      introduction_completed: true,
    };

    try {
      profileMutation.mutate(updatedProfile);
      toast({
        title: "Signed up successfully 🎉",
      });
      localStorage.removeItem(`currentStep_${authKey}`);
    } catch (e) {
      console.error(e);
      toast({
        title: "Error completing onboarding 😢. Try again",
      });
    }
  };

  const handleFinish = async () => {
    console.log("Finish button clicked");
    setLoading(true);
    if (auth) {
      await updateOnboardingStatus(auth.key, profile);
      resetSteps();
      navigate("/");
    } else {
      toast({
        title: "Error completing onboarding 😢. Try again",
      });
    }
    setLoading(false);
  };

  return (
    <div className="w-full sm:w-1/2 lg:w-1/3 p-4 flex flex-col h-full">
      <div className="flex flex-col items-center justify-center flex-grow">
        <div className="w-[60%] mb-10">
          <Lottie options={defaultOptions} />
        </div>
        <div className="text-lg mb-10 text-center">
          <p className="text-2xl font-semibold p-2">Congratulations!</p>
          <p>
            You’ve just earned <strong>100 Devotion Points</strong> & <strong>10 chat credits</strong> for signing
            up to Bridge23
          </p>
        </div>
      </div>
      <div className="mt-auto">
        <Button
          onClick={handleFinish}
          size="lg"
          className="px-4 py-2 rounded-md w-full sm:w-[70%] lg:w-[60%] mx-auto flex items-center justify-center"
          disabled={loading}
        >
          {loading ? (
            <>
              <ArrowClockwise className="mr-2 h-4 w-4 animate-spin" />
              <span>Finishing...</span>
            </>
          ) : (
            <span>Finish</span>
          )}
        </Button>
      </div>
    </div>
  );
};

export default CongratulationsPageFive;
