import { useRecoilState } from "recoil";
import { Profile } from "@/types/entities";
import { ProfileFormBase, FormSchema } from "./ProfileFormBase";
import { useToast } from "../ui/use-toast";
import { authState } from "@/atoms/auth";
import { useState } from "react";
import { useProfileMutation } from "@/queries/profiles";

type Props = {
  profile: Profile | null;
  authKey: string;
  onNext?: () => void;
  onPrev?: () => void;
};

export function NewProfileForm({ profile, onNext }: Props) {
  const [user] = useRecoilState(authState);
  const { toast } = useToast();
  const [, setIsSaving] = useState(false);
  const profileMutation = useProfileMutation();

  const handleSubmit = async (data: FormSchema) => {
    // console.log("HandleSubmit called in EditProfileForm", data);
    setIsSaving(true);

    const updatedProfile: Profile = {
      ...profile, // Keep existing values
      ...data, // Overwrite only fields provided by the form
      avatar_svg: data.avatar_svg ?? profile?.avatar_svg ?? "", // Ensure avatar_svg is always a string
    };

    try {
      profileMutation.mutate(updatedProfile);
      if (onNext) {
        onNext();
      }
      console.log("Profile successfully updated", updatedProfile);
    } catch (e) {
      console.error("Error updating profile:", e);
      toast({
        title: "Error updating profile 😢. Try again",
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <ProfileFormBase
      profile={profile}
      onSubmit={handleSubmit}
      title="Create Profile"
      buttonText="Proceed"
      isEditMode={false}
    />
  );
}
