import React, { useState, useEffect, useRef } from "react";
import { Drawer, DrawerContent, DrawerTrigger } from "@/components/ui/drawer";
import Keypad from "./Keypad";
// import { getCurrencySymbol } from "@/components/data/currencies";
import CurrencySelect from "@/components/CurrencySelect";
import { CaretLeft } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";

interface AmountInputProps {
  amount: string;
  handleAmountChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeypadClick: (value: string) => void;
  currency: string;
  setCurrency: (currency: string) => void;
}

const AmountInput: React.FC<AmountInputProps> = ({
  amount,
  handleAmountChange,
  handleKeypadClick,
  currency,
  setCurrency,
}) => {
  const [showKeypad, setShowKeypad] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [amountOnFocus, setAmountOnFocus] = useState(false);
  const amountInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const closeKeypad = () => setShowKeypad(false);

  const getFontSize = () => {
    if (amount.length > 10) return "text-2xl";
    if (amount.length > 7) return "text-3xl";
    return "text-5xl";
  };

  const formatDisplayAmount = (value: string) => {
    const parts = value.split(".");
    return (
      parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      (parts[1] ? "." + parts[1] : "")
    );
  };

  return (
    <div className="w-full">
      <div className="flex items-center">
        <button
          onClick={() => navigate("/")}
          aria-label="Back"
          className="p-2 z-10"
        >
          <CaretLeft className="h-6 w-6 text-gray-500" />
        </button>
        <div
          className="flex-grow flex justify-center"
          style={{ marginLeft: "-50px" }}
        >
          <CurrencySelect currency={currency} onCurrencyChange={setCurrency} />
        </div>
      </div>
      {/* {isMobile ? (
        <Drawer open={showKeypad} onOpenChange={setShowKeypad}>
          <DrawerTrigger asChild>
            <div
              className="flex items-center justify-center text-5xl font-semibold text-gray-100 py-4 w-full cursor-pointer"
              onClick={() => setShowKeypad(true)}
            >
              <span
                className={`${getFontSize()} mr-1 text-gray-800 dark:text-gray-200`}
              >
                {getCurrencySymbol(currency)}
              </span>
              <span
                className={`${getFontSize()} ${amount !== "0.00" ? "text-gray-800 dark:text-gray-200" : "text-gray-400 dark:text-gray-600"}`}
              >
                {formatDisplayAmount(amount) || "0.00"}
              </span>
            </div>
          </DrawerTrigger>
          <DrawerContent
            className="fixed mt-60 bg-gray-800 p-6 z-50"
            onClick={(e) => e.stopPropagation()} // Prevent event propagation
          >
            <div onClick={(e) => e.stopPropagation()}>
              <Keypad
                handleKeypadClick={handleKeypadClick}
                closeKeypad={closeKeypad}
              />
            </div>
          </DrawerContent>
        </Drawer>
      ) : ( */}
      <div className="flex items-center justify-center text-5xl font-semibold text-gray-100 py-4 w-full h-28">
        <span
          className={`${getFontSize()} mr-1 text-gray-800 dark:text-gray-200`}
          onClick={() => amountInputRef.current?.focus()}
        >
          {/* {getCurrencySymbol(currency)} */}
          {currency}
        </span>
        <input
          value={
            amountOnFocus && amount === "0.00"
              ? ""
              : formatDisplayAmount(amount)
          }
          onChange={handleAmountChange}
          className={`${getFontSize()} ${amount !== "0.00" ? "text-gray-800 dark:text-gray-200" : "text-gray-400 dark:text-gray-600"} bg-transparent border-none text-center w-full focus:outline-none focus:ring-0`}
          type="text"
          inputMode="decimal"
          pattern="[0-9.]*"
          aria-label="Amount"
          onFocus={() => setAmountOnFocus(true)}
          onBlur={() => setAmountOnFocus(false)}
          style={{ width: `${formatDisplayAmount(amount).length}ch` }}
          ref={amountInputRef}
        />
        <span className="text-red-500 ml-1">*</span>
      </div>
    </div>
  );
};

export default AmountInput;
