import { completeTask, getTasks, startTask } from '@/api/supabase/tasks';
import { Task } from '@/types/entities';
import React, { useEffect, useState } from 'react';

export const PointsPage: React.FC = () => {
    const [tasks, setTasks] = useState<Task[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        async function fetchTasks() {
            try {
                const data: Task[] = await getTasks();
                setTasks(data);
            } catch (error) {
                console.error("Error fetching tasks:", error);
                setError((error as Error).message);
            }
        }

        fetchTasks();
    }, []);

    const handleStartTask = async (taskId: number) => {
        try {
            const taskActivity = await startTask(taskId);
            alert(`Task ${taskId} started successfully 🎉`);
            console.log("Task started:", taskActivity);
            const updatedTasks = await getTasks();
            setTasks(updatedTasks);
        } catch (error) {
            console.error("Error starting task:", error);
            setError((error as Error).message);
        }
    }

    const handleCompleteTask = async (taskId: number) => {
        try {
            const taskActivity = await completeTask(taskId);
            alert(`Task ${taskId} completed successfully 🎉`);
            console.log("Task completed:", taskActivity);
            const updatedTasks = await getTasks();
            setTasks(updatedTasks);
        } catch (error) {
            console.error("Error completing task:", error);
            setError((error as Error).message);
        }
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <h1>Tasks</h1>
            <ul>
                {tasks.map((task) => (
                    <li key={task.id}>
                        <h2>{task.name}</h2>
                        <p>Task ID: {task.id}</p>
                        <p>Started: {task.is_started ? "Yes" : "No"}</p>
                        <p>Completed: {task.is_done ? "Yes" : "No"}</p>
                        <p>URL: {task.navigate}</p>
                        {!task.is_started && <button onClick={() => handleStartTask(task.id)}>Start Task</button>}
                        {task.is_started && !task.is_done && <button onClick={() => handleCompleteTask(task.id)}>Complete Task</button>}
                    </li>
                ))}
            </ul>
        </div>
    );
}
