"use client";

import { authState } from "@/atoms/auth";
import {
  useRecoilState,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import { IntroProvider, useIntro } from "../../components/intro/context/IntroContext";
import { Profile } from "@/types/entities";
import Loading from "@/components/LoadingSplashScreen";
import { currenciesState, fetchCurrencies } from "@/atoms/exchangeRate";
import { useEffect } from "react";
import CongratulationsPageFive from "@/components/intro/CongratulationsPageFive";
import { CreateProfilePageFour } from "@/components/intro/CreateProfilePageFour";
import ProgressBar from "@/components/intro/ProgressBar";
import SingleOnboardingPage from "@/components/intro/SingleOnboardingPage";
import { useProfileQuery } from "@/queries/profiles";

type StepComponentProps = {
  onNextStep: (step: number) => void;
  onPrevStep: (step: number) => void;
  profile: Profile | null;
  authKey?: string;
};

const steps = [
  ({ onNextStep }: StepComponentProps) => (
    <SingleOnboardingPage onNext={() => onNextStep(1)} />
  ),
  ({ onNextStep, onPrevStep, profile, authKey }: StepComponentProps) => (
    <CreateProfilePageFour
      profile={profile}
      authKey={authKey!}
      onNext={() => onNextStep(2)}
      onPrev={() => onPrevStep(0)}
    />
  ),
  ({ onPrevStep }: StepComponentProps) => (
    <CongratulationsPageFive onPrev={() => onPrevStep(1)} />
  ),
];

const getProgressStage = (step: number): number => {
  return step;
};

function IntroSteps() {
  const { currentStep, setCurrentStep } = useIntro();
  const [auth] = useRecoilState(authState);
  const { data: profile } = useProfileQuery();
  const StepComponent = steps[currentStep];
  const currenciesLoadable = useRecoilValueLoadable(fetchCurrencies);
  const setCurrencies = useSetRecoilState(currenciesState);

  const handleNextStep = (step: number) => {
    setCurrentStep(step);
  };

  const handlePrevStep = (step: number) => {
    setCurrentStep(step);
  };

  const currentStage = getProgressStage(currentStep);

  useEffect(() => {
    const loadCurrencies = async () => {
      if (currenciesLoadable.state === "hasValue") {
        setCurrencies(currenciesLoadable.contents);
      }
    };

    loadCurrencies();
  }, [setCurrencies, currenciesLoadable]);

  return (
    <div className="flex flex-col h-screen">
      <ProgressBar currentStage={currentStage} />
      <div className="flex-grow flex justify-center items-center">
        <StepComponent
          profile={profile}
          authKey={auth?.key as string}
          onNextStep={handleNextStep}
          onPrevStep={handlePrevStep}
        />
      </div>
    </div>
  );
}

function Introduction() {
  const [auth] = useRecoilState(authState);

  if (!auth) {
    return <Loading />;
  }

  return (
    <IntroProvider userKey={auth.key}>
      <IntroSteps />
    </IntroProvider>
  );
}

export default Introduction;
