import { fetchAllTelegramReceipts, telegramReceiptsState } from "@/atoms/telegram_receipts";
import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

export const useTelegramReceipts = () => {
  const telegramReceipts = useRecoilValue(telegramReceiptsState);
  const setTelegramReceipts = useSetRecoilState(telegramReceiptsState);

  useEffect(() => {
    const loadTelegramReceipts = async () => {
      const allTelegramReceipts = await fetchAllTelegramReceipts();
      setTelegramReceipts(allTelegramReceipts);
    };

    loadTelegramReceipts();
  }, [setTelegramReceipts]);

  return telegramReceipts;
};