type PageWrapperProps = {
  children: React.ReactNode;
};
export default function PageWrapper({ children }: PageWrapperProps) {
  return (
    <div className="container mx-auto px-2 lg:px-8 max-w-screen-xl min-h-screen pt-20 pb-24">
      {children}
    </div>
  );
}
