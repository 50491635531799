// export default CreateProfilePageFour;
import { z } from "zod";
import * as Sentry from "@sentry/react";
import { useRecoilState } from "recoil";
import { Profile } from "@/types/entities";
import { createAvatar } from "@dicebear/core";
import { openPeeps } from "@dicebear/collection";
import { getRandomColor } from "@/hooks/useBackground";
import { authState } from "@/atoms/auth";
import { useToast } from "@/components/ui/use-toast";
import { FormSchema, ProfileFormIntro } from "@/components/intro/ProfileFormIntro";
import { useProfileMutation } from "@/queries/profiles";
import { useProfileQuery } from "@/queries/profiles";

type Props = {
  profile: Profile | null;
  authKey: string;
  onNext?: () => void;
  onPrev?: () => void;
};

const profileSchema = z.object({
  avatar_svg: z.string().optional(),
  color: z.string().optional(),
  username: z
    .string()
    .min(3, { message: "Username should be at least 3 characters long" })
    .max(100, { message: "Username should be <= 100 characters long" }),
  timezone: z.string({
    required_error: "Timezone is required",
  }),
});

export function CreateProfilePageFour({ profile, onNext }: Props) {
  const [user] = useRecoilState(authState);
  const profileMutation = useProfileMutation();
  const { toast } = useToast();

  const handleSubmit = async (data: FormSchema) => {
    const color = profile?.color || getRandomColor();
    const avatar_svg =
      profile?.avatar_svg ||
      createAvatar(openPeeps, {
        seed: new Date().toJSON(),
        backgroundColor: [color],
      }).toString();

    const newProfile: Profile = {
      ...data,
      avatar_svg,
      color,
      introduction_completed: true,
    };

    try {
      profileSchema.parse(data); // Ensure validation
      console.log("authKey", user?.key);
      console.log("profile keys", Object.keys(newProfile));
      await profileMutation.mutateAsync(newProfile);
      toast({
        title: "Profile successfully saved 🎉",
      });
      if (onNext) {
        onNext();
      }
    } catch (e) {
      console.error("Error saving profile", data);
      console.error(e);
      Sentry.captureException(e, { data });
      if (e instanceof z.ZodError) {
        toast({
          title: "Error saving profile 😢. Please check required fields.",
          description: e.errors.map((err) => err.message).join(", "),
        });
      } else {
        toast({
          title: "Error saving profile 😢. Try again",
        });
      }
    }
  };

  if (!user) {
    return null;
  }

  return (
    <ProfileFormIntro
      profile={profile}
      onSubmit={handleSubmit}
      title="Create Profile"
      buttonText="Next"
      isEditMode={false}
    />
  );
}
