// hooks/useLoadReceipts.js
import { fetchAllReceipts, receiptsState } from "@/atoms/receipt";
import { useEffect } from "react";
import { useRecoilStateLoadable } from "recoil";

export const useLoadReceipts = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [receipts, setReceipts] = useRecoilStateLoadable(receiptsState);

  useEffect(() => {
    const loadReceipts = async () => {
      try {
        const receiptsData = await fetchAllReceipts();
        setReceipts(receiptsData);
      } catch (error) {
        console.error("Error loading receipts:", error);
      }
    };

    loadReceipts();
  }, [setReceipts]);
};
