// src/api/telegram/referral-link.ts
import { openTelegramLink, isTMA, retrieveLaunchParams } from "@telegram-apps/sdk-react";

export async function handleShareTelegramReferralLink(text?: string) {
  const launchParams = retrieveLaunchParams();

  const shareText = encodeURIComponent(
    text ||
      "I’m training my Personal AI agent to monetize my data by collaborating with Business AI agents! 💼💡 Join the Bridge23 Beta and start earning passive income from your data. Secure your Airdrop spot and grow your AI-powered earnings today. 🚀"
  );

  const referralLink = encodeURIComponent(`https://t.me/bridge23bot/bridge23app?startapp=inv_${launchParams.initData?.user?.id}`);
  const telegramLink = `https://t.me/share/url?url=${referralLink}&text=${shareText}`;

  openTelegramLink(telegramLink);
}