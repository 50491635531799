// api/juno/transaction.ts
import { getDoc, setDoc, listDocs, deleteDoc } from "@junobuild/core";
import { ITransactionAPI } from "../types";
import { Transaction } from "../../types/entities";
import { nanoid } from "nanoid";
import { getSatteliteOptions } from "..";

// Constants
const TRANSACTIONS_COLLECTION = "transactions";

export class JunoTransactionAPI implements ITransactionAPI {
  async fetchOne(transactionKey: string): Promise<Transaction | null> {
    const transaction = await getDoc<Transaction>({
      collection: TRANSACTIONS_COLLECTION,
      key: transactionKey,
      satellite: getSatteliteOptions(),
    });

    if (!transaction) {
      return null;
    }

    const result = { id: transaction.key, ...transaction.data };
    console.log("JunoTransactionAPI fetchOne result", result);
    return result;
  }

  async fetchAll(): Promise<Transaction[]> {
    const response = await listDocs<Transaction>({
      collection: TRANSACTIONS_COLLECTION,
      satellite: getSatteliteOptions(),
    });

    if (!response.items) {
      return [];
    }

    const result = response.items.map((doc) => ({ id: doc.key, ...doc.data }));
    console.log("JunoTransactionAPI fetchAll result", result);
    return result;
  }

  async create(
    data: Transaction,
    transactionKey?: string
  ): Promise<Transaction> {
    const key = transactionKey || nanoid();

    await setDoc<Transaction>({
      collection: TRANSACTIONS_COLLECTION,
      doc: {
        key: key,
        data: { ...data },
      },
      satellite: getSatteliteOptions(),
    });

    const result = { id: key, ...data };
    console.log("JunoTransactionAPI create result", result);
    return result;
  }

  async update(
    transactionKey: string,
    data: Transaction
  ): Promise<Transaction> {
    const existingTransaction = await getDoc<Transaction>({
      collection: TRANSACTIONS_COLLECTION,
      key: transactionKey,
      satellite: getSatteliteOptions(),
    });

    if (!existingTransaction) {
      throw new Error("Transaction not found");
    }

    await setDoc<Transaction>({
      collection: TRANSACTIONS_COLLECTION,
      doc: {
        key: transactionKey,
        data,
        version: existingTransaction.version,
      },
      satellite: getSatteliteOptions(),
    });

    const result = { id: transactionKey, ...data };
    console.log("JunoTransactionAPI update result", result);
    return result;
  }

  async delete(
    transactionKey: string
    // data: Transaction
  ): Promise<{ success: boolean; message: string }> {
    const existingTransaction = await getDoc<Transaction>({
      collection: TRANSACTIONS_COLLECTION,
      key: transactionKey,
      satellite: getSatteliteOptions(),
    });

    try {
      // Ensure getSatteliteOptions includes necessary version info
      const satelliteOptions = getSatteliteOptions();

      await deleteDoc({
        collection: TRANSACTIONS_COLLECTION,
        doc: {
          key: transactionKey,
          version: existingTransaction?.version,
          data: {}, // Provide an empty object to satisfy the type requirement
        },
        satellite: satelliteOptions,
      });

      return {
        success: true,
        message: `Transaction with key ${transactionKey} successfully deleted.`,
      };
    } catch (error) {
      console.error(
        `Error deleting transaction with key ${transactionKey}:`,
        error
      );
      return {
        success: false,
        message: `Failed to delete transaction with key ${transactionKey}.`,
      };
    }
  }
}
