// src/components/ui/DeleteDialog.tsx
import React from 'react';
import { Dialog, DialogClose, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogContent } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { ArrowClockwise } from '@phosphor-icons/react';

interface DeleteDialogProps {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    onDelete: () => void;
    isDeleting: boolean;
    text?: string;
}

const DeleteDialog: React.FC<DeleteDialogProps> = ({ open, onOpenChange, onDelete, isDeleting, text='receipt' }) => {
    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className="sm:max-w-md w-[90%] rounded">
                <DialogHeader className='pt-4'>
                    <DialogTitle>Are you sure you want to delete this {text}?</DialogTitle>
                    <DialogDescription>
                        This action cannot be undone.
                    </DialogDescription>
                </DialogHeader>
                <DialogFooter>
                    <DialogClose asChild>
                        <Button variant="secondary" onClick={() => onOpenChange(false)} disabled={isDeleting}>
                            Cancel
                        </Button>
                    </DialogClose>
                    <Button variant="destructive" className='mb-4' onClick={onDelete} disabled={isDeleting}>
                        {isDeleting ? (
                            <>
                                <ArrowClockwise className="mr-2 h-4 w-4 animate-spin" />
                                Deleting...
                            </>
                        ) : (
                            'Delete'
                        )}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default DeleteDialog;
