import { useCurrencies } from "@/hooks/useCurrencies";

type ProviderProps = {
  children: React.ReactNode;
};

export default function CurrenciesProvider({children}: ProviderProps) {
  useCurrencies()

  return <>{children}</>
}