import { authState } from "@/atoms/auth";
import { fetchProfile, profileState } from "@/atoms/profile";
import { fetchProfileStats, profileStatsState } from "@/atoms/profile_stats";
import { Profile, ProfileStats } from "@/types/entities";
import { User, authSubscribe } from "@junobuild/core";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import Loading from "@/components/LoadingSplashScreen";
import posthog from "posthog-js";

type ProvidersProps = {
  children: React.ReactNode;
};

export default function JunoAuthProvider({ children }: ProvidersProps) {
  const [, setUser] = useRecoilState(authState);
  const [, setProfile] = useRecoilState<Profile | null>(profileState);
  const [, setProfileStats] = useRecoilState<ProfileStats | null>(
    profileStatsState
  );
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const fetchProfileAndRedirect = useCallback(
    async (u: User) => {
      try {
        const _profile = await fetchProfile(u.key);
        const profileStats = await fetchProfileStats(u.key);

        posthog.identify(
          "juno:" + u.key,
          {
            username: _profile?.username,
            source: "juno",
          } // optional: set additional person properties
        );

        if (!u) {
          navigate("/login");
          setLoading(false);
          return;
        }

        if (u && !_profile) {
          console.error("No profile returned from fetchProfile");
          setLoading(false);
          // navigate("/onboarding");
          navigate("/intro");
          return;
        }

        if (!_profile?.introduction_completed) {
          if (_profile) setProfile(_profile);
          // if (location.pathname !== "/onboarding") {
          if (location.pathname !== "/intro") {
            // navigate("/onboarding");
            navigate("/intro");
          }
        } else {
          setProfile(_profile);
          setProfileStats(profileStats);
          // if (location.pathname === "/onboarding") {
          if (location.pathname === "/intro") {
            navigate("/");
          }
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
        navigate("/login");
      } finally {
        setLoading(false);
      }
    },
    [navigate, setProfile, location.pathname, setProfileStats]
  );

  useEffect(() => {
    const unsubscribe = authSubscribe((user: User | null) => {
      if (user) {
        setUser(user);
        fetchProfileAndRedirect(user);
      } else {
        console.log("Juno auth provider set user to null");
        setUser(null);
        setProfile(null);
        setProfileStats(null);
        navigate("/login");
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [fetchProfileAndRedirect, setUser, setProfile, navigate, setProfileStats]);

  if (loading) {
    return <Loading />; // Replace with your loading indicator
  }

  return <>{children}</>;
}
