"use client";

import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from "react";

interface IntroContextType {
  currentStep: number;
  setCurrentStep: (step: number) => void;
  resetSteps: () => void;
}

const IntroContext = createContext<IntroContextType | undefined>(
  undefined,
);

export const useIntro = (): IntroContextType => {
  const context = useContext(IntroContext);
  if (!context) {
    throw new Error("useIntro must be used within an IntroProvider");
  }
  return context;
};

interface IntroProviderProps {
  children: ReactNode;
  userKey: string;
}

export const IntroProvider: React.FC<IntroProviderProps> = ({
  children,
  userKey,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(() => {
    const saved = localStorage.getItem(`currentIntroStep_${userKey}`);
    return saved ? JSON.parse(saved) : 0;
  });

  useEffect(() => {
    localStorage.setItem(`currentIntroStep_${userKey}`, JSON.stringify(currentStep));
  }, [currentStep, userKey]);

  const resetSteps = () => {
    setCurrentStep(0);
    localStorage.removeItem(`currentIntroStep_${userKey}`); // Сброс шага в localStorage
  };

  return (
    <IntroContext.Provider value={{ currentStep, setCurrentStep, resetSteps }}>
      {children}
    </IntroContext.Provider>
  );
};
