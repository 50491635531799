/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-expect-error
export const idlFactory = ({ IDL }) => {
  const GetLogMessagesFilter = IDL.Record({
    'analyzeCount' : IDL.Nat32,
    'messageRegex' : IDL.Opt(IDL.Text),
    'messageContains' : IDL.Opt(IDL.Text),
  });
  const Nanos = IDL.Nat64;
  const GetLogMessagesParameters = IDL.Record({
    'count' : IDL.Nat32,
    'filter' : IDL.Opt(GetLogMessagesFilter),
    'fromTimeNanos' : IDL.Opt(Nanos),
  });
  const GetLatestLogMessagesParameters = IDL.Record({
    'upToTimeNanos' : IDL.Opt(Nanos),
    'count' : IDL.Nat32,
    'filter' : IDL.Opt(GetLogMessagesFilter),
  });
  const CanisterLogRequest = IDL.Variant({
    'getMessagesInfo' : IDL.Null,
    'getMessages' : GetLogMessagesParameters,
    'getLatestMessages' : GetLatestLogMessagesParameters,
  });
  const CanisterLogFeature = IDL.Variant({
    'filterMessageByContains' : IDL.Null,
    'filterMessageByRegex' : IDL.Null,
  });
  const CanisterLogMessagesInfo = IDL.Record({
    'features' : IDL.Vec(IDL.Opt(CanisterLogFeature)),
    'lastTimeNanos' : IDL.Opt(Nanos),
    'count' : IDL.Nat32,
    'firstTimeNanos' : IDL.Opt(Nanos),
  });
  const LogMessagesData = IDL.Record({
    'timeNanos' : Nanos,
    'message' : IDL.Text,
  });
  const CanisterLogMessages = IDL.Record({
    'data' : IDL.Vec(LogMessagesData),
    'lastAnalyzedMessageTimeNanos' : IDL.Opt(Nanos),
  });
  const CanisterLogResponse = IDL.Variant({
    'messagesInfo' : CanisterLogMessagesInfo,
    'messages' : CanisterLogMessages,
  });
  const MetricsGranularity = IDL.Variant({
    'hourly' : IDL.Null,
    'daily' : IDL.Null,
  });
  const GetMetricsParameters = IDL.Record({
    'dateToMillis' : IDL.Nat,
    'granularity' : MetricsGranularity,
    'dateFromMillis' : IDL.Nat,
  });
  const UpdateCallsAggregatedData = IDL.Vec(IDL.Nat64);
  const CanisterHeapMemoryAggregatedData = IDL.Vec(IDL.Nat64);
  const CanisterCyclesAggregatedData = IDL.Vec(IDL.Nat64);
  const CanisterMemoryAggregatedData = IDL.Vec(IDL.Nat64);
  const HourlyMetricsData = IDL.Record({
    'updateCalls' : UpdateCallsAggregatedData,
    'canisterHeapMemorySize' : CanisterHeapMemoryAggregatedData,
    'canisterCycles' : CanisterCyclesAggregatedData,
    'canisterMemorySize' : CanisterMemoryAggregatedData,
    'timeMillis' : IDL.Int,
  });
  const NumericEntity = IDL.Record({
    'avg' : IDL.Nat64,
    'max' : IDL.Nat64,
    'min' : IDL.Nat64,
    'first' : IDL.Nat64,
    'last' : IDL.Nat64,
  });
  const DailyMetricsData = IDL.Record({
    'updateCalls' : IDL.Nat64,
    'canisterHeapMemorySize' : NumericEntity,
    'canisterCycles' : NumericEntity,
    'canisterMemorySize' : NumericEntity,
    'timeMillis' : IDL.Int,
  });
  const CanisterMetricsData = IDL.Variant({
    'hourly' : IDL.Vec(HourlyMetricsData),
    'daily' : IDL.Vec(DailyMetricsData),
  });
  const CanisterMetrics = IDL.Record({ 'data' : CanisterMetricsData });
  const TokenChain = IDL.Variant({
    'base_mainnet' : IDL.Null,
    'op_mainnet' : IDL.Null,
    'eth_mainnet' : IDL.Null,
    'tao_mainnet' : IDL.Null,
    'icp_testnet' : IDL.Null,
    'btc_mainnet' : IDL.Null,
    'eth_testnet' : IDL.Null,
    'arb_mainnet' : IDL.Null,
    'sol_mainnet' : IDL.Null,
    'bsc_mainnet' : IDL.Null,
    'ftm_mainnet' : IDL.Null,
    'icp_mainnet' : IDL.Null,
  });
  const Currency = IDL.Variant({
    'cad' : IDL.Null,
    'chf' : IDL.Null,
    'eur' : IDL.Null,
    'gbp' : IDL.Null,
    'idr' : IDL.Null,
    'jpy' : IDL.Null,
    'usd' : IDL.Null,
  });
  const CurrencyQuote = IDL.Record({
    'value' : IDL.Float64,
    'source' : IDL.Opt(IDL.Text),
    'name' : IDL.Text,
    'currency_type' : Currency,
    'description' : IDL.Opt(IDL.Text),
    'created_at' : IDL.Nat64,
    'value_str' : IDL.Text,
    'symbol' : IDL.Text,
  });
  const TokenCurrency = IDL.Variant({
    'btc' : IDL.Null,
    'dai' : IDL.Null,
    'eth' : IDL.Null,
    'exe' : IDL.Null,
    'icp' : IDL.Null,
    'sol' : IDL.Null,
    'bonk' : IDL.Null,
    'ckbtc' : IDL.Null,
    'cketh' : IDL.Null,
    'test' : IDL.Null,
    'usdc' : IDL.Null,
    'usdt' : IDL.Null,
    'wbtc' : IDL.Null,
    'weth' : IDL.Null,
    'wtao' : IDL.Null,
    'sneed' : IDL.Null,
  });
  const TokenQuote = IDL.Record({
    'value' : IDL.Float64,
    'source' : IDL.Opt(IDL.Text),
    'name' : IDL.Text,
    'currency_type' : Currency,
    'created_at' : IDL.Nat64,
    'value_str' : IDL.Text,
    'token_type' : TokenCurrency,
    'symbol' : IDL.Text,
  });
  const Token = IDL.Record({
    'abi' : IDL.Text,
    'decimals' : IDL.Nat,
    'contract' : IDL.Text,
    'name' : IDL.Text,
    'slug' : IDL.Text,
    'description' : IDL.Text,
    'created_at' : IDL.Nat64,
    'chains' : IDL.Vec(TokenChain),
    'token_type' : TokenCurrency,
    'last_quote' : IDL.Opt(TokenQuote),
  });
  const HttpHeader = IDL.Record({ 'value' : IDL.Text, 'name' : IDL.Text });
  const HttpResponsePayload = IDL.Record({
    'status' : IDL.Nat,
    'body' : IDL.Vec(IDL.Nat8),
    'headers' : IDL.Vec(HttpHeader),
  });
  const TransformArgs = IDL.Record({
    'context' : IDL.Vec(IDL.Nat8),
    'response' : HttpResponsePayload,
  });
  const Main = IDL.Service({
    'canisterId' : IDL.Func([], [IDL.Text], ['query']),
    'collectCanisterMetrics' : IDL.Func([], [], []),
    'getCanisterLog' : IDL.Func(
        [IDL.Opt(CanisterLogRequest)],
        [IDL.Opt(CanisterLogResponse)],
        ['query'],
      ),
    'getCanisterMetrics' : IDL.Func(
        [GetMetricsParameters],
        [IDL.Opt(CanisterMetrics)],
        ['query'],
      ),
    'getChains' : IDL.Func([], [IDL.Vec(TokenChain)], ['query']),
    'getCurrencies' : IDL.Func(
        [],
        [IDL.Opt(IDL.Vec(CurrencyQuote))],
        ['query'],
      ),
    'getQuote' : IDL.Func([IDL.Text], [IDL.Opt(CurrencyQuote)], []),
    'getQuoteHistory' : IDL.Func(
        [IDL.Text, IDL.Nat],
        [IDL.Opt(IDL.Vec(CurrencyQuote))],
        ['query'],
      ),
    'getQuoteHistoryCount' : IDL.Func([], [IDL.Nat], ['query']),
    'getTokenQuoteHistory' : IDL.Func(
        [IDL.Text, IDL.Nat],
        [IDL.Opt(IDL.Vec(TokenQuote))],
        ['query'],
      ),
    'getTokens' : IDL.Func([], [IDL.Vec(Token)], ['query']),
    'getTokensWithQuotes' : IDL.Func([], [IDL.Vec(Token)], ['query']),
    'transform_response' : IDL.Func(
        [TransformArgs],
        [HttpResponsePayload],
        ['query'],
      ),
    'whoami' : IDL.Func([], [IDL.Principal], ['query']),
  });
  return Main;
};
export const init = () => { return []; };
