"use client";

import { useState, useEffect } from "react";
// import { useTheme } from "next-themes";
import { isTMA } from "@telegram-apps/sdk-react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Browser, Link } from "@phosphor-icons/react";
import { useRecoilState } from "recoil";
import { authState } from "@/atoms/auth";
import { NFIDProvider, signIn, signOut } from "@junobuild/core";
import { Avatar } from "@/components/Avatar";
import { useNavigate } from "react-router-dom";
import { Widget } from "@typeform/embed-react";
import { useProfileQuery } from "@/queries/profiles";
export function MainMenuToggle() {
  // const { setTheme } = useTheme();
  const { data: profile } = useProfileQuery();
  const [user] = useRecoilState(authState);
  const [isTma, setIsTma] = useState(false);
  const [showFeedbackOptions, setShowFeedbackOptions] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const navigate = useNavigate();
  

  useEffect(() => {
    async function checkTMA() {
      setIsTma(await isTMA());
    }
    checkTMA();
  }, []);

  async function handleLogin() {
    await signIn({
      maxTimeToLive: BigInt(30 * 24 * 60 * 60 * 1000 * 1000 * 1000), // 30 days
      provider: new NFIDProvider({
        appName: "Bridge23",
        logoUrl: "/icon-256x256.png",
      }),
    });
  }

  // function openFeedbackForm() {
  //   window.open("https://bridge23.typeform.com/to/HfkJUBdq", "_blank", "noopener");
  // }
  // https://cko8sv40a0v.typeform.com/to/HfkJUBdq
  // https://docs.google.com/spreadsheets/d/1jEIJJrvGSFIg5hTNyW20WZU7BIuCjyT677sEycy7JRU/edit?usp=sharing
  const handleFeedbackClick = () => {
    setShowFeedbackOptions(true);
  };

  const handleEmbedView = () => {
    setShowFeedbackOptions(false);
    setShowFeedback(true);
  };

  const handleDirectLink = () => {
    window.open(
      "https://form.typeform.com/to/HfkJUBdq?typeform-source=bridge23.typeform.com",
      "_blank",
      "noopener"
    );
    setShowFeedbackOptions(false);
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" size="icon">
            {profile ? (
              <Avatar profile={profile} />
            ) : (
              <span className="text-xl">🚪</span>
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          {user ? (
            <>
              {profile && (
                <DropdownMenuLabel>@{profile?.username}</DropdownMenuLabel>
              )}
              <DropdownMenuItem onClick={() => navigate("/profile")}>
                Profile
              </DropdownMenuItem>
              {/* <DropdownMenuItem onClick={() => navigate("/dashboard")}>
                Tracker
              </DropdownMenuItem> */}
            </>
          ) : (
            <>
              <DropdownMenuItem onClick={() => handleLogin()}>
                Login / Register
              </DropdownMenuItem>
            </>
          )}
          <>
            {profile && (
              <DropdownMenuItem onClick={handleFeedbackClick}>
                Feedback
              </DropdownMenuItem>
            )}
          </>
          <DropdownMenuSeparator />
          <DropdownMenuItem>
            version{" "}
            {
              //@ts-ignore
              __APP_VERSION__
            }
          </DropdownMenuItem>
          {user && !isTma && (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={() => signOut()}>
                Logout
              </DropdownMenuItem>
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
      {/* <DropdownMenuGroup>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>Theme</DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <DropdownMenuItem onClick={() => setTheme("light")}>
                  Bright
                  <span className="ml-2">🌈</span>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => setTheme("dark")}>
                  Dark
                  <span className="ml-2">😈</span>
                </DropdownMenuItem>
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
        </DropdownMenuGroup> */}
      <Dialog open={showFeedbackOptions} onOpenChange={setShowFeedbackOptions}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Feedback Options</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col space-y-4 mt-4">
            <Button
              onClick={handleEmbedView}
              className="flex items-center justify-start"
            >
              <Browser className="mr-2 h-4 w-4" />
              <span>Open with Embed view</span>
            </Button>
            <Button
              onClick={handleDirectLink}
              className="flex items-center justify-start"
            >
              <Link className="mr-2 h-4 w-4" />
              <span>Direct to link</span>
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {showFeedback && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative w-full max-w-3xl h-full max-h-3xl flex items-center justify-center mt-40">
            <Widget
              id="HfkJUBdq"
              style={{ width: "100%", height: "1200%" }}
              className="typeform-widget mt-32"
            />
            <Button
              className="absolute right-1 text-white"
              style={{ top: "500%", width: "80px" }}
              onClick={() => setShowFeedback(false)}
            >
              Close
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
