import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { MessageCircle, Mic, Video, Link, File, Image, Send } from 'lucide-react'
import { useQuery } from "@tanstack/react-query"
import { getStats } from "@/api/supabase/profiles"
import { useProfileQuery } from "@/queries/profiles"

function StatsIcon({ Icon, count, isSent = false }: { Icon: React.ElementType; count: number; isSent?: boolean }) {
  return (
    <div className="flex items-center">
      <Icon className="mr-1" size={16} />
      <span className="text-sm">{count}</span>
      {isSent && <Send className="ml-1" size={12} />}
    </div>
  )
}

export function PersonalAgentStatsCard() {
  const { data: profile } = useProfileQuery();

  const { data: profileStats, isPending: profileStatsLoading } = useQuery({
    queryKey: ["profileStats"],
    queryFn: () => getStats(),
    enabled: !!profile,
  });

  if (profileStatsLoading) {
    return (
      <Card>
        <CardContent className="text-center py-6">
          <p>Loading stats...</p>
        </CardContent>
      </Card>
    );
  }
  return (
    <Card>
      <CardHeader>
        <CardTitle>Personal Agent Stats</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex justify-between mb-4">
          <div className="flex space-x-4">
            <StatsIcon Icon={MessageCircle} count={100} />
            <StatsIcon Icon={Mic} count={50} />
            <StatsIcon Icon={Video} count={30} />
            <StatsIcon Icon={Link} count={20} />
            <StatsIcon Icon={File} count={10} />
            <StatsIcon Icon={Image} count={40} />
          </div>
    
        </div>
        <div className="flex justify-between items-center">
        <div>Chat credits balance: {profileStats?.credits || 0}</div>
          <Button>Buy more chat credits</Button>
        </div>
      </CardContent>
    </Card>
  )
}

