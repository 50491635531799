"use client";

import { EditProfileForm } from "@/components/forms/EditProfileForm";
import PageWrapper from "@/components/layout/PageWrapper";
import Loading from "@/components/LoadingSplashScreen";
import { useProfileQuery } from "@/queries/profiles";

export default function ProfilePage() {
  const { data: profile, isPending } = useProfileQuery();

  if (isPending) {
    return <Loading />;
  }

  return (
    <PageWrapper>
      <EditProfileForm profile={profile} />
    </PageWrapper>
  );
}
