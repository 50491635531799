// api/juno/category.ts
import { Doc, getDoc, listDocs, setDoc } from "@junobuild/core";
import { ICategoryAPI } from "../types";
import { Category } from "@/types/entities";
import { nanoid } from "nanoid";
import { getSatteliteOptions } from "..";

// Constants
const CATEGORIES_COLLECTION = "categories";

export class JunoCategoryAPI implements ICategoryAPI {
  async fetchOne(categoryKey: string): Promise<Category | null> {
    const category = await getDoc<Category>({
      collection: CATEGORIES_COLLECTION,
      key: categoryKey,
      satellite: getSatteliteOptions(),
    });

    if (!category) {
      return null;
    }

    return { id: category.key, ...category.data };
  }

  async fetchAll(): Promise<Category[]> {
    const response = await listDocs<Category>({
      collection: CATEGORIES_COLLECTION,
      satellite: getSatteliteOptions(),
    });

    if (!response.items) {
      return [];
    }

    return response.items.map((doc) => ({ id: doc.key, ...doc.data }));
  }

  async createOrUpdate(data: Category): Promise<Category> {
    const existingCategoryDoc = await this._exists(data.name);
    if (existingCategoryDoc) {
      const existingCategory = existingCategoryDoc.data;
      if (
        existingCategory.icon !== data.icon ||
        existingCategory.type !== data.type
      ) {
        // Update the category with the new icon and type
        const updatedCategory = await setDoc<Category>({
          collection: CATEGORIES_COLLECTION,
          doc: {
            key: existingCategoryDoc.key,
            data: { ...existingCategory, icon: data.icon, type: data.type },
            version: existingCategoryDoc.version, // Include the version for the update
          },
          satellite: getSatteliteOptions(),
        });

        return {
          ...existingCategory,
          icon: data.icon,
          type: data.type,
          id: updatedCategory.key,
        };
      } else {
        return existingCategory; // No changes if the icon and type are the same
      }
    } else {
      // Create a new category
      const categoryKey = nanoid();
      const newDoc = await setDoc<Category>({
        collection: CATEGORIES_COLLECTION,
        doc: {
          key: categoryKey,
          data,
        },
        satellite: getSatteliteOptions(),
      });

      return { id: newDoc.key, ...newDoc.data };
    }
  }

  async _exists(categoryName: string): Promise<Doc<Category> | null> {
    const response = await listDocs<Category>({
      collection: CATEGORIES_COLLECTION,
      satellite: getSatteliteOptions(),
    });

    if (response.items) {
      const existingCategory = response.items.find(
        (doc: Doc<Category>) => doc.data.name === categoryName
      );
      if (!existingCategory) {
        return null;
      }
      return existingCategory;
    }
    return null;
  }
}
