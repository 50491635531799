// api/juno/document.ts
import {
  getDoc,
  setDoc,
  listDocs,
  deleteDoc,
  uploadFile,
} from "@junobuild/core";
import { IDocumentAPI } from "../types";
import { Document } from "../../types/entities";
import { nanoid } from "nanoid";
import { getSatteliteOptions } from "..";

const DOCUMENT_COLLECTION = "documents";

export class JunoDocumentAPI implements IDocumentAPI {
  async fetchOne(documentKey: string): Promise<Document | null> {
    try {
      const document = await getDoc<Document>({
        collection: DOCUMENT_COLLECTION,
        key: documentKey,
        satellite: getSatteliteOptions(),
      });

      return document ? { id: document.key, ...document.data } : null;
    } catch (error) {
      console.error(`Error fetching document with key ${documentKey}:`, error);
      throw error;
    }
  }

  async fetchAll(): Promise<Document[]> {
    try {
      const documentsResponse = await listDocs<Document>({
        collection: DOCUMENT_COLLECTION,
        satellite: getSatteliteOptions(),
      });

      const documents = documentsResponse.items
        ? documentsResponse.items.map((doc) => ({ id: doc.key, ...doc.data }))
        : [];

      return documents;
    } catch (error) {
      console.error("Error fetching all documents:", error);
      throw error;
    }
  }

  async create(data: Document, documentKey?: string): Promise<Document> {
    const key = documentKey || nanoid();
    try {
      await setDoc<Document>({
        collection: DOCUMENT_COLLECTION,
        doc: {
          key: key,
          data: { ...data },
        },
        satellite: getSatteliteOptions(),
      });

      return { id: key, ...data };
    } catch (error) {
      console.error("Error creating document:", error);
      throw error;
    }
  }

  async createDocument(
    document: File,
    docKey?: string
  ): Promise<{ success: boolean; message: string }> {
    const key = docKey || nanoid();
    try {
      const documentKey = await uploadFile({
        data: document,
        collection: DOCUMENT_COLLECTION,
        satellite: getSatteliteOptions(),
      });

      const downloadUrl = documentKey.downloadUrl;

      return {
        success: true,
        message: downloadUrl,
      };
    } catch (error) {
      console.error(`Error uploading document with key ${key}:`, error);
      return {
        success: false,
        message: `Failed to upload document with key ${key}.`,
      };
    }
  }

  async delete(
    documentKey: string
  ): Promise<{ success: boolean; message: string }> {
    const existingDocument = await getDoc<Document>({
      collection: DOCUMENT_COLLECTION,
      key: documentKey,
      satellite: getSatteliteOptions(),
    });
    try {
      await deleteDoc({
        collection: DOCUMENT_COLLECTION,
        doc: {
          key: documentKey,
          data: existingDocument!.data,
          version: existingDocument?.version,
        },
        satellite: getSatteliteOptions(),
      });

      return {
        success: true,
        message: `Document with key ${documentKey} successfully deleted.`,
      };
    } catch (error) {
      console.error(`Error deleting document with key ${documentKey}:`, error);
      return {
        success: false,
        message: `Failed to delete document with key ${documentKey}.`,
      };
    }
  }
}