// atoms/telegramReceiptsData.ts
import { atom } from "recoil";
import { Receipt } from "@/types/entities";
import { getAPI } from "@/api";

// State atom for a list of telegram receipts data
export const telegramReceiptsDataState = atom<Receipt[]>({
  key: "allTelegramReceiptsDataState",
  default: [],
});

const API = getAPI();

/**
 * Fetch all telegram receipts data.
 * @return {Promise<Receipt[]>} List of all telegram receipts data.
 */
export async function fetchAllTelegramReceiptsData(): Promise<Receipt[]> {
  try {
    return await API.telegramReceiptsData.fetchAllReceiptsData();
  } catch (error) {
    console.error("Failed to fetch all telegram receipts data:", error);
    return [];
  }
}

/**
 * Update an existing telegram receipt and update the Recoil state.
 * @param {Receipt} data - The receipt data to update.
 * @param {string} receiptKey - The key of the receipt.
 * @param {Function} setTelegramReceiptsData - Function to update the telegram receipts data state.
 * @return {Promise<Receipt>} The updated receipt data.
 */
export async function updateTelegramReceipt(
  data: Receipt,
  receiptKey: string,
  setTelegramReceiptsData: (
    callback: (receipts: Receipt[]) => Receipt[]
  ) => void
): Promise<Receipt> {
  try {
    const receipt = await API.telegramReceiptsData.update(receiptKey, data);

    setTelegramReceiptsData((prevReceipts: Receipt[]) =>
      prevReceipts.map((receipt) =>
        receipt.id === receiptKey
          ? { ...data, id: receiptKey }
          : receipt
      )
    );

    return receipt;
  } catch (error) {
    console.error("Failed to update telegram receipt:", error);
    throw error;
  }
}

/**
 * Delete a telegram receipt and update the Recoil state.
 * @param {string} receiptKey - The key of the receipt.
 * @param {Function} setTelegramReceiptsData - Function to update the telegram receipts data state.
 * @return {Promise<{ success: boolean; message: string }>} The result of the deletion operation.
 */
export async function deleteTelegramReceipt(
  receiptKey: string,
  setTelegramReceiptsData: (
    callback: (receipts: Receipt[]) => Receipt[]
  ) => void
): Promise<{ success: boolean; message: string }> {
  try {
    const response = await API.telegramReceiptsData.delete(receiptKey);
    if (response.success) {
      console.log("Deleted telegram receipt with key:", receiptKey);
      setTelegramReceiptsData((prevReceipts: Receipt[]) =>
        prevReceipts.filter(
          (receipt) => receipt.id !== receiptKey
        )
      );
    }
    return response;
  } catch (error) {
    console.error("Failed to delete telegram receipt:", error);
    return {
      success: false,
      message: `Failed to delete telegram receipt with key ${receiptKey}.`,
    };
  }
}