import { useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../ui/command";
import { Button } from "../ui/button";
import {
  FormControl,
  FormDescription,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { cn } from "@/lib/utils";
import { Controller } from "react-hook-form";

type Option = {
  value: string;
  label: string;
};

interface SelectablePopoverProps {
  label: string;
  placeholder: string;
  options: Option[];
  value: string;
  onChange: (value: string) => void;
  control: any;
  name: string;
  className?: string;  // Add className to props
}

const SelectablePopover: React.FC<SelectablePopoverProps> = ({
  label,
  placeholder,
  options,
  value,
  onChange,
  control,
  name,
  className,    // Destructure className
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <FormItem className="flex flex-col">
      <FormLabel>{label}</FormLabel>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => (
          <>
            <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
              <PopoverTrigger asChild>
                <FormControl className="w-full">
                  <Button
                    variant="outline"
                    role="combobox"
                    className={cn(
                      "w-full justify-between",
                      !field.value && "text-muted-foreground",
                      className
                    )}
                  >
                    {field.value
                      ? options.find((option) => option.value === field.value)?.label
                      : placeholder}
                  </Button>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent className="w-64 p-0 max-h-[200px] overflow-auto">
                <Command>
                  <CommandInput placeholder={`Search ${label.toLowerCase()}...`} />
                  <CommandEmpty>No {label.toLowerCase()} found.</CommandEmpty>
                  <CommandGroup>
                    {options.map((option) => (
                      <CommandItem
                        value={option.label}
                        key={option.value}
                        onSelect={() => {
                          field.onChange(option.value);
                          setPopoverOpen(false);
                        }}
                      >
                        {option.label}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </Command>
              </PopoverContent>
            </Popover>
            {fieldState.error && (
              <span className="text-red-500 text-sm mt-1">
                {fieldState.error.message}
              </span>
            )}
          </>
        )}
      />
      <FormDescription>
        This is the {label.toLowerCase()} that will be used in the dashboard.
      </FormDescription>
    </FormItem>
  );
};

export default SelectablePopover;