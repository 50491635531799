"use client";

import { Button } from "@/components/ui/button";
import { NFIDProvider, signIn } from "@junobuild/core";

import logoUrl from "@/assets/icon-512x512.png";
import iconUrl from "@/assets/icon-192x192.png";
import icpUrl from "@/assets/ICP-badge.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { isTMA } from "@telegram-apps/sdk-react";
import CountUp from "react-countup";

const AuthButton = () => {
  const navigate = useNavigate();
  const [isTma, setIsTma] = useState(false);

  useEffect(() => {
    async function detectAndSetIsTMA() {
      const _isTMA = await isTMA();
      console.log("isTMA", _isTMA);
      setIsTma(_isTMA);
    }
    detectAndSetIsTMA();
  }, []);

  async function handleLogin() {
    await signIn({
      maxTimeToLive: BigInt(30 * 24 * 60 * 60 * 1000 * 1000 * 1000), // 30 days
      provider: new NFIDProvider({
        appName: "Bridge23",
        logoUrl,
      }),
    });

    // navigate("/onboarding");
    navigate("/intro");
  }

  return (
    <section className="flex flex-col items-center justify-between min-h-screen p-5 mt-4">
      <header className="flex flex-col items-center gap-5 md:gap-8 p-6 text-center flex-grow">
        <div className="flex items-center gap-2">
          <img src={iconUrl} alt="Logo" width={34} height={34} />
          <span className="text-2xl font-bold">Bridge 23</span>
        </div>
        <h1 className="text-lg md:text-xl">
          The first OnChain Expense Tracker allowing people to own their
          financial information and sell their data anonymously to merchants
          directly.
        </h1>
        {isTma && (
          <div className="mt-4 text-sm text-gray-500">
            You are using a Telegram Mini App. You can use the app to login
            instead.
          </div>
        )}
        <div className="text-center">
          <p className="text-lg">
            Data miner:{" "}
            <CountUp start={0} end={1101} duration={2.5} separator="," />
          </p>
          <p className="text-lg">
            Items tokenized:{" "}
            <CountUp start={0} end={811987} duration={2.5} separator="," />
          </p>
          <p className="text-lg">
            Trees saved:{" "}
            <CountUp start={0} end={811000} duration={2.5} separator="," />
          </p>
        </div>
        <Button onClick={() => handleLogin()} className="mt-4">
          Login / Register
        </Button>
      </header>
      <div className="mb-4">
        <img src={icpUrl} alt="ICP-Logo" width={150} height={150} />
      </div>
    </section>
  );
};

export default AuthButton;
