import { useEffect, useCallback } from "react";
import { backButton, useSignal } from "@telegram-apps/sdk-react";
import { useNavigate, useLocation } from "react-router-dom";

export function useBackButton(onClick?: () => void) {
  const navigate = useNavigate();
  const location = useLocation();
  const isVisible = useSignal(backButton.isVisible);

  const handleBackButtonClick = useCallback(() => {
    if (onClick) {
      onClick();
    } else {
      navigate(-1);
    }
  }, [onClick, navigate]);

  useEffect(() => {
    if (location.pathname !== "/") {
      backButton.show();

      return () => {
        backButton.hide();
      };
    } else {
      backButton.hide();
    }
  }, [location.pathname, handleBackButtonClick]);

  return isVisible;
}
