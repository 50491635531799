// atoms/telegramReceipts.ts
import { atom } from "recoil";
import { Transaction } from "@/types/entities";
import { getAPI } from "@/api";

// State atom for a list of telegram receipts
export const telegramReceiptsState = atom<Transaction[]>({
  key: "allTelegramReceiptsState",
  default: [],
});

const API = getAPI();

/**
 * Fetch all telegram receipts.
 * @return {Promise<Transaction[]>} List of all telegram receipts.
 */
export async function fetchAllTelegramReceipts(): Promise<Transaction[]> {
  try {
    return await API.telegramReceipts.fetchAllReceipts();
  } catch (error) {
    console.error("Failed to fetch all telegram receipts:", error);
    return [];
  }
}

/**
 * Update an existing telegram transaction and update the Recoil state.
 * @param {Transaction} data - The transaction data to update.
 * @param {string} transactionKey - The key of the transaction.
 * @param {Function} setTelegramReceipts - Function to update the telegram receipts state.
 * @return {Promise<Transaction>} The updated transaction data.
 */
export async function updateTelegramTransaction(
  data: Transaction,
  transactionKey: string,
  setTelegramReceipts: (
    callback: (transactions: Transaction[]) => Transaction[]
  ) => void
): Promise<Transaction> {
  try {
    const transaction = await API.telegramReceipts.update(transactionKey, data);

    setTelegramReceipts((prevTransactions: Transaction[]) =>
      prevTransactions.map((transaction) =>
        transaction.id === transactionKey
          ? { ...data, id: transactionKey }
          : transaction
      )
    );

    return transaction;
  } catch (error) {
    console.error("Failed to update telegram transaction:", error);
    throw error;
  }
}

/**
 * Delete a telegram transaction and update the Recoil state.
 * @param {string} transactionKey - The key of the transaction.
 * @param {Function} setTelegramReceipts - Function to update the telegram receipts state.
 * @return {Promise<{ success: boolean; message: string }>} The result of the deletion operation.
 */
export async function deleteTelegramTransaction(
  transactionKey: string,
  setTelegramReceipts: (
    callback: (transactions: Transaction[]) => Transaction[]
  ) => void
): Promise<{ success: boolean; message: string }> {
  try {
    const response = await API.telegramReceipts.delete(transactionKey);
    if (response.success) {
      console.log("Deleted telegram transaction with key:", transactionKey);
      setTelegramReceipts((prevTransactions: Transaction[]) =>
        prevTransactions.filter(
          (transaction) => transaction.id !== transactionKey
        )
      );
    }
    return response;
  } catch (error) {
    console.error("Failed to delete telegram transaction:", error);
    return {
      success: false,
      message: `Failed to delete telegram transaction with key ${transactionKey}.`,
    };
  }
}