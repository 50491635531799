import React from "react";
import { ArrowClockwise, CaretLeft } from "@phosphor-icons/react";
import { Button } from "@/components/ui/button";
import { authState } from "@/atoms/auth";
import { useRecoilState } from "recoil";
import { Profile } from "@/types/entities";
import { toast } from "@/components/ui/use-toast";
import { useNavigate } from "react-router-dom";
import { completeTask } from "@/api/supabase/tasks";
import { useProfileMutation } from "@/queries/profiles";
import { useProfileQuery } from "@/queries/profiles";

const ONBOARDING_TASK_ID = 2;

interface FinishMessageProps {
  onPrev?: () => void;
}

const FinishMessage: React.FC<FinishMessageProps> = ({ onPrev }) => {
  const [auth] = useRecoilState(authState);
  const { data: profile } = useProfileQuery();
  const profileMutation = useProfileMutation();

  const navigate = useNavigate();
  const [saving, setSaving] = React.useState(false);

  const updateOnboardingStatus = async (
    authKey: string,
    profile: Profile | null
  ) => {
    if (!profile) return;

    const updatedProfile: Profile = {
      ...profile,
      onboarding_completed: true,
    };

    try {
      await profileMutation.mutateAsync(updatedProfile);
      await completeTask(ONBOARDING_TASK_ID);
      toast({
        title: "Onboarding completed successfully 🎉",
      });
      localStorage.removeItem(`currentStep_${authKey}`);
    } catch (e) {
      console.error(e);
      toast({
        title: "Error completing onboarding 😢. Try again",
      });
    }
  };

  const handleFinish = async () => {
    setSaving(true);
    if (auth) {
      await updateOnboardingStatus(auth.key, profile);
      navigate("/");
    } else {
      toast({
        title: "Error completing onboarding 😢. Try again",
      });
    }
    setSaving(false);
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-gray-900 bg-opacity-75 backdrop-blur-sm">
      <div className="bg-gradient-to-br from-blue-500/20 to-purple-500/20 p-6 rounded-lg shadow-lg text-white w-96 mx-4">
        <h2 className="text-xl font-semibold mb-4">Congratulations!</h2>
        <p className="mb-6">Your profile has been setup successfully</p>
        <div className="flex justify-between">
          <Button
            onClick={onPrev}
            className="flex items-center bg-gray-700 px-4 py-2 rounded-md hover:bg-gray-600"
          >
            <CaretLeft />
            <span className="ml-2">Add Expense</span>
          </Button>
          <Button
            onClick={handleFinish}
            className="bg-blue-600 px-4 py-2 rounded-md hover:bg-blue-500"
            disabled={saving}
          >
            {saving ? (
              <>
                <ArrowClockwise className="mr-2 h-4 w-4 animate-spin" />
                Saving...
              </>
            ) : (
              <>Finish</>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FinishMessage;
