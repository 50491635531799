import logoUrl from "@/assets/LogoHorizont.gif";

export default function Loading() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-black">
      <img
        className="w-96 h-96"
        src={logoUrl}
        alt="Loading animation"
      />
    </div>
  );
}