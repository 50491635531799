import { atom } from "recoil";
import { Rewards } from "../types/entities.ts";
import { getPoints } from "../api/supabase/rewards-system.ts";

export const rewardsState = atom({
  key: "rewards",
  default: { total_points: 0, users_invited: 0 } as Rewards | null,
});

export async function fetchRewards(): Promise<Rewards | null> {
  const data = await getPoints();
  if (data.ok) {
    const json = await data.json();
    return json as Rewards;
  }

  throw new Error("Failed to fetch rewards");
}
