// utils.js
import { startOfWeek, startOfDay, startOfMonth } from "date-fns";
import { Transaction } from "@/types/entities";

export const filterTransactionsByPeriod = (transactions: Transaction[], period: string) => {
  const now = new Date();
  let startDate: Date;

  switch (period) {
    case "weekly":
      startDate = startOfWeek(now);
      break;
    case "today":
      startDate = startOfDay(now);
      break;
    case "monthly":
      startDate = startOfMonth(now);
      break;
    case "all":
    default:
      return transactions;
  }

  return transactions.filter(transaction => new Date(transaction.date) >= startDate);
};
