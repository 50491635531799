import {
  getDoc,
  setDoc,
  listDocs,
  deleteDoc,
  listAssets,
  deleteAsset,
  uploadFile,
} from "@junobuild/core";
import { IReceiptAPI } from "../types";
import { Receipt, ReceiptImage } from "../../types/entities";
import { nanoid } from "nanoid";
import { getSatteliteOptions } from "..";
import { resizeAndGrayscaleImage } from "@/components/upload/services/resizeAndGrayscaleImage";

// Constants
const RECEIPT_COLLECTION = "receipts";

export class JunoReceiptAPI implements IReceiptAPI {
  async fetchOne(receiptKey: string): Promise<Receipt | null> {
    try {
      const receipt = await getDoc<Receipt>({
        collection: RECEIPT_COLLECTION,
        key: receiptKey,
        satellite: getSatteliteOptions(),
      });

      return receipt ? { id: receipt.key, ...receipt.data } : null;
    } catch (error) {
      console.error(`Error fetching receipt with key ${receiptKey}:`, error);
      throw error;
    }
  }

  async fetchAll(): Promise<{
    receipts: Receipt[];
    receiptImages: ReceiptImage[];
  }> {
    try {
      const [receiptsResponse, receiptImagesResponse] = await Promise.all([
        listDocs<Receipt>({
          collection: RECEIPT_COLLECTION,
          satellite: getSatteliteOptions(),
        }),
        listAssets({
          collection: RECEIPT_COLLECTION,
          satellite: getSatteliteOptions(),
        }),
      ]);

      const receipts = receiptsResponse.items
        ? receiptsResponse.items.map((doc) => ({ id: doc.key, ...doc.data }))
        : [];
      const receiptImages = receiptImagesResponse.assets || [];

      return { receipts, receiptImages };
    } catch (error) {
      console.error("Error fetching all receipts and receipt images:", error);
      throw error;
    }
  }

  async create(data: Receipt, receiptKey?: string): Promise<Receipt> {
    const key = receiptKey || nanoid();
    try {
      await setDoc<Receipt>({
        collection: RECEIPT_COLLECTION,
        doc: {
          key: key,
          data: { ...data },
        },
        satellite: getSatteliteOptions(),
      });

      return { id: key, ...data };
    } catch (error) {
      console.error("Error creating receipt:", error);
      throw error;
    }
  }

  async createAsset(
    asset: File,
    docKey?: string
  ): Promise<{ success: boolean; message: string }> {
    const key = docKey || nanoid();
    const grayImage = await resizeAndGrayscaleImage(asset);
    const fileExtension = asset.type.split("/")[1];
    const fileWithUniqueName = new File(
      [grayImage],
      `${docKey}.${fileExtension}`,
      { type: asset.type }
    );

    try {
      const assetKey = await uploadFile({
        data: fileWithUniqueName,
        collection: "receipts",
        satellite: getSatteliteOptions(),
      });

      const downloadUrl = assetKey.downloadUrl;

      return {
        success: true,
        message: downloadUrl,
      };
    } catch (error) {
      console.error(`Error uploading asset with key ${key}:`, error);
      return {
        success: false,
        message: `Failed to upload asset with key ${key}.`,
      };
    }
  }

  async update(receiptKey: string, data: Receipt): Promise<Receipt> {
    try {
      const existingReceipt = await getDoc<Receipt>({
        collection: RECEIPT_COLLECTION,
        key: receiptKey,
        satellite: getSatteliteOptions(),
      });

      if (!existingReceipt) {
        throw new Error(`Receipt with key ${receiptKey} not found`);
      }

      await setDoc<Receipt>({
        collection: RECEIPT_COLLECTION,
        doc: {
          key: receiptKey,
          data,
          version: existingReceipt.version,
        },
        satellite: getSatteliteOptions(),
      });

      return { id: receiptKey, ...data };
    } catch (error) {
      console.error(`Error updating receipt with key ${receiptKey}:`, error);
      throw error;
    }
  }

  async delete(
    receiptKey: string,
    imageUrl: string,
    data: Receipt
  ): Promise<{ success: boolean; message: string }> {
    const existingReceipt = await getDoc<Receipt>({
      collection: RECEIPT_COLLECTION,
      key: receiptKey,
      satellite: getSatteliteOptions(),
    });
    try {
      // Ensure getSatteliteOptions includes necessary version info
      const satelliteOptions = getSatteliteOptions();
      const imagePath = imageUrl.replace(/^https?:\/\/[^/]+/, "");

      await deleteDoc({
        collection: RECEIPT_COLLECTION,
        doc: {
          key: receiptKey,
          data,
          version: existingReceipt?.version,
        },
        satellite: satelliteOptions,
      });

      await deleteAsset({
        collection: RECEIPT_COLLECTION,
        fullPath: imagePath,
        satellite: satelliteOptions,
      });

      return {
        success: true,
        message: `Receipt with key ${receiptKey} successfully deleted.`,
      };
    } catch (error) {
      console.error(`Error deleting receipt with key ${receiptKey}:`, error);
      return {
        success: false,
        message: `Failed to delete receipt with key ${receiptKey}.`,
      };
    }
  }

  async fetchReceiptImages(): Promise<ReceiptImage[]> {
    try {
      const response = await listAssets({
        collection: RECEIPT_COLLECTION,
        satellite: getSatteliteOptions(),
      });

      return response.assets;
    } catch (error) {
      console.error("Error fetching receipt images:", error);
      throw error;
    }
  }

  async deleteImage(
    imageKey: string
  ): Promise<{ success: boolean; message: string }> {
    try {
      await deleteAsset({
        collection: RECEIPT_COLLECTION,
        fullPath: imageKey,
        satellite: getSatteliteOptions(),
      });
      return {
        success: true,
        message: `Image with key ${imageKey} successfully deleted.`,
      };
    } catch (error) {
      console.error(`Error deleting image with key ${imageKey}:`, error);
      return {
        success: false,
        message: `Failed to delete image with key ${imageKey}.`,
      };
    }
  }
}
