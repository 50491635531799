"use client";

import React, { useState, useCallback } from "react";
import Picker, { EmojiClickData, EmojiStyle, Theme } from "emoji-picker-react";
import { useRecoilState } from "recoil";
import { categoriesState, createOrUpdateCategory } from "@/atoms/category";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import "./AddCategory.css";

interface AddCategoryProps {
  onCategoryAdded?: (category: {
    name: string;
    icon: string;
    type: "income" | "expense";
  }) => void;
  type: "income" | "expense";
}

const AddCategory: React.FC<AddCategoryProps> = ({ onCategoryAdded, type }) => {
  const [categoryName, setCategoryName] = useState<string>("");
  const [emoji, setEmoji] = useState<string>("");
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [, setCategories] = useRecoilState(categoriesState);
  const maxCategoryLength = 30;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleCategoryNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value.length <= maxCategoryLength) {
      setCategoryName(event.target.value);
    }
  };

  const onEmojiClick = useCallback((emojiObject: EmojiClickData) => {
    setEmoji(emojiObject.emoji);
    setShowPicker(false);
  }, []);

  useCallback((value: string) => {
    setCategoryName(value);
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const newCategory = { name: categoryName, icon: emoji, type };
      await createOrUpdateCategory(newCategory, setCategories);
      if (onCategoryAdded) {
        onCategoryAdded(newCategory);
      }
      setCategoryName("");
      setEmoji("");
      setIsDialogOpen(false);
      setLoading(false);
    } catch (error) {
      setError("Error creating or updating category");
      console.error("Error creating or updating category:", error);
      setLoading(false);
    }
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogTrigger asChild>
        <Button variant="default" className="ml-1">
          Add
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add New Category</DialogTitle>
          <DialogDescription>
            Please fill out the form below to add a new category.
          </DialogDescription>
        </DialogHeader>
        <div>
          <form onSubmit={handleSubmit}>
            <div className="mb-2">
              <label className="block mb-1">Category Name:</label>
              <input
                type="text"
                value={categoryName}
                onChange={handleCategoryNameChange}
                className="w-full px-3 py-2 border rounded-md"
              />
              <p className="text-sm text-gray-500">
                {categoryName.length}/{maxCategoryLength} characters
              </p>
            </div>
            <div className="mb-4">
              <label className="block mb-2">Select Icon:</label>
              <Button
                type="button"
                variant="secondary"
                className="w-full"
                onClick={() => setShowPicker(!showPicker)}
              >
                {emoji ? `Selected Icon: ${emoji}` : "Select Icon"}
              </Button>
              {showPicker && (
                <div className="relative mt-2 emoji-picker-wrapper">
                  <div className="emoji-picker-center">
                    <Picker
                      onEmojiClick={onEmojiClick}
                      theme={Theme.AUTO}
                      emojiStyle={EmojiStyle.NATIVE}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Category Type:</label>
              <select
                value={type}
                className="w-full px-3 py-2 border rounded-md"
                disabled
              >
                <option value="income">Income</option>
                <option value="expense">Expense</option>
              </select>
            </div>
            <div className="my-4">
              <h3 className="font-semibold">Current Category</h3>
              <p className="text-gray-300">
                {emoji} {categoryName}
              </p>
            </div>
            {error && <p className="text-red-500">{error}</p>}
            <Button
              type="submit"
              variant="default"
              className="w-full"
              disabled={loading}
            >
              {loading ? (
                <div className="flex items-center justify-center">
                  <svg
                    className="animate-spin h-5 w-5 mr-3"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C6.48 0 2 4.48 2 10h2zm2 5.29A7.962 7.962 0 014 12H0c0 2.53.99 4.84 2.61 6.47l1.39-1.18z"
                    ></path>
                  </svg>
                  Loading...
                </div>
              ) : (
                "Add Category"
              )}
            </Button>
          </form>
          <Button id="close-dialog-button" variant="ghost" className="hidden">
            Close
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddCategory;
