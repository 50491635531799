import React from "react";
import OnboardingTransactionsForm from "./OnboardingTransactionsForm";

const IncomeListPage: React.FC<{ onNext: () => void; onPrev: () => void }> = ({
  onNext,
  onPrev,
}) => {
  return (
    <OnboardingTransactionsForm
      onNext={onNext}
      onPrev={onPrev}
      transactionType="income"
    />
  );
};

export default IncomeListPage;
