import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatCurrency(amount: number, rawCurrency?: string) {
  const currency = rawCurrency
    ? rawCurrency === "RP"
      ? "IDR"
      : rawCurrency
    : "USD"; // Default to 'USD' if no currency is passed

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  }).format(amount);
}

export function formatNumber(amount: number) {
  return new Intl.NumberFormat("en-US").format(amount);
}

export function formatDate(dateString: string) {
  const date = new Date(dateString);

  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}
