import React from "react";
import { CaretLeft } from "@phosphor-icons/react";
import { Button } from "@/components/ui/button";

interface AddFirstExpenseMessageProps {
  onNext?: () => void;
  onPrev?: () => void;
}

const AddFirstExpenseMessage: React.FC<AddFirstExpenseMessageProps> = ({
  onNext,
  onPrev,
}) => {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-gray-900 bg-opacity-75 backdrop-blur-sm">
      <div className="bg-gradient-to-br from-blue-500/20 to-purple-500/20 p-6 rounded-lg shadow-lg text-white w-96 mx-4">
        <h2 className="text-xl font-semibold mb-4">Great Job!</h2>
        <p className="mb-6">
          Lets continue by adding your first recurring expense. It can be your
          rent, gym membership, streaming service, anything you spend on weekly
          or monthly basis.{" "}
        </p>
        <div className="flex justify-between">
          <Button
            onClick={onPrev}
            className="flex items-center bg-gray-700 px-4 py-2 rounded-md hover:bg-gray-600"
          >
            <CaretLeft />
            <span className="ml-2">Add Income</span>
          </Button>
          <Button
            onClick={onNext}
            className="bg-blue-600 px-4 py-2 rounded-md hover:bg-blue-500"
          >
            Add First Expense
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddFirstExpenseMessage;
