// hooks/useLoadCategories.ts
import { categoriesState, fetchAllCategories } from "@/atoms/category";
import {
  defaultExpenseCategories,
  defaultIncomeCategories,
} from "@/components/data/categories";
import { useEffect } from "react";
import { useRecoilStateLoadable } from "recoil";

type DefaultCategory = {
  name: string;
  icon: string;
  type: "income" | "expense";
};

export const useLoadCategories = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [categories, setCategories] = useRecoilStateLoadable(categoriesState);

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const fetchedCategories = await fetchAllCategories();

        const incomeCategories: DefaultCategory[] = defaultIncomeCategories.map(
          (category) => ({
            name: category.name,
            icon: category.icon,
            type: "income",
          })
        );

        const expenseCategories: DefaultCategory[] =
          defaultExpenseCategories.map((category) => ({
            name: category.name,
            icon: category.icon,
            type: "expense",
          }));

        const allDefaultCategories: DefaultCategory[] = [
          ...incomeCategories,
          ...expenseCategories,
        ];

        const combinedCategories = [
          ...allDefaultCategories,
          ...fetchedCategories,
        ];

        setCategories(combinedCategories);
      } catch (error) {
        console.error("Error loading categories:", error);
      }
    };

    loadCategories();
  }, [setCategories]);
};
