import React, { useMemo, useState } from "react";
import { Transaction } from "@/types/entities";
import { parse, isToday, isYesterday, format, startOfWeek, endOfWeek, isThisYear, isValid } from "date-fns";
import { formatCurrency } from "@/lib/utils";
import EditReceiptModal from "./EditReceiptModal";
import { useTelegramReceiptsData } from "@/hooks/useTelegramReceiptsData";
import { useRecoilState } from "recoil";
// import { deleteReceipt, receiptsState } from '@/atoms/receipt'; 
import { deleteTelegramReceipt, telegramReceiptsDataState } from '@/atoms/telegram_receipts_data';
import { deleteTelegramTransaction, telegramReceiptsState } from "@/atoms/telegram_receipts";

type TelegramBotTransactionListProps = {
  transactions: Transaction[];
  selectedCurrency?: string;
  getExchangeRateToUSD?: (currency: string) => number;
  onVerify?: (receiptId: string) => void;
  loadingReceiptId?: string | null;
};

const parseDate = (dateString: string): Date => {
  const formats = [
    "yyyy-MM-dd hh:mm a",
    "yyyy-MM-dd HH:mm",
    "yyyy-MM-dd'T'HH:mm:ss.SSSXXX",
    "yyyy-MM-dd'T'HH:mm:ss",
    "yyyy-MM-dd HH:mm:ss",
    "yyyy-MM-dd'T'HH:mm:ssXXX",
    "yyyy-MM-dd",
    "MM/dd/yyyy HH:mm"
  ];

  let parsedDate: Date | null = null;

  for (const formatString of formats) {
    const date = parse(dateString, formatString, new Date());
    if (isValid(date)) {
      parsedDate = date;
      break;
    }
  }

  if (!parsedDate) {
    console.error(`Invalid date: ${dateString}`);
    parsedDate = new Date(0);
  }

  return parsedDate;
};

const customFormatDate = (dateString: string) => {
  const date = parseDate(dateString);

  if (isToday(date)) {
    return { label: "Today", date };
  } else if (isYesterday(date)) {
    return { label: "Yesterday", date };
  } else if (date >= startOfWeek(new Date()) && date <= endOfWeek(new Date())) {
    return { label: format(date, "EEEE, MMMM d"), date };
  } else if (isThisYear(date)) {
    return { label: format(date, "EEEE, MMMM d"), date };
  } else {
    return { label: format(date, "EEEE, MMMM d, yyyy"), date };
  }
};

const groupTransactionsByDate = (
  transactions: Transaction[],
  getExchangeRateToUSD?: (currency: string) => number
) => {
  return transactions.reduce(
    (acc, transaction) => {
      const { label, date } = customFormatDate(transaction.date);
      if (!acc[label]) {
        acc[label] = { transactions: [], date, totalAmountUSD: 0 };
      }
      let amountInUSD = transaction.amount_cents / 100;
      if (getExchangeRateToUSD) {
        const exchangeRate = getExchangeRateToUSD(
          transaction.amount_currency?.toUpperCase() || "USD"
        );
        amountInUSD = Math.abs(amountInUSD) * exchangeRate;
      }
      acc[label].transactions.push(transaction);
      acc[label].totalAmountUSD +=
        transaction.transaction_type === "income" ? amountInUSD : -amountInUSD;
      return acc;
    },
    {} as Record<
      string,
      { transactions: Transaction[]; date: Date; totalAmountUSD: number }
    >
  );
};

const TelegramBotTransactionList: React.FC<TelegramBotTransactionListProps> = ({
  transactions,
  selectedCurrency,
  getExchangeRateToUSD,
  onVerify,
  loadingReceiptId,
}) => {
  const [selectedTransaction, setSelectedTransaction] = useState<Transaction | null>(null);
  const [, setReceipts] = useRecoilState(telegramReceiptsDataState);
  const [, setTransactions] = useRecoilState(telegramReceiptsState); 
  const receiptsData = useTelegramReceiptsData();

  const groupedTransactions = useMemo(() => {
    const result = groupTransactionsByDate(transactions, getExchangeRateToUSD);
    if (getExchangeRateToUSD && selectedCurrency) {
      const exchangeRate = getExchangeRateToUSD(selectedCurrency);
      Object.keys(result).forEach((key) => {
        result[key].totalAmountUSD = result[key].totalAmountUSD / exchangeRate;
      });
    }
    return result;
  }, [transactions, getExchangeRateToUSD, selectedCurrency]);

  const sortedDates = Object.keys(groupedTransactions).sort((a, b) => {
    return (
      groupedTransactions[b].date.getTime() -
      groupedTransactions[a].date.getTime()
    );
  });

  const handleSaveAndVerify = (transaction: Transaction) => {
    if (onVerify && transaction.receipt_id) {
      onVerify(transaction.receipt_id);
    }
    setSelectedTransaction(null);
  };

  const handleDelete = async (receiptId: string) => {
    try {
      const receipt = receiptsData.find(r => r.id === receiptId);
      
      if (!receipt) {
        console.error("Receipt not found");
        return;
      }
      
      // Call the delete function with an empty string for imageUrl
      const response = await deleteTelegramReceipt(receiptId, setReceipts);
  
      if (response.success) {
        // Delete the associated transaction
        const transaction = transactions.find(t => t.receipt_id === receiptId);
        if (transaction && transaction.id) {
          await deleteTelegramTransaction(transaction.id, setTransactions);
          console.log("Transaction deleted successfully");
        } else {
          console.warn("Associated transaction not found");
        }
      } else {
        console.error("Failed to delete receipt:", response.message);
      }
    } catch (error) {
      console.error("Error deleting receipt:", error);
    }
  };

  return (
    <div className="mt-4 w-full">
      {transactions.length > 0 ? (
        <ul>
          {sortedDates.map((label) => (
            <li key={label} className="mb-8">
              <div className="font-bold text-foreground/80 mb-2 flex justify-between">
                <div>{label}</div>
                {selectedCurrency && getExchangeRateToUSD && (
                  <div className="font-light">
                    {groupedTransactions[label].totalAmountUSD < 0 && "-"}
                    {formatCurrency(
                      Math.abs(groupedTransactions[label].totalAmountUSD),
                      selectedCurrency
                    )}
                  </div>
                )}
              </div>
              <div className="border-b border-border"></div>
              {groupedTransactions[label].transactions.map((transaction) => (
                <div key={transaction.id} className="flex justify-between items-center">
                  <div
                    className="cursor-pointer mt-4"
                    onClick={() => setSelectedTransaction(transaction)}
                  >
                    <h1 className="font-bold">
                      {transaction.name}
                      <span className="font-normal text-red-500 ml-2">
                        (${(transaction.amount_cents / 100).toFixed(2)})
                      </span>
                    </h1>
                  </div>
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
                    onClick={() => onVerify && transaction.receipt_id && onVerify(transaction.receipt_id)}
                    disabled={loadingReceiptId === transaction.receipt_id}
                  >
                    {loadingReceiptId === transaction.receipt_id ? "Verifying..." : "Verify"}
                  </button>
                </div>
              ))}
            </li>
          ))}
        </ul>
      ) : (
        <div className="text-center">No transactions found</div>
      )}

      {selectedTransaction && (
        <EditReceiptModal
          transaction={selectedTransaction}
          onClose={() => setSelectedTransaction(null)}
          onSaveAndVerify={handleSaveAndVerify}
          onDelete={() => handleDelete(selectedTransaction.receipt_id ?? '')}
        />
      )}
    </div>
  );
};

export default TelegramBotTransactionList;