import React, { useMemo } from "react";
import { Transaction } from "@/types/entities";
import TransactionsListItem from "./TransactionsListItem";
import {
  parse,
  isToday,
  isYesterday,
  format,
  startOfWeek,
  endOfWeek,
  isThisYear,
  isValid,
} from "date-fns";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { categoriesState } from "@/atoms/category";
import { formatCurrency } from "@/lib/utils";

type TransactionsListProps = {
  transactions: Transaction[];
  selectedCurrency?: string;
  getExchangeRateToUSD?: (currency: string) => number;
  onVerify?: (receiptId: string) => void;
  loadingReceiptId?: string | null; // New prop for loading state
};

const parseDate = (dateString: string): Date => {
  const formats = [
    "yyyy-MM-dd hh:mm a",
    "yyyy-MM-dd HH:mm",
    "yyyy-MM-dd'T'HH:mm:ss.SSSXXX",
    "yyyy-MM-dd'T'HH:mm:ss",
    "yyyy-MM-dd HH:mm:ss",
    "yyyy-MM-dd'T'HH:mm:ssXXX",
    "yyyy-MM-dd",
    "MM/dd/yyyy HH:mm"
  ];

  let parsedDate: Date | null = null;

  for (const formatString of formats) {
    const date = parse(dateString, formatString, new Date());
    if (isValid(date)) {
      parsedDate = date;
      break;
    }
  }

  if (!parsedDate) {
    console.error(`Invalid date: ${dateString}`);
    parsedDate = new Date(0);
  }

  return parsedDate;
};

const customFormatDate = (dateString: string) => {
  const date = parseDate(dateString);

  if (isToday(date)) {
    return { label: "Today", date };
  } else if (isYesterday(date)) {
    return { label: "Yesterday", date };
  } else if (date >= startOfWeek(new Date()) && date <= endOfWeek(new Date())) {
    return { label: format(date, "EEEE, MMMM d"), date };
  } else if (isThisYear(date)) {
    return { label: format(date, "EEEE, MMMM d"), date };
  } else {
    return { label: format(date, "EEEE, MMMM d, yyyy"), date };
  }
};

const groupTransactionsByDate = (
  transactions: Transaction[],
  getExchangeRateToUSD?: (currency: string) => number
) => {
  return transactions.reduce(
    (acc, transaction) => {
      const { label, date } = customFormatDate(transaction.date);
      if (!acc[label]) {
        acc[label] = { transactions: [], date, totalAmountUSD: 0 };
      }
      let amountInUSD = transaction.amount_cents / 100;
      if (getExchangeRateToUSD) {
        const exchangeRate = getExchangeRateToUSD(
          transaction.amount_currency?.toUpperCase() || "USD"
        );
        amountInUSD = Math.abs(amountInUSD) * exchangeRate;
      }
      acc[label].transactions.push(transaction);
      acc[label].totalAmountUSD +=
        transaction.transaction_type === "income" ? amountInUSD : -amountInUSD;
      return acc;
    },
    {} as Record<
      string,
      { transactions: Transaction[]; date: Date; totalAmountUSD: number }
    >
  );
};

const TransactionsList: React.FC<TransactionsListProps> = ({
  transactions,
  selectedCurrency,
  getExchangeRateToUSD,
  onVerify,
  loadingReceiptId, // Accept the loadingReceiptId prop
}) => {
  const location = useLocation();
  const transactionId = new URLSearchParams(location.search).get("id");
  const categories = useRecoilValue(categoriesState);

  const lastAddedTransaction = transactionId
    ? transactions.find((t) => t.id === transactionId)
    : null;

  const groupedTransactions = useMemo(() => {
    const result = groupTransactionsByDate(transactions, getExchangeRateToUSD);
    if (getExchangeRateToUSD && selectedCurrency) {
      const exchangeRate = getExchangeRateToUSD(selectedCurrency);
      Object.keys(result).forEach((key) => {
        result[key].totalAmountUSD = result[key].totalAmountUSD / exchangeRate;
      });
    }
    return result;
  }, [transactions, getExchangeRateToUSD, selectedCurrency]);

  const sortedDates = Object.keys(groupedTransactions).sort((a, b) => {
    return (
      groupedTransactions[b].date.getTime() -
      groupedTransactions[a].date.getTime()
    );
  });

  return (
    <div className="mt-4 w-full">
      {transactions.length > 0 ? (
        <ul>
          {lastAddedTransaction && (
            <li key="highlight" className="mb-8">
              <div className="font-bold text-foreground/80 mb-2">
                Last added transaction
              </div>
              <div className="border-b border-border"></div>
              <div key={lastAddedTransaction.id}>
                <TransactionsListItem
                  transaction={lastAddedTransaction}
                  category={
                    categories.find(
                      (cat) => cat.name === lastAddedTransaction.category
                    ) || {
                      name: lastAddedTransaction.transaction_type,
                      icon: "🎲",
                      type: lastAddedTransaction.transaction_type,
                    }
                  }
                  currency={
                    lastAddedTransaction.amount_currency?.toUpperCase() || "USD"
                  }
                  transactionKey={"transaction-" + lastAddedTransaction.id}
                  onVerify={onVerify}
                  loadingReceiptId={loadingReceiptId} // Pass the loading state
                />
              </div>
            </li>
          )}
          {sortedDates.map((label) => (
            <li key={label} className="mb-8">
              <div className="font-bold text-foreground/80 mb-2 flex justify-between">
                <div>{label}</div>
                {selectedCurrency && getExchangeRateToUSD && (
                  <div className="font-light">
                    {groupedTransactions[label].totalAmountUSD < 0 && "-"}
                    {formatCurrency(
                      Math.abs(groupedTransactions[label].totalAmountUSD),
                      selectedCurrency
                    )}
                  </div>
                )}
              </div>
              <div className="border-b border-border"></div>
              {groupedTransactions[label].transactions
                .filter(
                  (transaction) =>
                    !lastAddedTransaction ||
                    transaction.id !== lastAddedTransaction.id
                )
                .map((transaction) => (
                  <div key={transaction.id}>
                    <TransactionsListItem
                      transaction={transaction}
                      category={
                        categories.find(
                          (cat) => cat.name === transaction.category
                        ) || {
                          name: transaction.transaction_type,
                          icon: "🎲",
                          type: transaction.transaction_type,
                        }
                      }
                      currency={
                        transaction.amount_currency?.toUpperCase() || "USD"
                      }
                      transactionKey={"transaction-" + transaction.id}
                      onVerify={onVerify}
                      loadingReceiptId={loadingReceiptId} // Pass the loading state
                    />
                  </div>
                ))}
            </li>
          ))}
        </ul>
      ) : (
        <div className="text-center">No transactions found</div>
      )}
    </div>
  );
};

export default TransactionsList;