// atoms/category.ts
import { atom } from "recoil";
import { Category } from "@/types/entities";
import { getAPI } from "@/api";

// State atom for a single category
export const categoryState = atom<Category | null>({
  key: "singleCategoryState",
  default: null,
});

// State atom for a list of categories
export const categoriesState = atom<Category[]>({
  key: "allCategoriesState",
  default: [],
});


export async function fetchCategory(
  categoryKey: string
): Promise<Category | null> {
  const API = getAPI();
  try {
    return await API.category.fetchOne(categoryKey);
  } catch (error) {
    console.error("Failed to fetch category:", error);
    return null;
  }
}

/**
 * Create a new category or update the existing one and update the Recoil state.
 * @param {Category} data - The category data to set.
 * @param {Function} setCategories - Function to update the categories state.
 * @return {Promise<Category>} The category data that was set or updated.
 */
export async function createOrUpdateCategory(
  data: Category,
  setCategories: (callback: (categories: Category[]) => Category[]) => void
): Promise<Category> {
  const API = getAPI();
  try {
    const category = await API.category.createOrUpdate(data);

    setCategories((prevCategories: Category[]) => {
      if (prevCategories.find((c) => c.id === data.id)) {
        return prevCategories.map((c) => (c.id === data.id ? category : c));
      } else {
        return [...prevCategories, category];
      }
    });

    return category;
  } catch (error) {
    console.error("Failed to create or update category:", error);
    throw error;
  }
}

/**
 * Fetch all categories.
 * @return {Promise<Category[]>} List of all categories.
 */
export async function fetchAllCategories(): Promise<Category[]> {
  const API = getAPI();
  try {
    return API.category.fetchAll();
  } catch (error) {
    console.error("Failed to fetch all categories:", error);
    return [];
  }
}