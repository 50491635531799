import { atom } from "recoil";

interface MintingState {
  [receiptId: string]: {
    isLoading: boolean;
    error: string | null;
  };
}

export const mintingState = atom<MintingState>({
  key: "mintingState",
  default: {},
});

const getInitialEvnAddressState = () => {
  const savedState = localStorage.getItem("evnAddressState");
  return savedState
    ? JSON.parse(savedState)
    : { evnAddress: null, profileId: null };
};

export const evnAddressState = atom({
  key: "evnAddressState",
  default: getInitialEvnAddressState(),
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newValue) => {
        if (newValue.evnAddress === null) {
          localStorage.removeItem("evnAddressState");
        } else {
          localStorage.setItem("evnAddressState", JSON.stringify(newValue));
        }
      });
    },
  ],
});
