import React from "react";
import OnboardingTransactionsForm from "./OnboardingTransactionsForm";

const ExpenseListPage: React.FC<{ onNext: () => void; onPrev: () => void }> = ({ onNext, onPrev }) => {
  return (
    <OnboardingTransactionsForm onNext={onNext} onPrev={onPrev} transactionType="expense" />
  );
};

export default ExpenseListPage;
