export default function authenticateTelegram(
  initDataRaw: string,
  principalId: string
) {
  return fetch(
    "https://sgpvdvssamjteabckoft.supabase.co/functions/v1/telegram-auth",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // @ts-ignore
        "X-Bridge23-Env": import.meta.env.PROD ? "production" : "local",
      },
      body: JSON.stringify({
        initDataRaw,
        principalId,
      }),
    }
  );
}
