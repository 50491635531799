import { Transaction } from "@/types/entities";
import { _getHeaders, URL_BASE } from "./shared";

export async function getTransactions() {
  const response = await fetch(`${URL_BASE}/transactions`, {
    method: "GET",
    headers: _getHeaders(),
  });

  if (!response.ok) {
    throw new Error("Failed to fetch transactions");
  }

  return response.json();
}

export async function getTransaction(transactionId: string) {
  const response = await fetch(`${URL_BASE}/transactions/${transactionId}`, {
    method: "GET",
    headers: _getHeaders(),
  });

  if (!response.ok) {
    throw new Error("Failed to fetch transaction");
  }

  return response.json();
}

export async function createTransaction(transaction: Transaction, transactionKey?: string) {
  const data = transactionKey ? { id: transactionKey, ...transaction } : transaction;
  const response = await fetch(`${URL_BASE}/transactions`, {
    method: "POST",
    headers: _getHeaders(),
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error("Failed to create transaction");
  }

  return response.json();
}

export async function updateTransaction(transactionId: string, transaction: Transaction) {
  const response = await fetch(`${URL_BASE}/transactions/${transactionId}`, {
    method: "PUT",
    headers: _getHeaders(),
    body: JSON.stringify(transaction),
  });

  if (!response.ok) {
    throw new Error("Failed to update transaction");
  }

  return response.json();
}

export async function deleteTransaction(transactionId: string): Promise<{ success: boolean; message: string }> {
  const response = await fetch(`${URL_BASE}/transactions/${transactionId}`, {
    method: "DELETE",
    headers: _getHeaders(),
  });

  if (!response.ok) {
    return { success: false, message: "Failed to delete transaction" };
  }

  return { success: true, message: "Transaction deleted successfully" };
}


