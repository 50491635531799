import { currenciesState, fetchCurrencies } from "@/atoms/exchangeRate";
import { useProfileQuery } from "@/queries/profiles";
import { useEffect } from "react";
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";

export const useCurrencies = () => {
  const setCurrencies = useSetRecoilState(currenciesState);
  const currencies = useRecoilValue(currenciesState);
  const currenciesLoadable = useRecoilValueLoadable(fetchCurrencies);

  useEffect(() => {
    if (currenciesLoadable.state === "hasValue") {
      setCurrencies(currenciesLoadable.contents);
    }
  }, [setCurrencies, currenciesLoadable]);

  return currencies;
};

export const useProfile = () => {
  const { data: profile } = useProfileQuery();
  return profile;
};
