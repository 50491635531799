import { useState, useEffect } from 'react';
import { isTMA } from "@telegram-apps/sdk";

export async function checkIsTMA() {
  try {
    const _isTMA = await isTMA();
    console.log("isTMA", _isTMA);
    return _isTMA;
  } catch (error) {
    console.error("Error checking isTMA:", error);
    return false;
  }
}

export function useIsTMA() {
  const [isTma, setIsTma] = useState(false);

  useEffect(() => {
    async function detectAndSetIsTMA() {
      const result = await checkIsTMA();
      setIsTma(result);
    }

    detectAndSetIsTMA();
  }, []);

  return { isTma };
}