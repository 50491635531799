// components/ExchangeRate.tsx
import React from "react";
import PageWrapper from "@/components/layout/PageWrapper";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useEffect } from "react";
import { useRecoilState, useRecoilValueLoadable } from "recoil";
import { currenciesState, fetchCurrencies } from "@/atoms/exchangeRate";
import Loading from "@/components/upload/UploadLoading";

const useCurrencies = () => {
  const [currencies, setCurrencies] = useRecoilState(currenciesState);
  const currenciesLoadable = useRecoilValueLoadable(fetchCurrencies);

  useEffect(() => {
    if (currenciesLoadable.state === "hasValue") {
      setCurrencies(currenciesLoadable.contents);
    }
  }, [currenciesLoadable, setCurrencies]);

  return currencies;
};

const ExchangeRate: React.FC = () => {
  const currencies = useCurrencies();

  return (
    <PageWrapper>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-2xl font-semibold mb-6">Currencies</h1>
        {currencies && currencies.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {currencies[0].map((currency, index) => (
              <Card key={index} className="shadow-md rounded-lg">
                <CardHeader>
                  <CardTitle>
                    {currency.name} ({currency.symbol})
                  </CardTitle>
                  {currency.description && (
                    <CardDescription>
                      {currency.description.join(", ")}
                    </CardDescription>
                  )}
                </CardHeader>
                <CardContent>
                  <p className="">Value: {currency.value}</p>
                  <p className="">
                    Type: {Object.keys(currency.currency_type)[0]}
                  </p>
                  {currency.source && (
                    <p className="">Source: {currency.source.join(", ")}</p>
                  )}
                  {/* <p className="">Created At: {new Date(Number(currency.created_at / 1000000n)).toLocaleString()}</p> */}
                  <p className="">Value String: {currency.value_str}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        ) : (
          // <p className="">Loading...</p>
          <Loading statusText={"Loading..."} />
        )}
      </div>
    </PageWrapper>
  );
};

export default ExchangeRate;
