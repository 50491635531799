import { Currency } from "@/types/entities";
import { useCallback } from "react";

export const useExchangeRateToUSD = (currencies: Currency[][]) => {
  return useCallback(
    (currency: string): number => {
      if (currency.toUpperCase() === "USD") return 1;
      if (currency.toUpperCase() === "RP") currency = "IDR";
      const currencyData = currencies
        .flat()
        .find((curr) => curr.name.toUpperCase() === currency.toUpperCase());
      return currencyData ? currencyData.value : 1;
    },
    [currencies]
  );
};
