// categories.ts

export const defaultIncomeCategories = [
  { type: 'income', name: "Salary", icon: "💰" },
  { type: 'income', name: "Freelance", icon: "🧑‍💻" },
  { type: 'income', name: "Investment", icon: "📈" },
  { type: 'income', name: "Gift", icon: "🎁" },
  { type: 'income', name: "Rental Income", icon: "🏡" },
  { type: 'income', name: "Bonus", icon: "🎉" },
];

export const defaultExpenseCategories = [
  { type: 'expense', name: "Food", icon: "🍔" },
  { type: 'expense', name: "Rent", icon: "🏠" },
  { type: 'expense', name: "Entertainment", icon: "🎬" },
  { type: 'expense', name: "Utilities", icon: "💡" },
  { type: 'expense', name: "Transport", icon: "🚗" },
  { type: 'expense', name: "Shopping", icon: "🛍️" },
  { type: 'expense', name: "Health", icon: "💊" },
  { type: 'expense', name: "Education", icon: "📚" },
  { type: 'expense', name: "Travel", icon: "✈️" },
];
