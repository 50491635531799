import React, { useState, useMemo } from "react";
import PageWrapper from "@/components/layout/PageWrapper";
import TelegramBotTransactionList from "@/components/transactions_list/TelegramBotTransactionList"; // Import the new component
import { useTelegramReceipts } from "@/hooks/useTelegramReceipts";
import { useTransactions } from "@/hooks/useTransactions";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { currenciesState } from "@/atoms/exchangeRate";
import { useExchangeRateToUSD } from "@/hooks/useExchangeRateToUSD";
import { createTransaction, transactionsState } from "@/atoms/transaction";
import { useToast } from "@/components/ui/use-toast";
import { Transaction } from "@/types/entities";
import { useProfileQuery } from "@/queries/profiles";

const VerifyReceipts: React.FC = () => {
  const telegramReceipts = useTelegramReceipts();
  const transactions = useTransactions();
  const currencies = useRecoilValue(currenciesState);
  const { data: profile } = useProfileQuery();
  const setTransactions = useSetRecoilState(transactionsState);
  const { toast } = useToast();

  const [selectedCurrency, setSelectedCurrency] = useState<string>(
    profile?.currency?.toUpperCase() || "USD"
  );
  const getExchangeRateToUSD = useExchangeRateToUSD(currencies || []);

  const [verifiedReceipts, setVerifiedReceipts] = useState<Set<string>>(
    new Set()
  );
  const [loadingReceiptId, setLoadingReceiptId] = useState<string | null>(null);

  const handleVerify = async (receiptId: string) => {
    setLoadingReceiptId(receiptId);
    try {
      const receipt = telegramReceipts.find((r) => r.id === receiptId);
      if (!receipt) {
        console.error("Receipt not found");
        return;
      }

      const transaction: Transaction = {
        date: receipt.date,
        name: receipt.name,
        transaction_type: receipt.transaction_type,
        is_recurring: receipt.is_recurring,
        amount_cents: receipt.amount_cents,
        amount_currency: receipt.amount_currency,
        category: receipt.category,
        receipt_id: receipt.id,
        profile_id: receipt.profile_id,
        offset: receipt.offset,
      };

      await createTransaction(transaction, setTransactions, receipt.id);

      setVerifiedReceipts((prev) => new Set(prev).add(receiptId));

      toast({
        title: "Verification successful!",
        description: "The receipt has been verified and uploaded successfully.",
      });
    } catch (error) {
      console.error("Failed to verify and upload the receipt", error);
      toast({
        title: "Verification failed",
        description:
          "There was an error verifying the receipt. Please try again.",
      });
    } finally {
      setLoadingReceiptId(null);
    }
  };

  const verifiedReceiptIds = useMemo(() => {
    return new Set(transactions.map((transaction) => transaction.receipt_id));
  }, [transactions]);

  const principalId = profile?.id;
  const filteredReceipts = useMemo(() => {
    return telegramReceipts
      .filter(
        (receipt) =>
          receipt.profile_id === principalId &&
          !verifiedReceiptIds.has(receipt.id)
      )
      .map((receipt) => ({
        ...receipt,
        isVerified: verifiedReceipts.has(receipt.id || ""),
      }));
  }, [telegramReceipts, verifiedReceipts, principalId, verifiedReceiptIds]);

  return (
    <PageWrapper>
      <div className="text-white flex flex-col items-start justify-center">
        <div className="flex flex-col items-center w-full max-w-3xl px-4 md:px-10 mx-auto">
          <h1 className="text-2xl font-bold mb-4">Verify Receipts</h1>
          <div className="w-full">
            <TelegramBotTransactionList
              transactions={filteredReceipts}
              selectedCurrency={selectedCurrency}
              getExchangeRateToUSD={getExchangeRateToUSD}
              onVerify={handleVerify}
              loadingReceiptId={loadingReceiptId}
            />
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default VerifyReceipts;
