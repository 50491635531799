import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Button } from "@/components/ui/button"

export function ChatSettingsCard() {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Chat Settings</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div>
          <label className="block mb-2">How agent replies to text messages:</label>
          <Select>
            <SelectTrigger>
              <SelectValue placeholder="Select option" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="text">Text</SelectItem>
              <SelectItem value="voice-text">Voice and Text</SelectItem>
              <SelectItem value="circle-text">Circle and Text</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div>
          <label className="block mb-2">How agent replies to voice memos:</label>
          <Select>
            <SelectTrigger>
              <SelectValue placeholder="Select option" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="voice-text">Voice and Text</SelectItem>
              <SelectItem value="only-voice">Only Voice</SelectItem>
              <SelectItem value="circle-video-text">Circle Video and Text</SelectItem>
              <SelectItem value="only-text">Only Text</SelectItem>
              <SelectItem value="none">None</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div>
          <label className="block mb-2">How agent replies to circle videos:</label>
          <Select>
            <SelectTrigger>
              <SelectValue placeholder="Select option" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="circle-video-text">Circle Video and Text</SelectItem>
              <SelectItem value="voice-text">Voice and Text</SelectItem>
              <SelectItem value="only-video-circle">Only Video Circle</SelectItem>
              <SelectItem value="only-voice">Only Voice</SelectItem>
              <SelectItem value="only-text">Only Text</SelectItem>
              <SelectItem value="none">None</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div className="flex justify-end mt-4">
          <Button>Save Changes</Button>
        </div>
      </CardContent>
    </Card>
  )
}

