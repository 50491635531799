import React, { useState, useEffect } from "react";
import PageWrapper from "@/components/layout/PageWrapper";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useRecoilValue } from "recoil";
import { fetchProfileStats } from "@/atoms/profile_stats";
import { authState } from "@/atoms/auth";
import CopyToClipboard from "@/components/CopyToClipboard";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Warning } from "@phosphor-icons/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { createAgent } from "@dfinity/utils";
import { AccountIdentifier, LedgerCanister } from "@dfinity/ledger-icp";
import { IcrcLedgerCanister } from "@dfinity/ledger-icrc";
import { Principal } from "@dfinity/principal";
import { cloudStorage } from "@telegram-apps/sdk";
import { Ed25519KeyIdentity } from "@dfinity/identity";
import { SendICPForm } from "./SendICPForm";
import { ReceiveICPForm } from "./ReceiveICPForm";
import { SendBUDForm } from "./SendBUDForm";
import { ReceiveBUDForm } from "./ReceiveBUDForm";
import { TonConnectButton } from "@tonconnect/ui-react";
import { isTMA } from "@telegram-apps/sdk";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { fetchRewards } from "@/atoms/rewards";
import { useProfileQuery } from "@/queries/profiles";
import { getStats } from "@/api/supabase/profiles";
import CountUp from "react-countup";

const IC_MAINNET_URL = "https://icp0.io/";
const MAINNET_CANISTER_ID = "ryjl3-tyaaa-aaaaa-aaaba-cai";
const BUD_CANISTER_ID = "acuhp-niaaa-aaaaj-azuba-cai";
const TONX_API_KEY = "f9925474-d700-4397-b16f-0c97cae0518d";

const fetchTONBalance = async (address: string) => {
  if (!address) return "0";

  const response = await fetch(
    `https://api.tonxapi.com/v2/account/getInfo?address=${address}`,
    {
      headers: {
        Authorization: `Bearer ${TONX_API_KEY}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch TON balance");
  }

  const data = await response.json();
  return data.balance ? (Number(data.balance) / 1e9).toFixed(4) : "0";
};

const Wallet: React.FC = () => {
  const user = useRecoilValue(authState);
  const [isTma, setIsTma] = useState(false);
  const [icpBalance, setIcpBalance] = useState<string>("0");
  const [budBalance, setBudBalance] = useState<string>("0");
  const [loading, setLoading] = useState(true);
  const [identity, setIdentity] = useState<Ed25519KeyIdentity | null>(null);
  const [totalUSDBalance, setTotalUSDBalance] = useState<number>(0);
  const IDENITITY_KEY = "telegram_internet_identity";
  const [tonConnectUI] = useTonConnectUI();
  const queryClient = useQueryClient();

  const { data: profile } = useProfileQuery();

  const { data: profileStats, isPending: profileStatsLoading } = useQuery({
    queryKey: ["profileStats"],
    queryFn: () => getStats(),
    enabled: !!profile,
  });

  const { data: rewards, isPending: rewardsLoading } = useQuery({
    queryKey: ["rewards"],
    queryFn: fetchRewards,
  });

  const { data: tonBalance = "0", isLoading: tonBalanceLoading } = useQuery({
    queryKey: ["tonBalance", tonConnectUI.account?.address],
    queryFn: () => fetchTONBalance(tonConnectUI.account?.address || ""),
    enabled: !!tonConnectUI.account?.address,
    retry: 3,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
  });

  const { data: icpBalanceData, isLoading: icpLoading } = useQuery({
    queryKey: ['icpBalance', user?.key],
    queryFn: async () => {
      if (!user?.key) return "0";
      const identity = await getIdentity();
      
      const agent = await createAgent({
        identity: identity,
        host: IC_MAINNET_URL,
      });

      const ledger = LedgerCanister.create({
        agent,
        canisterId: Principal.fromText(MAINNET_CANISTER_ID),
      });

      const accountId = AccountIdentifier.fromPrincipal({
        principal: Principal.fromText(user.key),
      });

      const balance = await ledger.accountBalance({
        accountIdentifier: accountId,
        certified: true,
      });

      const icpBalanceNum = Number(balance.toString()) / 100000000;
      return icpBalanceNum.toFixed(4);
    },
    enabled: !!user?.key,
    staleTime: 30000,
    refetchInterval: 30000,
  });

  const { data: budBalanceData, isLoading: budLoading } = useQuery({
    queryKey: ['budBalance', user?.key],
    queryFn: async () => {
      if (!user?.key) return "0";
      const identity = await getIdentity();
      
      const agent = await createAgent({
        identity: identity,
        host: IC_MAINNET_URL,
      });

      const ledger = IcrcLedgerCanister.create({
        agent,
        canisterId: Principal.fromText(BUD_CANISTER_ID),
      });

      const balance = await ledger.balance({
        owner: Principal.fromText(user.key),
        certified: true,
      });

      return (Number(balance.toString()) / 100000000).toFixed(4);
    },
    enabled: !!user?.key,
    staleTime: 30000,
    refetchInterval: 30000,
  });

  useEffect(() => {
    async function detectAndSetIsTMA() {
      const _isTMA = await isTMA();
      setIsTma(_isTMA);
    }

    detectAndSetIsTMA();
  }, []);

  const getIdentity = async () => {
    const restoredIdentity = await cloudStorage.getItem(IDENITITY_KEY);

    let identity: Ed25519KeyIdentity;
    if (!restoredIdentity) {
      console.log("generating identity");
      identity = await Ed25519KeyIdentity.generate();
      await cloudStorage.setItem(
        IDENITITY_KEY,
        JSON.stringify(identity.toJSON())
      );
      return identity;
    } else {
      identity = Ed25519KeyIdentity.fromJSON(restoredIdentity);
      return identity;
    }
  };

  const calculateUSDBalance = async (icpAmount: number) => {
    try {
      const response = await fetch(
        "https://api.coinbase.com/v2/exchange-rates?currency=ICP"
      );
      const data = await response.json();
      const icpToUsd = parseFloat(data.data.rates.USD);
      const usdBalance = icpAmount * icpToUsd;
      setTotalUSDBalance(usdBalance);
    } catch (error) {
      console.error("Error fetching ICP price:", error);
    }
  };

  console.log("Loaded identity", identity);

  const ICPBalanceCard: React.FC = () => {
    const [cardIdentity, setCardIdentity] = useState<Ed25519KeyIdentity | null>(null);

    useEffect(() => {
      getIdentity().then(setCardIdentity);
    }, []);

    if (!cardIdentity) {
      return null;
    }

    return (
      <Card className="bg-card">
        <CardHeader className="flex flex-row items-center justify-between">
          <div className="flex items-center">
            <img src="/icp.png" alt="Logo" className="h-8 w-8 mr-2" />
            <CardTitle className="text-xl font-bold text-card-foreground">
              Internet Computer
            </CardTitle>
          </div>
          <div className="flex space-x-2">
            <div className="flex items-center">
              <ReceiveICPForm principalId={user?.key ?? ""} />
              <span className="ml-1 text-sm text-muted-foreground">Receive</span>
            </div>
            <div className="flex items-center">
              <SendICPForm
                identity={cardIdentity}
                onTransferComplete={() => {
                  // Invalidate the ICP balance query to trigger a refresh
                  queryClient.invalidateQueries({ queryKey: ['icpBalance'] })
                }}
              />
              <span className="ml-1 text-sm text-muted-foreground">Send</span>
            </div>
          </div>
        </CardHeader>
        <CardContent className="text-center">
          <div className="flex justify-between">
            <p className="text-muted-foreground">Balance</p>
            <p className="text-lg font-bold flex text-card-foreground">
              {icpLoading ? "Loading..." : `${icpBalanceData} ICP`}
            </p>
          </div>
        </CardContent>
      </Card>
    );
  };

  const BUDBalanceCard: React.FC = () => {
    return (
      <Card className="bg-card">
        <CardHeader className="flex flex-row items-center justify-between">
          <div className="flex items-center">
            <img src="/bud.png" alt="Logo" className="h-8 w-8 mr-2" />
            <CardTitle className="text-lg font-bold text-card-foreground">
              Bridge Unit of Data
            </CardTitle>
          </div>
          <div className="flex space-x-2">
            <div className="flex items-center">
              <ReceiveBUDForm principalId={user?.key || ""} />
              <span className="ml-1 text-sm text-muted-foreground">Receive</span>
            </div>
            <div className="flex items-center">
              {identity && (
                <SendBUDForm
                  identity={identity}
                  onTransferComplete={() => {
                    queryClient.invalidateQueries({ queryKey: ['budBalance'] })
                  }}
                />
              )}
              <span className="ml-1 text-sm text-muted-foreground">Send</span>
            </div>
          </div>
        </CardHeader>
        <CardContent className="text-center">
          <div className="flex justify-between">
            <p className="text-muted-foreground">Balance</p>
            <p className="text-lg font-bold flex text-card-foreground">
              {budLoading ? "Loading..." : `${budBalanceData} BUD`}
            </p>
          </div>
        </CardContent>
      </Card>
    );
  };

  const TONBalanceCard: React.FC = () => {
    if (!tonConnectUI.account?.address) return null;

    return (
      <Card className="bg-card">
        <CardHeader className="flex flex-row items-center justify-between">
          <div className="flex items-center">
            <img src="/tonlogo.png" alt="TON Logo" className="h-8 w-8 mr-2" />
            <CardTitle className="text-xl font-bold text-card-foreground">TON</CardTitle>
          </div>
        </CardHeader>
        <CardContent className="text-center">
          <div className="flex justify-between">
            <p className="text-muted-foreground">Balance</p>
            <p className="text-lg font-bold flex text-card-foreground">
              {tonBalanceLoading ? "Loading..." : `${tonBalance} TON`}
            </p>
          </div>
        </CardContent>
      </Card>
    );
  };

  const StatsCard: React.FC = () => {
    if (profileStatsLoading || rewardsLoading) {
      return (
        <Card className="bg-card">
          <CardContent className="text-center py-6">
            <p className="text-muted-foreground">Loading stats...</p>
          </CardContent>
        </Card>
      );
    }

    return (
      <Card className="bg-card">
        <CardHeader className="flex flex-row items-center justify-between">
          <div className="flex items-center">
            <CardTitle className="text-xl font-bold text-card-foreground">Stats & Credits</CardTitle>
          </div>
        </CardHeader>
        <CardContent>
          <div className="space-y-2">
            <div className="flex justify-between">
              <p className="text-muted-foreground">Available Credits</p>
              <p className="font-bold text-card-foreground">{profileStats?.credits || 0}</p>
            </div>
            <div className="flex justify-between">
              <p className="text-muted-foreground">Current Streak</p>
              <p className="font-bold text-card-foreground">{profileStats?.streak || 0} days</p>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  };
  const PortfolioCard: React.FC = () => {
    const [portfolioData, setPortfolioData] = useState({
      totalPurchases: profileStats?.total_items || 0,
      browserActivity: "Coming Soon",
      socialMediaActivity: "Coming Soon", 
      appActivity: "Coming Soon",
    });

    return (
      <Card>
        <CardHeader>
          <CardTitle className="text-xl">Data Portfolio Overview</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm text-muted-foreground">Total Purchases</p>
              <p className="text-2xl font-bold">
                <CountUp end={portfolioData.totalPurchases} duration={2} />
              </p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">Browser Activity</p>
              <p className="text-2xl font-bold">{portfolioData.browserActivity}</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">Social Media Activity</p>
              <p className="text-2xl font-bold">{portfolioData.socialMediaActivity}</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">App Activity</p>
              <p className="text-2xl font-bold">{portfolioData.appActivity}</p>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  };

  const PotentialPortfolioCard: React.FC = () => {
    return (
      <Card>
        <CardHeader>
          <CardTitle className="text-xl">Potential Portfolio Price</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-4xl font-bold">
            $ {((profileStats?.total_items || 0) * 0.02).toFixed(2)}
          </p>
          <p className="text-sm text-muted-foreground mt-2">
            (Total Purchases * $0.02 per item)
          </p>
        </CardContent>
      </Card>
    );
  };

  return (
    <PageWrapper>
      <div className="flex flex-col items-center justify-center max-w-3xl mx-auto space-y-8">
        <Card className="w-full bg-card">
          <CardHeader className="items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-2xl text-center text-card-foreground">
              Total Balance
            </CardTitle>
          </CardHeader>
          <CardContent className="text-center">
            <p className="text-4xl font-bold text-card-foreground">$ {totalUSDBalance.toFixed(2)}</p>
            <div className="flex flex-col items-center mt-2">
              <CopyToClipboard />
            </div>
            <div className="flex flex-col items-center mt-4">
              {isTma && <TonConnectButton />}
            </div>
          </CardContent>
        </Card>
        <div className="w-full space-y-4">
          <StatsCard />
          <PortfolioCard />
          <PotentialPortfolioCard />
          {isTma && <TONBalanceCard />}
          <div className="bg-card rounded-lg">
            <BUDBalanceCard />
          </div>
          <div className="bg-card rounded-lg">
            <ICPBalanceCard />
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Wallet;
